import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoIcon, LeftArrowIcon } from '~/components/icons';
import Card from '~/components/shared/Card';
import CardItem from '~/components/shared/CardItem';
import CardItemProperty from '~/components/shared/CardItem/property';
import Pill from '~/components/shared/Pill';
import { StepperContext } from '~/components/shared/Stepper';
import Button from '~/components/shared/buttons/Button';
import { useFormat } from '~/components/shared/formatDate/Date';
import { HasMDM } from '~/features/mdm';

import { OnboardingStepperContext } from '../context';

const EmployeeRecap: FunctionComponent<{ employeeId?: string | null }> = ({ employeeId }) => {
  const { t } = useTranslation();
  const dateFormat = useFormat('date');
  const {
    contextData: { employee },
  } = useContext(OnboardingStepperContext);
  const { setCurrentStep } = useContext(StepperContext);

  return (
    <Card
      title={t('titles.informations')}
      icon={<InfoIcon />}
      action={
        <Button
          variant="secondary-dark"
          onClick={() => setCurrentStep(employeeId ? 1 : 2)}
          icon={<LeftArrowIcon width={16} className="pri-mr-4" />}
        >
          {t('buttons.edit')}
        </Button>
      }
    >
      <CardItem>
        <CardItemProperty label={t('properties.employees.firstname')} value={employee?.firstName} />
      </CardItem>
      <CardItem>
        <CardItemProperty label={t('properties.employees.lastname')} value={employee?.lastName} />
      </CardItem>
      <CardItem>
        <CardItemProperty label={t('properties.employees.email')} value={employee?.email} />
      </CardItem>
      <CardItem>
        <CardItemProperty label={t('properties.employees.personal_email')} value={employee?.personalEmail} />
      </CardItem>
      <CardItem>
        <CardItemProperty label={t('properties.employees.phone')} value={employee?.phone} />
      </CardItem>
      <CardItem>
        <CardItemProperty
          label={t('properties.employees.start_date')}
          value={employee?.startDate && moment(employee?.startDate).format(dateFormat)}
        />
      </CardItem>
      <HasMDM>
        <>
          <CardItem>
            <CardItemProperty
              label={t('properties.employees.automatic_enrollment')}
              value={
                employee?.automaticEnrollment ? (
                  <Pill color="green">{t('texts.yes')}</Pill>
                ) : (
                  <Pill color="grey">{t('texts.no')}</Pill>
                )
              }
            />
          </CardItem>
          <CardItem>
            <CardItemProperty
              label={t('properties.employees.send_to_personal_email')}
              value={
                employee?.sendToPersonalEmail ? (
                  <Pill color="green">{t('texts.yes')}</Pill>
                ) : (
                  <Pill color="grey">{t('texts.no')}</Pill>
                )
              }
            />
          </CardItem>
        </>
      </HasMDM>
    </Card>
  );
};

export default EmployeeRecap;
