import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const UserFollowIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 17 17" fill="none">
    <path
      d="M16.25 7.76678L14.25 7.76678L14.25 5.76678L13.25 5.76678L13.25 7.76678L11.25 7.76678L11.25 8.76678L13.25 8.76678L13.25 10.7668L14.25 10.7668L14.25 8.76678L16.25 8.76678L16.25 7.76678ZM6.25 2.76678C6.74445 2.76678 7.2278 2.91341 7.63893 3.18811C8.05005 3.46281 8.37048 3.85326 8.5597 4.31008C8.74892 4.76689 8.79843 5.26956 8.70196 5.75451C8.6055 6.23946 8.3674 6.68492 8.01777 7.03455C7.66814 7.38418 7.22268 7.62228 6.73773 7.71875C6.25277 7.81521 5.75011 7.7657 5.29329 7.57648C4.83648 7.38726 4.44603 7.06683 4.17133 6.65571C3.89662 6.24459 3.75 5.76124 3.75 5.26678C3.75 4.60374 4.01339 3.96786 4.48223 3.49902C4.95107 3.03018 5.58696 2.76678 6.25 2.76678ZM6.25 1.76678C5.55777 1.76678 4.88108 1.97206 4.3055 2.35664C3.72993 2.74123 3.28133 3.28785 3.01642 3.92739C2.75152 4.56693 2.6822 5.27067 2.81725 5.9496C2.9523 6.62853 3.28564 7.25217 3.77513 7.74166C4.26461 8.23114 4.88825 8.56448 5.56718 8.69953C6.24612 8.83458 6.94985 8.76527 7.58939 8.50036C8.22893 8.23546 8.77556 7.78685 9.16014 7.21128C9.54473 6.63571 9.75 5.95902 9.75 5.26678C9.75 4.33853 9.38125 3.44829 8.72487 2.79191C8.0685 2.13553 7.17826 1.76678 6.25 1.76678ZM11.25 15.7668L10.25 15.7668L10.25 13.2668C10.25 12.6037 9.98661 11.9679 9.51777 11.499C9.04893 11.0302 8.41304 10.7668 7.75 10.7668L4.75 10.7668C4.08696 10.7668 3.45107 11.0302 2.98223 11.499C2.51339 11.9679 2.25 12.6037 2.25 13.2668L2.25 15.7668L1.25 15.7668L1.25 13.2668C1.25 12.3385 1.61875 11.4483 2.27513 10.7919C2.9315 10.1355 3.82174 9.76678 4.75 9.76678L7.75 9.76678C8.67826 9.76678 9.5685 10.1355 10.2249 10.7919C10.8813 11.4483 11.25 12.3385 11.25 13.2668L11.25 15.7668Z"
      fill={color}
    />
  </svg>
);

export default UserFollowIcon;
