import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const SubtractAltIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 17 17" fill="none">
    <path
      d="M8.75 2.76678C12.05 2.76678 14.75 5.46678 14.75 8.76678C14.75 12.0668 12.05 14.7668 8.75 14.7668C5.45 14.7668 2.75 12.0668 2.75 8.76678C2.75 5.46678 5.45 2.76678 8.75 2.76678ZM8.75 1.76678C4.9 1.76678 1.75 4.91678 1.75 8.76678C1.75 12.6168 4.9 15.7668 8.75 15.7668C12.6 15.7668 15.75 12.6168 15.75 8.76678C15.75 4.91678 12.6 1.76678 8.75 1.76678Z"
      fill={color}
    />
    <path d="M4.75 8.26678H12.75V9.26678H4.75V8.26678Z" fill={color} />
  </svg>
);

export default SubtractAltIcon;
