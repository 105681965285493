/* eslint-disable jsx-a11y/anchor-is-valid */
import cn from 'classnames';
import RCTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import type { ReactElement } from 'react';
import React from 'react';

import Flex from '../shaping/Flex';
import { Text } from '../typography';
import './index.scss';

export type TooltipProps = {
  text: ReactElement | ReactElement[] | string;
  subtext?: ReactElement | ReactElement[] | string;
  align?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
};

const Tooltip: FunctionComponent<TooltipProps> = ({ children, className, align = 'bottom', text, subtext }) => (
  <RCTooltip
    placement={align}
    overlay={
      <Flex direction="column" gap={2}>
        {typeof text === 'string' ? (
          <Text className="pri-tooltip-text" variant="light" size="caption" bold>
            {text}
          </Text>
        ) : (
          text
        )}
        {subtext && typeof subtext === 'string' ? (
          <Text className="pri-tooltip-subtext" variant="light" size="caption">
            {subtext}
          </Text>
        ) : (
          subtext
        )}
      </Flex>
    }
    overlayClassName={cn('pri-tooltip', className)}
  >
    <span className="pri-tooltip-trigger-button">{children}</span>
  </RCTooltip>
);

export default Tooltip;
