import { useCallback, useState } from 'react';
import { Buffer } from 'buffer';

interface UseSSOFormSubmitHandlerParams {
  callbackURL: string;
  rawSamlResponse: string;
  relayState?: string | null;
}

export const useSAMLResponseFormSubmit = () => {
  const [loading, setLoading] = useState(false);

  const submitSAMLResponseForm = useCallback(
    ({ callbackURL, rawSamlResponse, relayState }: UseSSOFormSubmitHandlerParams) => {
      setLoading(true);

      const formElement = document.createElement('form');
      formElement.action = callbackURL;
      formElement.method = 'POST';
      formElement.style.display = 'none';

      const samlResponseInputElement = document.createElement('input');
      samlResponseInputElement.type = 'hidden';
      samlResponseInputElement.name = 'SAMLResponse';
      samlResponseInputElement.value = Buffer.from(rawSamlResponse).toString('base64');
      formElement.appendChild(samlResponseInputElement);

      if (relayState) {
        const relayStateInputElement = document.createElement('input');
        relayStateInputElement.type = 'hidden';
        relayStateInputElement.name = 'RelayState';
        relayStateInputElement.value = relayState;
        formElement.appendChild(relayStateInputElement);
      }

      document.body.appendChild(formElement);
      formElement.submit();

      setTimeout(() => {
        document.body.removeChild(formElement);
        setLoading(false);
      }, 1000); // Delay to let the form submit so we can display a loading state
    },
    [],
  );

  return { submitSAMLResponseForm, loading };
};
