import React from 'react';

const LogoutIcon: SvgComponent = ({ className, width = 24, color = 'black' }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 16 17" fill="none">
    <path
      d="M3 15.7668H9C9.26512 15.7665 9.5193 15.661 9.70677 15.4736C9.89424 15.2861 9.9997 15.0319 10 14.7668V13.2668H9V14.7668H3V2.76678H9V4.26678H10V2.76678C9.9997 2.50166 9.89424 2.24748 9.70677 2.06001C9.5193 1.87254 9.26512 1.76709 9 1.76678H3C2.73488 1.76709 2.4807 1.87254 2.29323 2.06001C2.10576 2.24748 2.0003 2.50166 2 2.76678V14.7668C2.0003 15.0319 2.10576 15.2861 2.29323 15.4736C2.4807 15.661 2.73488 15.7665 3 15.7668Z"
      fill={color}
    />
    <path
      d="M10.293 11.0598L12.086 9.26678H5V8.26678H12.086L10.293 6.47378L11 5.76678L14 8.76678L11 11.7668L10.293 11.0598Z"
      fill={color}
    />
  </svg>
);

export default LogoutIcon;
