import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const AppIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 12 13" fill="none">
    <path
      d="M3 1.76678V3.26678H1.5V1.76678H3ZM3.75 1.01678H0.75V4.01678H3.75V1.01678ZM6.75 1.76678V3.26678H5.25V1.76678H6.75ZM7.5 1.01678H4.5V4.01678H7.5V1.01678ZM10.5 1.76678V3.26678H9V1.76678H10.5ZM11.25 1.01678H8.25V4.01678H11.25V1.01678ZM3 5.51678V7.01678H1.5V5.51678H3ZM3.75 4.76678H0.75V7.76678H3.75V4.76678ZM6.75 5.51678V7.01678H5.25V5.51678H6.75ZM7.5 4.76678H4.5V7.76678H7.5V4.76678ZM10.5 5.51678V7.01678H9V5.51678H10.5ZM11.25 4.76678H8.25V7.76678H11.25V4.76678ZM3 9.26678V10.7668H1.5V9.26678H3ZM3.75 8.51678H0.75V11.5168H3.75V8.51678ZM6.75 9.26678V10.7668H5.25V9.26678H6.75ZM7.5 8.51678H4.5V11.5168H7.5V8.51678ZM10.5 9.26678V10.7668H9V9.26678H10.5ZM11.25 8.51678H8.25V11.5168H11.25V8.51678Z"
      fill={color}
    />
  </svg>
);

export default AppIcon;
