import cn from 'classnames';
import type { ReactElement } from 'react';
import React from 'react';
import { useToggle } from 'react-use';

import Flex from '~/components/shared/shaping/Flex';

import Bloc from '../shaping/Bloc';

import { ChevronBottomIcon } from '~/components/icons';
import { Title } from '../typography';
import './index.scss';

export type CardProps = {
  title: string;
  children: ReactChildren;
  collapsedByDefault?: boolean;
  icon?: ReactElement<SvgComponentProps>;
};

const CollapsibleSection: FunctionComponent<CardProps> = ({
  className,
  children,
  title,
  icon = null,
  collapsedByDefault = true,
}) => {
  const [collapsed, setCollapse] = useToggle(collapsedByDefault);

  return (
    <Bloc rounded backgroundColor="white" className={cn('pri-collapsible-section', className)}>
      <Flex
        className={cn('pri-collapsible-header', { '--collapsed': collapsed })}
        justify="between"
        align="center"
        gap={5}
        onClick={() => setCollapse(!collapsed)}
      >
        <Flex justify="start" align="center" gap={3}>
          {icon}
          <Title size="largeLabel">{title}</Title>
        </Flex>
        <ChevronBottomIcon className={cn('pri-collapsible-chevron', { '--collapsed': !collapsed })} />
      </Flex>
      <div className={cn('pri-collapsible-collapse', { '--collapsed': collapsed })}>
        <Bloc className="pri-collapsible-childrens">{children}</Bloc>
      </div>
    </Bloc>
  );
};

export default CollapsibleSection;
