import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import type { OrganizationFormFields } from '~/validations/login';
import { chooseOrganizationSchema } from '~/validations/login';

import Button from '~/components/shared/buttons/Button';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import { SIGNUP_ROUTE } from '~/config/routes';
import { LoginContext } from '../context';

const ChooseOrganizationPart: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setOrganization } = useContext(LoginContext);
  const form = useForm<OrganizationFormFields>({
    mode: 'onSubmit',
    resolver: zodResolver(chooseOrganizationSchema(t)),
  });
  const { handleSubmit } = form;

  const onSubmit = useCallback(
    (e: OrganizationFormFields) => {
      setOrganization(e.organization);
    },
    [setOrganization],
  );

  return (
    <Flex direction="column">
      <Title size="smallHeading">{t('titles.enter_company_information')}</Title>
      <Form form={form} className="pri-mt-7" onSubmit={handleSubmit(onSubmit)}>
        <Input name="organization" label={t('forms.labels.company')} form={form} required />
        <Flex direction="row">
          <Button type="submit" className="flex-1 pri-mt-2" fullWidth>
            {t('buttons.confirm')}
          </Button>
        </Flex>
      </Form>
      <Flex className="pri-mt-5" direction="row" justify="center" align="baseline" gap={2}>
        <Text>{t('texts.no_account_yet')}</Text>
        <Link to={SIGNUP_ROUTE()}>
          <Text>{t('links.sign_up')}</Text>
        </Link>
      </Flex>
    </Flex>
  );
};

export default ChooseOrganizationPart;
