import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const SettingsServicesIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="settings--services">
      <path
        id="Vector"
        d="M18.125 15.625H16.875V14.375H17.5V11.875H15V12.5H13.75V11.25C13.75 11.0842 13.8158 10.9253 13.9331 10.8081C14.0503 10.6908 14.2092 10.625 14.375 10.625H18.125C18.2908 10.625 18.4497 10.6908 18.5669 10.8081C18.6842 10.9253 18.75 11.0842 18.75 11.25V15C18.75 15.1658 18.6842 15.3247 18.5669 15.4419C18.4497 15.5592 18.2908 15.625 18.125 15.625Z"
        fill={color}
      />
      <path
        id="Vector_2"
        d="M15 18.7499H11.25C11.0842 18.7499 10.9253 18.684 10.8081 18.5668C10.6908 18.4496 10.625 18.2907 10.625 18.1249V14.3749C10.625 14.2091 10.6908 14.0502 10.8081 13.933C10.9253 13.8157 11.0842 13.7499 11.25 13.7499H15C15.1658 13.7499 15.3247 13.8157 15.4419 13.933C15.5592 14.0502 15.625 14.2091 15.625 14.3749V18.1249C15.625 18.2907 15.5592 18.4496 15.4419 18.5668C15.3247 18.684 15.1658 18.7499 15 18.7499ZM11.875 17.4999H14.375V14.9999H11.875V17.4999ZM9.375 12.4111C8.92331 12.2934 8.51366 12.0515 8.19258 11.7127C7.8715 11.3739 7.65186 10.9519 7.55859 10.4945C7.46532 10.0372 7.50216 9.56285 7.66494 9.12539C7.82772 8.68794 8.1099 8.30489 8.47945 8.01976C8.84899 7.73462 9.29109 7.55883 9.75553 7.51234C10.22 7.46585 10.6881 7.55052 11.1069 7.75675C11.5256 7.96298 11.8781 8.28249 12.1244 8.679C12.3706 9.07551 12.5008 9.53314 12.5 9.9999H13.75C13.7507 9.28477 13.547 8.58435 13.1627 7.98123C12.7785 7.3781 12.2298 6.89741 11.5814 6.59584C10.933 6.29427 10.2118 6.1844 9.503 6.27918C8.79419 6.37395 8.12726 6.66943 7.58084 7.13076C7.03442 7.59209 6.63131 8.20005 6.41903 8.88294C6.20675 9.56582 6.19416 10.2952 6.38274 10.985C6.57132 11.6748 6.9532 12.2963 7.48337 12.7762C8.01354 13.2561 8.66988 13.5745 9.375 13.6936V12.4111Z"
        fill={color}
      />
      <path
        id="Vector_3"
        d="M18.056 8.46875L16.6123 9.7375L15.7248 8.85L17.231 7.525L15.756 4.975L13.606 5.7C13.1024 5.2808 12.532 4.9491 11.9185 4.71875L11.4748 2.5H8.52478L8.08103 4.71875C7.46266 4.94267 6.88912 5.27494 6.38728 5.7L4.24352 4.975L2.76852 7.525L4.46852 9.01875C4.35289 9.66571 4.35289 10.328 4.46852 10.975L2.76852 12.475L4.24352 15.025L6.39352 14.3C6.89716 14.7192 7.46758 15.0509 8.08103 15.2812L8.52478 17.5H9.37478V18.75H8.52478C8.23576 18.7497 7.95577 18.6493 7.73244 18.4658C7.5091 18.2824 7.35622 18.0272 7.29978 17.7437L6.98103 16.1688C6.69806 16.0307 6.42455 15.8741 6.16227 15.7L4.64352 16.2125C4.51438 16.2545 4.37934 16.2756 4.24352 16.275C4.02398 16.2765 3.80801 16.2194 3.61793 16.1095C3.42784 15.9996 3.27054 15.841 3.16227 15.65L1.68727 13.1C1.54139 12.8494 1.48718 12.5558 1.53393 12.2696C1.58068 11.9835 1.72548 11.7224 1.94352 11.5312L3.14352 10.4813C3.13102 10.3188 3.12477 10.1625 3.12477 10C3.12477 9.8375 3.13727 9.68125 3.14977 9.525L1.94352 8.46875C1.72548 8.27758 1.58068 8.01654 1.53393 7.73035C1.48718 7.44416 1.54139 7.15062 1.68727 6.9L3.16227 4.35C3.27054 4.159 3.42784 4.00037 3.61793 3.89049C3.80801 3.78062 4.02398 3.72349 4.24352 3.725C4.37934 3.72438 4.51438 3.74547 4.64352 3.7875L6.15602 4.3C6.42044 4.12581 6.69604 3.96922 6.98103 3.83125L7.29978 2.25625C7.35622 1.97279 7.5091 1.71764 7.73244 1.53419C7.95577 1.35074 8.23576 1.25032 8.52478 1.25H11.4748C11.7638 1.25032 12.0438 1.35074 12.2671 1.53419C12.4904 1.71764 12.6433 1.97279 12.6998 2.25625L13.0185 3.83125C13.3015 3.96926 13.575 4.12585 13.8373 4.3L15.356 3.7875C15.4852 3.74547 15.6202 3.72438 15.756 3.725C15.9756 3.72349 16.1915 3.78062 16.3816 3.89049C16.5717 4.00037 16.729 4.159 16.8373 4.35L18.3123 6.9C18.4582 7.15062 18.5124 7.44416 18.4656 7.73035C18.4189 8.01654 18.2741 8.27758 18.056 8.46875Z"
        fill={color}
      />
    </g>
  </svg>
);

export default SettingsServicesIcon;
