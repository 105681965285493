import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '~/components/shared/buttons/Button';
import Flex from '~/components/shared/shaping/Flex';
import type { Connection } from '~/pages/Login/context';
import { LoginContext } from '~/pages/Login/context';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

type PasswordSignUpPartProps = {
  connection: Connection;
};

const PasswordSignUpPart: FunctionComponent<PasswordSignUpPartProps> = ({ connection }) => {
  const { t } = useTranslation();
  const { organizationId, invitation, redirectUri } = useContext(LoginContext);
  const { webAuth } = useContext(PrimoAdminContext);

  const signup = useCallback(() => {
    if (connection?.realm) {
      webAuth.authorize({
        connection: connection.realm,
        redirectUri,
        organization: organizationId,
        invitation,
      });
    }
  }, [connection, invitation, organizationId, redirectUri, webAuth]);

  if (!connection?.realm) {
    return null;
  }
  return (
    <Flex>
      <Button className="pri-flex-1" onClick={() => signup()} fullWidth>
        {t('buttons.signup_with_email')}
      </Button>
    </Flex>
  );
};

export default PasswordSignUpPart;
