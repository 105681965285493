import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { UpdateDeviceTagsMutation } from '~/graphql/schema';
import { useUpdateDeviceTagsMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseUpdateDeviceTagsVariables = {
  deviceId: string;
  tags: string[];
};

const useMutationUpdateDeviceTags = (queryOptions: UseQueryOptions<UseUpdateDeviceTagsVariables> = {}) => {
  const serializeCreateDeviceData = useCallback((data: UpdateDeviceTagsMutation | undefined | null) => {
    if (data?.updateDeviceTags?.__typename === 'MutationUpdateDeviceTagsSuccess') {
      return ok(data.updateDeviceTags.data);
    }
    if (data?.updateDeviceTags?.__typename === 'MutationUpdateDeviceTagsError')
      return refuse(data.updateDeviceTags.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [mutation, { data: updateDeviceTagsData, loading: updateDeviceTagsLoading, called: updateDeviceTagsCalled }] =
    useUpdateDeviceTagsMutation({
      refetchQueries: [
        'GetViewDevices',
        'ListInStockDevicesPage',
        'ListInStockDevicesPage',
        'GetDeviceDetailsPageDocument',
        'GetDeviceDetailsPage',
      ],
      awaitRefetchQueries: true,
    });

  const updateDeviceTagsResult = useMemo(
    () => serializeCreateDeviceData(updateDeviceTagsData),
    [serializeCreateDeviceData, updateDeviceTagsData],
  );

  const updateDeviceTags = useCallback(
    async (variables: UseUpdateDeviceTagsVariables) => {
      const result = await mutation({
        variables,
      });
      return serializeCreateDeviceData(result.data);
    },
    [mutation, serializeCreateDeviceData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !updateDeviceTagsCalled) updateDeviceTags({ ...queryOptions });
  }, [updateDeviceTags, queryOptions, updateDeviceTagsCalled]);

  return useMemo(
    () => ({
      updateDeviceTags,
      updateDeviceTagsResult,
      updateDeviceTagsLoading,
      updateDeviceTagsCalled,
    }),
    [updateDeviceTags, updateDeviceTagsResult, updateDeviceTagsLoading, updateDeviceTagsCalled],
  );
};

export default useMutationUpdateDeviceTags;
