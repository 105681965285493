import React from 'react';
import type { FC, ReactElement } from 'react';

import { useCompany } from '~/hooks/useCompany';
import { HasMDM } from './HasMDM';

type HasMDMProps = { children: ReactElement | null };

export const HasMDMAndSSOLogin: FC<HasMDMProps> = ({ children }) => {
  const company = useCompany();

  if (!company?.mdmSSOLoginEnabled) {
    return null;
  }
  return <HasMDM>{children}</HasMDM>;
};
