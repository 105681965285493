import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckmarkFilledIcon from '~/components/icons/checkmarkFilled';
import Pill from '~/components/shared/Pill';
import { DeviceEnrollmentStatusFilter, DevicePlatform } from '~/graphql/schema';

export interface EmployeeDeviceEnrollementStatusPillProps {
  enrollmentStatus: DeviceEnrollmentStatusFilter;
  platform?: DevicePlatform;
}

export const EmployeeDeviceEnrollementStatusPill: FunctionComponent<EmployeeDeviceEnrollementStatusPillProps> = ({
  enrollmentStatus,
  platform,
}) => {
  const { t } = useTranslation();
  if (!enrollmentStatus) return null;

  if (enrollmentStatus === DeviceEnrollmentStatusFilter.PartiallyEnrolled) {
    return <Pill color="yellow">{t('enums.enrollment_status.partially_enrolled')}</Pill>;
  }

  if (enrollmentStatus === DeviceEnrollmentStatusFilter.Enrolled) {
    return (
      <Pill
        icon={
          <CheckmarkFilledIcon className="pri-icon-online pri-flex pri-flex-align-center" color="green" width={16} />
        }
        color="green"
      >
        {t('enums.enrollment_status.enrolled')}
      </Pill>
    );
  }

  const notEnrollable =
    enrollmentStatus === DeviceEnrollmentStatusFilter.NotEnrollable && platform !== DevicePlatform.Unknown;
  if (notEnrollable) {
    return <Pill color="grey">{t('enums.enrollment_status.not_enrollable')}</Pill>;
  }

  return <Pill color="red">{t(`enums.enrollment_status.non_enrolled`)}</Pill>;
};

export default EmployeeDeviceEnrollementStatusPill;
