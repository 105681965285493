import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CardLink from '~/components/featured/cards/CardLink';
import EnrollmentCardList from '~/components/featured/cards/EnrollmentCardList';
import { HowToFixPartiallyEnrolled } from '~/components/featured/cards/HowToFixPartiallyEnrolled';
import InfoIcon from '~/components/icons/info';
import MilestoneIcon from '~/components/icons/milestone';
import Callout from '~/components/shared/Callout';
import Loader from '~/components/shared/Loader';
import Flex from '~/components/shared/shaping/Flex';
import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { Title } from '~/components/shared/typography';
import { DeviceEnrollmentStatusFilter, DevicePlatform, EnrollmentPackageType } from '~/graphql/schema';
import { useNavigatorPlatorm } from '~/hooks/useNavigatorPlatform';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';
import { EmployeeEnrollmentStepperContext } from '../../context';
import { InstallerCard } from './_part/InstallerCard';

const DownloadInstallerStep: FunctionComponent = () => {
  const { company, employee } = useContext(PrimoEmployeeContext);

  const { onFinish, contextData, enrollmentLinks, refreshEnrollmentLinks, refreshEnrollmentLinksLoading } = useContext(
    EmployeeEnrollmentStepperContext,
  );
  const { setFooterProps } = useContext(StepperContext);
  const { t } = useTranslation();
  const navigatorPlaform = useNavigatorPlatorm();

  const { selectedDevice } = contextData;

  const isDevicePartiallyEnrolled =
    selectedDevice?.enrollmentStatus === DeviceEnrollmentStatusFilter.PartiallyEnrolled &&
    selectedDevice.platform === DevicePlatform.Macos;

  const enrollmentLinksForPlatform = useMemo(
    () => enrollmentLinks?.links.find(link => link.platform === selectedDevice?.platform),
    [enrollmentLinks, selectedDevice],
  );
  const navigatorPlatformDifferentFromSelectedPlatform =
    selectedDevice?.platform && navigatorPlaform !== selectedDevice?.platform;

  const downloadLinksAvailable =
    (!!selectedDevice && !!enrollmentLinksForPlatform?.urls[0]) ||
    (!selectedDevice && enrollmentLinks?.links?.flatMap(link => link.urls).every(urlItem => !!urlItem));

  const onNext = useCallback(async () => {
    await onFinish();

    return true;
  }, [onFinish]);

  useEffect(() => {
    refreshEnrollmentLinks();
    setFooterProps({
      onNext,
      disabledNextButton: !downloadLinksAvailable,
      nextLabel: t(
        `steppers.enrollment_stepper.next_button_label_${!downloadLinksAvailable ? 'complete_enrollment' : 'quit'}`,
      ),
    });
  }, [setFooterProps, onNext, downloadLinksAvailable, t, refreshEnrollmentLinks]);

  if (!company?.id || !employee?.id) return null;

  return (
    <Step size="lg">
      <Flex direction="column" gap={8} fullWidth align="start" justify="center">
        <Title size="subHeading" bold>
          {t('titles.enrollment_stepper.download_installer.title')}
        </Title>
        <Flex className="pri-mt-3" direction="column" gap={8} fullWidth>
          {!selectedDevice ? (
            <EnrollmentCardList
              runOnInit
              exclude={[EnrollmentPackageType.Pkg]}
              className="pri-mt-6"
              companyId={company.id}
              employeeId={employee.id}
            />
          ) : (
            <>
              {navigatorPlatformDifferentFromSelectedPlatform && (
                <Callout
                  className="pri-mb-3"
                  icon={<InfoIcon />}
                  variant="info"
                  title={t('notices.enrollment_stepper.platform_mismatch_title', {
                    platform: selectedDevice?.platform,
                  })}
                  description={t('notices.enrollment_stepper.platform_mismatch_text')}
                />
              )}
              <Flex direction="row" gap={8}>
                {!enrollmentLinksForPlatform && refreshEnrollmentLinksLoading && <Loader spacing="md" />}
                {enrollmentLinksForPlatform?.urls
                  .filter(u =>
                    isDevicePartiallyEnrolled
                      ? u.type === EnrollmentPackageType.Pkg
                      : u.type !== EnrollmentPackageType.Pkg,
                  )
                  .map(item => (
                    <InstallerCard
                      key={item.type}
                      platform={enrollmentLinksForPlatform.platform}
                      item={item}
                      loading={refreshEnrollmentLinksLoading}
                      packageRequestedAt={enrollmentLinks?.lastBuildRequestedAt}
                      averageBuildTime={enrollmentLinks?.averageBuildTimeInMinutes}
                      onRefreshLinksEvent={async () => {
                        await refreshEnrollmentLinks();
                      }}
                    />
                  ))}
              </Flex>
            </>
          )}
          <Flex className="pri-mt-3" direction="column" gap={7} fullWidth>
            {isDevicePartiallyEnrolled ? (
              <HowToFixPartiallyEnrolled />
            ) : (
              <CardLink
                icon={<MilestoneIcon />}
                text={t('texts.enrollment_stepper.download_installer.how_to_links.enroll')}
                onClick={() => {
                  window.open(
                    'https://support.getprimo.com/fr/articles/8911815-fr-enregistrez-votre-ordinateur-dans-primo-fleetdm',
                    '_blank',
                  );
                }}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Step>
  );
};

export default DownloadInstallerStep;
