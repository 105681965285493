import cn from 'classnames';
import React, { useMemo } from 'react';

import Tooltip from '~/components/shared/Tooltip';
import type { TitleProps } from './types';

import './index.scss';

export const Title: FunctionComponent<TitleProps> = ({
  className,
  variant,
  children,
  align,
  size = 'subHeading',
  bold,
  ...rest
}) => {
  let Heading;

  switch (size) {
    case 'heading':
      Heading = 'h1' as keyof JSX.IntrinsicElements;
      break;
    case 'smallHeading':
      Heading = 'h3' as keyof JSX.IntrinsicElements;
      break;
    case 'largeLabel':
      Heading = 'h4' as keyof JSX.IntrinsicElements;
      break;
    default:
      Heading = 'h2' as keyof JSX.IntrinsicElements;
      break;
  }

  const hasMaxLength = 'maxLength' in rest && typeof children === 'string';

  const title = useMemo(() => {
    if (!hasMaxLength) return children;
    return children.length > (rest.maxLength || 0) ? (
      <Tooltip text={children}>{`${children.slice(0, rest.maxLength)}...`}</Tooltip>
    ) : (
      children
    );
  }, [children, hasMaxLength, rest.maxLength]);

  return (
    <Heading
      className={cn(className, 'pri-title', {
        [`pri-title-variant-${variant}`]: variant,
        [`pri-title-align-${align}`]: align,
        [`pri-title-${size}`]: size,
        [`pri-title-bold`]: bold,
      })}
    >
      {title}
    </Heading>
  );
};
