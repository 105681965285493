import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const DeliveryIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.4819 8.62402L19.4819 3.12402C19.3342 3.04282 19.1685 3.00024 19 3.00024C18.8315 3.00024 18.6657 3.04282 18.5181 3.12402L8.51805 8.62402C8.36127 8.71034 8.23052 8.83717 8.13946 8.99125C8.0484 9.14534 8.00037 9.32104 8.00037 9.50002C8.00037 9.679 8.0484 9.8547 8.13946 10.0088C8.23052 10.1629 8.36127 10.2897 8.51805 10.376L18 15.5913V26.3086L14.9638 24.6393L14 26.3912L18.5181 28.876C18.6656 28.9574 18.8314 29 19 29C19.1685 29 19.3343 28.9574 19.4819 28.876L29.4819 23.376C29.6387 23.2897 29.7695 23.1629 29.8607 23.0089C29.9518 22.8548 29.9999 22.679 30 22.5V9.50002C29.9999 9.321 29.9518 9.14527 29.8607 8.99118C29.7695 8.83709 29.6387 8.71029 29.4819 8.62402ZM19 5.14162L26.9248 9.50002L19 13.8584L11.0752 9.50002L19 5.14162ZM28 21.9087L20 26.3087V15.5913L28 11.1913V21.9087Z"
      fill={color}
    />
    <path d="M10 16L2 16V14H10V16Z" fill={color} />
    <path d="M12 24H4V22H12V24Z" fill={color} />
    <path d="M14 20H6V18H14V20Z" fill={color} />
  </svg>
);

export default DeliveryIcon;
