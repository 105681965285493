import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import google from '~/assets/google_g.svg';
import Button from '~/components/shared/buttons/Button';
import Flex from '~/components/shared/shaping/Flex';
import type { Connection } from '~/pages/Login/context';
import { LoginContext } from '~/pages/Login/context';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

type GoogleLoginPartProps = {
  connection: Connection;
};

const GoogleLoginPart: FunctionComponent<GoogleLoginPartProps> = ({ connection }) => {
  const { t } = useTranslation();
  const { organizationId, invitation, redirectUri } = useContext(LoginContext);
  const { webAuth } = useContext(PrimoAdminContext);
  const [params] = useSearchParams();

  const onLogin = useCallback(() => {
    webAuth.authorize({
      organization: organizationId,
      connection: connection.name,
      redirectUri: `${redirectUri}?${params.toString()}`,
      invitation,
    });
  }, [connection, organizationId, invitation, redirectUri, params, webAuth]);

  return (
    <Flex>
      <Button
        onClick={onLogin}
        className="pri-flex-1"
        variant="secondary-dark"
        fullWidth
        icon={<img className="pri-mr-4" src={google} alt="Google" height="16" />}
      >
        {invitation ? t('buttons.socials.google_sign_up') : t('buttons.socials.google')}
      </Button>
    </Flex>
  );
};

export default GoogleLoginPart;
