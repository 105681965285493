import { DatePicker as CarbonDatePicker, ClassPrefix, DatePickerInput } from '@carbon/react';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import type { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { getI18n } from 'react-i18next';

import Flex from '../../shaping/Flex';
import Error from '../_shared/Error';
import Helper from '../_shared/Helper';
import Label from '../_shared/Label';
import './index.scss';

export type DatePickerProps<FV extends FieldValues> = {
  label?: string;
  helper?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  dateFormat?: string;
  className?: string;
  minDate?: string | number;
  name: Path<FV>;
  form: UseFormReturn<FV>;
};

const DatePicker = <FV extends FieldValues>({
  className,
  dateFormat,
  label,
  required,
  form,
  name,
  disabled,
  placeholder,
  helper,
  ...props
}: DatePickerProps<FV>) => {
  const id = useMemo(() => `date-picker-${Math.random().toFixed(10).slice(2, 10)}`, []);
  const {
    control,
    formState: { errors },
  } = form;
  const error = errors[name];
  const i18n = useMemo(getI18n, []);

  const sanitizeHour = useCallback(
    (input?: string | Date) => {
      if (!input) return undefined;
      const isEn = i18n.language === 'en';
      const parsedDate = typeof input === 'string' ? moment(input, isEn ? 'YYYY/MM/DD' : 'DD/MM/YYYY') : moment(input);
      return parsedDate.utc().add(2, 'hours').startOf('day');
    },
    [i18n.language],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ref, onBlur, ...rest } }) => (
        <Flex direction="column" className="pri-flex-1">
          {label && <Label className="pri-mb-4" required={required} label={label} />}
          <ClassPrefix prefix="cds">
            <CarbonDatePicker
              allowInput
              className={cn('pri-date-picker', { 'pri-date-picker-required': required }, className)}
              datePickerType="single"
              dateFormat={(dateFormat ?? i18n.language === 'fr') ? 'd/m/Y' : 'Y/m/d'}
              onChange={val => {
                if (!val[0]) {
                  return;
                }
                onChange(sanitizeHour(val[0])?.toISOString());
              }}
              locale={i18n.language}
              {...props}
              {...rest}
            >
              <DatePickerInput
                onBlur={e => {
                  if (!e.target.value) {
                    onChange(undefined);
                  }
                  onBlur();
                }}
                id={`input-${id}`}
                labelText={null}
                invalid={!!error}
                disabled={disabled}
                placeholder={placeholder}
                className={cn({ 'pri-date-picker-input-required': required })}
              />
            </CarbonDatePicker>
          </ClassPrefix>
          {error?.message && <Error error={error.message.toString()} />}
          {!error && helper && <Helper helper={helper} />}
        </Flex>
      )}
    />
  );
};

export default DatePicker;
