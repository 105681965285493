import cn from 'classnames';
import React, { useMemo } from 'react';

import Tooltip from '~/components/shared/Tooltip';
import type { TextProps } from './types';

import './index.scss';

export const Text: FunctionComponent<TextProps> = ({
  className,
  variant,
  children,
  size = 'body',
  align,
  letterSpacing,
  italic,
  noWrap,
  noTooltip,
  bold,
  ...rest
}) => {
  const classNames = cn(className, 'pri-text', {
    [`pri-text-variant-${variant}`]: variant,
    [`pri-text-align-${align}`]: align,
    [`pri-text-${size}`]: size,
    [`pri-text-bold`]: bold,
    [`pri-text-letter-spacing-${letterSpacing}`]: letterSpacing,
    'pri-text-italic': italic,
    'pri-text-no-wrap': noWrap,
  });
  const hasMaxLength = 'maxLength' in rest && typeof children === 'string';
  const text = useMemo(() => {
    if (!hasMaxLength) return children;
    if (hasMaxLength && noTooltip)
      return <span className={classNames}>{`${children.slice(0, rest.maxLength)}...`}</span>;
    return children.length > (rest.maxLength || 0) ? (
      <Tooltip text={children}>
        <span className={classNames}>{`${children.slice(0, rest.maxLength)}...`}</span>
      </Tooltip>
    ) : (
      children
    );
  }, [hasMaxLength, children, rest.maxLength, noTooltip, classNames]);
  return <span className={classNames}>{text}</span>;
};
