import cn from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';

import Bloc from '../shaping/Bloc';
import './index.scss';

export type AccordionProps = {
  active?: boolean;
  label?: ReactNode;
  onClick?: () => void;
};

const Accordion: FunctionComponent<AccordionProps> = ({ className, children, active, label, onClick }) => (
  <div className={cn('pri-accordion', { 'pri-accordion-active': active }, className)}>
    <Bloc onClick={onClick}>{label}</Bloc>
    <div className="pri-accordion-items">{children}</div>
  </div>
);

export default Accordion;
