import React, { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Pill from '~/components/shared/Pill';

const IntegrationPill: FunctionComponent<{ enabled: boolean; pending?: boolean }> = ({ enabled, pending }) => {
  const { t } = useTranslation();

  if (pending) {
    return <Pill color="blue">{t('badges.pending')}</Pill>;
  }

  return enabled ? (
    <Pill color="green">{t('badges.connected')}</Pill>
  ) : (
    <Pill color="grey">{t('badges.not_configured')}</Pill>
  );
};

export default IntegrationPill;
