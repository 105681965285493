import cn from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';
import { Text } from '../../../typography/Text';

export type HelperProps = {
  helper: string | ReactNode;
  children?: never;
};

const Helper: FunctionComponent<HelperProps> = ({ className, helper }) =>
  typeof helper === 'string' ? (
    <Text className={cn('pri-helper', 'pri-mt-3', className)} size="caption" variant="muted">
      {helper}
    </Text>
  ) : (
    helper
  );

export default Helper;
