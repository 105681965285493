import { useCallback } from 'react';
import axios from 'axios';
import type { SSOLoginRequestParams, SSOLoginRequestResponse } from './types';
import { SSO_LOGIN_LINK } from '~/config/routes';

export const useSSOLoginRequest = () => {
  const submitSSOLoginRequest = useCallback(
    async ({ slug, type, payload, requestData, headers }: SSOLoginRequestParams): Promise<SSOLoginRequestResponse> => {
      const { data } = await axios.post(
        SSO_LOGIN_LINK(slug),
        {
          type,
          ...payload,
          SAMLRequest: {
            issuer: requestData.issuer,
            requestId: requestData.requestId,
          },
        },
        {
          headers,
        },
      );

      return { samlResponse: data };
    },
    [],
  );

  return { submitSSOLoginRequest };
};
