import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Loader from '~/components/shared/Loader';
import Button from '~/components/shared/buttons/Button';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { Title } from '~/components/shared/typography';
import { CompanyLoginConnectionStrategy } from '~/graphql/schema';
import { SignupContext } from '~/pages/Signup/context';
import useSignupWithPasswordCallback from '~/pages/Signup/part/SignupWithPasswordPart/useSignupWithPasswordCallback';
import SignupWithGooglePart from '~/pages/Signup/part/SocialSignup/SignupWithGooglePart';
import type { SelfSignUpWithPasswordSchemaFields } from '~/validations/signupWithPassword';
import { selfSignUpWithPasswordSchema } from '~/validations/signupWithPassword';

const SignupWithPasswordPart: FunctionComponent = () => {
  const { t } = useTranslation();
  const { connections, employeeData, removeOrganization, organization } = useContext(SignupContext);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<SelfSignUpWithPasswordSchemaFields>({
    mode: 'onSubmit',
    resolver: zodResolver(selfSignUpWithPasswordSchema(t)),
  });

  const { handleSubmit, watch } = form;

  const { email, password } = watch();

  const signupAndAuthenticate = useSignupWithPasswordCallback({
    email,
    password,
    setIsLoading,
  });

  const areSignupRequiredFieldsValid = useMemo(() => !!employeeData && !!organization, [employeeData, organization]);

  useEffect(() => {
    if (!areSignupRequiredFieldsValid) {
      removeOrganization();
    }
  }, [areSignupRequiredFieldsValid, removeOrganization]);

  const onSubmit = useCallback(async () => {
    await signupAndAuthenticate();
  }, [signupAndAuthenticate]);

  if (!areSignupRequiredFieldsValid) return null;

  return (
    <Bloc className="login-form">
      <Title size="smallHeading">{t('titles.sign_up_with_google')}</Title>
      <FormProvider {...form}>
        <Flex direction="column" gap={4} className="pri-mt-6">
          {connections.map(connection => {
            switch (connection.strategy) {
              case CompanyLoginConnectionStrategy.Google:
                return <SignupWithGooglePart key="google" />;
              default:
                return null;
            }
          })}
          <Title size="smallHeading" className="pri-mt-6">
            {t('titles.sign_up_with_email')}
          </Title>
          <Form form={form} className="pri-mt-3">
            <Input
              name="email"
              label={t('forms.labels.work_email_address')}
              placeholder={t('forms.placeholders.email_signup')}
              form={form}
              required
              type="email"
            />
            <Input
              name="password"
              label={t('forms.labels.password')}
              placeholder={t('forms.placeholders.password_signup')}
              form={form}
              required
              type="password"
            />
            {isLoading ? (
              <Flex fullHeight fullWidth justify="center" align="center">
                <Loader />
              </Flex>
            ) : (
              <Flex direction="row">
                <Button className="flex-1 pri-mt-2 pri-mb-5" onClick={handleSubmit(onSubmit)} fullWidth>
                  {t('buttons.signup')}
                </Button>
              </Flex>
            )}
          </Form>
        </Flex>
      </FormProvider>
    </Bloc>
  );
};

export default SignupWithPasswordPart;
