import cn from 'classnames';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { Form } from '~/components/shared/forms/Form';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { Truthy } from '~/utils/truthy';
import Select from './Select';

import './CountrySelector.scss';

type CountrySelectorProps = {
  dark?: boolean;
};

const CountrySelector: FunctionComponent<CountrySelectorProps> = ({ dark }) => {
  const { isBetaActivated } = useContext(PrimoAdminContext);
  const { i18n, t } = useTranslation();
  const [localStorageLanguage, setLanguage] = useLocalStorage<string>('language');

  const resolvedLanguage = localStorageLanguage || i18n.resolvedLanguage;
  const form = useForm({
    defaultValues: {
      language: resolvedLanguage,
    },
  });
  const { watch } = form;
  const language = watch('language');

  useEffect(() => {
    if (!language) return;
    i18n.changeLanguage(language);
    setLanguage(language);
  }, [i18n, language, setLanguage]);

  return (
    <Form form={form}>
      <Select
        form={form}
        className={cn('pri-country-selector', { 'pri-country-selector-dark': dark })}
        name="language"
        options={['en', 'fr', isBetaActivated ? 'cimode' : null].filter(Truthy)}
        placeholder={t(`enums.languages.${resolvedLanguage}`)}
        getOptionLabel={option => t(`enums.languages.${option}`)}
        menuPlacement="top"
        dark={dark}
      />
    </Form>
  );
};

export default CountrySelector;
