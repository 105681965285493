import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { LeftArrowIcon } from '~/components/icons';
import Card from '~/components/shared/Card';
import CardItem from '~/components/shared/CardItem';

import AppIcon from '~/components/icons/app';
import { StepperContext } from '~/components/shared/Stepper';
import Button from '~/components/shared/buttons/Button';
import { RoundedImage } from '~/components/shared/images';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';
import { OffboardingStepperContext } from '../context';

const AppsRecap: FunctionComponent<{ employeeId?: string | null }> = ({ employeeId }) => {
  const { t } = useTranslation();
  const {
    companyExternalIntegrations,
    contextData: { employee, apps },
  } = useContext(OffboardingStepperContext);
  const { currentStep, setCurrentStep } = useContext(StepperContext);

  const deltaDevicesStep = employee?.devices?.length ? 1 : 0;
  const appStep = (employeeId ? 1 : 2) + deltaDevicesStep;

  if (!apps?.length || currentStep <= appStep) {
    return null;
  }

  return (
    <Card
      title={t('steppers.offboarding.deprovisioning')}
      icon={<AppIcon />}
      action={
        <Button
          variant="secondary-dark"
          onClick={() => {
            setCurrentStep(appStep);
          }}
          icon={<LeftArrowIcon width={16} className="pri-mr-4" />}
        >
          {t('buttons.edit')}
        </Button>
      }
    >
      {companyExternalIntegrations
        .filter(integration => apps.some(appName => integration.externalIntegration?.name === appName))
        .map(integration => (
          <CardItem key={integration.id}>
            <Flex justify="start" align="center" gap={5}>
              {integration.externalIntegration?.logoUrl && (
                <RoundedImage size="sm" src={integration.externalIntegration.logoUrl} />
              )}
              <Text>{t(`enums.external_integrations.${integration.externalIntegration?.name.toLowerCase()}`)}</Text>
            </Flex>
          </CardItem>
        ))}
    </Card>
  );
};

export default AppsRecap;
