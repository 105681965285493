/* eslint-disable jsx-a11y/click-events-have-key-events  */
/* eslint-disable jsx-a11y/no-static-element-interactions  */
import cn from 'classnames';
import type { HTMLProps, MouseEventHandler } from 'react';
import React from 'react';

import './index.scss';

export type BlocProps = {
  id?: string;
  backgroundColor?: 'crow' | 'feather-light' | 'white' | 'feather';
  borderColor?: 'grey-light' | 'cream-light';
  rounded?: boolean;
  scrollable?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
} & Pick<HTMLProps<HTMLDivElement>, 'style'>;

const Bloc: FunctionComponent<BlocProps> = ({
  id,
  className,
  children,
  backgroundColor,
  borderColor,
  rounded = false,
  style = {},
  scrollable = false,
  onClick,
  onMouseDown,
}) => (
  <div
    id={id}
    className={cn(className, 'pri-bloc', {
      [`pri-bloc-background-${backgroundColor}`]: backgroundColor,
      [`pri-bloc-border-${borderColor}`]: borderColor,
      'pri-bloc-rounded': rounded,
      'pri-bloc-scrollable': scrollable,
    })}
    style={style}
    onClick={onClick}
    onMouseDown={onMouseDown}
  >
    {children}
  </div>
);

export default Bloc;
