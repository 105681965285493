import React, { useCallback, useEffect, useMemo } from 'react';

import { isOk } from '@primo/operation-result';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

import './index.scss';

import Button from '~/components/shared/buttons/Button';
import ThrottledButton from '~/components/shared/buttons/ThrottledButton';
import Loader from '~/components/shared/Loader';
import Bloc from '~/components/shared/shaping/Bloc';
import Filler from '~/components/shared/shaping/Filler';
import type { DevicePlatform, EnrollmentLinksResult, EnrollmentPackageType } from '~/graphql/schema';
import useQueryGetEnrollmentLinks from '~/hooks/queries/devices/useQueryGetEnrollmentLinks';
import DevicePlatformIconV2 from '../../display/devices/DevicePlatformIcon';

type EnrollmentItem = {
  url?: string | null;
  platform: DevicePlatform;
  type: EnrollmentPackageType;
  buttonState: 'building' | 'request' | 'ready';
};
type EnrollmentCardBaseProps = {
  companyId: string;
  employeeId: string;
  inventory?: boolean;
  mode?: 'download' | 'copy';
  runOnInit?: boolean;
  onQueryResultChange?: (result: EnrollmentLinksResult | null) => void;
  getRefreshFunction?: (refresh: () => Promise<void>) => void;
};

// 'exclude' use case
type EnrollmentCardExcludeProps = EnrollmentCardBaseProps & {
  exclude: EnrollmentPackageType[];
  include?: never;
};

// 'include' use case
type EnrollmentCardIncludeProps = EnrollmentCardBaseProps & {
  include: EnrollmentPackageType[];
  exclude?: never;
};

type EnrollmentCardListProps = EnrollmentCardExcludeProps | EnrollmentCardIncludeProps;

const computeButtonState = (url: string | null | undefined, isPastBuildTime: boolean) => {
  if (url) {
    return 'ready';
  }

  return isPastBuildTime ? 'request' : 'building';
};

const EnrollmentCardList: FunctionComponent<EnrollmentCardListProps> = ({
  mode = 'download',
  exclude,
  include,
  employeeId,
  runOnInit = true,
  inventory,
  companyId,
  className,
  getRefreshFunction,
}) => {
  const { t } = useTranslation();
  const { getEnrollmentLinks, enrollmentLinksLoading, enrollmentLinksResult } = useQueryGetEnrollmentLinks();

  const enrollmentLinks = useMemo(() => {
    if (isOk(enrollmentLinksResult)) {
      return enrollmentLinksResult.data;
    }

    return null;
  }, [enrollmentLinksResult]);

  const refreshEnrollmentLinks = useCallback(async (): Promise<void> => {
    if (!companyId || !employeeId) return;
    if (!inventory && !employeeId) return;

    await getEnrollmentLinks({
      companyId,
      employeeId,
      inventory: inventory === undefined ? false : inventory,
    });
  }, [getEnrollmentLinks, companyId, employeeId, inventory]);

  const packageShouldBeReadyAt = enrollmentLinks?.lastBuildRequestedAt
    ? moment(enrollmentLinks?.lastBuildRequestedAt)
        .utc()
        .add(enrollmentLinks?.averageBuildTimeInMinutes, 'minutes')
        .toDate()
    : null;

  const isPastBuildTime = useMemo(() => moment().utc().isSameOrAfter(packageShouldBeReadyAt), [packageShouldBeReadyAt]);

  const handleLinkReady = (link: string | null | undefined) => {
    if (!link) return;

    if (mode === 'download') {
      window.open(link);
      return;
    }

    if (mode === 'copy') {
      navigator.clipboard.writeText(link);
      toast.success(<span>{t('success.link_copied')}</span>);
    }
  };

  const enrollmentItem = useMemo(
    () =>
      !enrollmentLinks
        ? []
        : enrollmentLinks.links?.reduce<EnrollmentItem[]>((acc, item) => {
            item.urls
              .filter(p => (exclude && !exclude.includes(p.type)) || (include && include.includes(p.type)))
              .forEach(({ url, type }) => {
                acc.push({
                  url,
                  platform: item.platform,
                  type,
                  buttonState: computeButtonState(url, isPastBuildTime),
                });
              });
            return acc;
          }, []),
    [enrollmentLinks, isPastBuildTime, exclude, include],
  );

  useEffect(() => {
    if (getRefreshFunction) {
      getRefreshFunction(refreshEnrollmentLinks);
    }
  }, [getRefreshFunction, refreshEnrollmentLinks]);

  useEffect(() => {
    if (runOnInit) {
      refreshEnrollmentLinks();
    }
  }, [refreshEnrollmentLinks, runOnInit]);

  return !enrollmentItem.length ? (
    <Loader spacing="sm" />
  ) : (
    <Bloc className={cn('pri-enrollment-card-list', className)}>
      <Flex className="pri-enrollement-card-list-items" direction="column" fullWidth>
        {enrollmentItem.map(item => (
          <Flex key={item.type} className="pri-enrollment-card-row" direction="row" gap={5} align="center" fullWidth>
            <DevicePlatformIconV2 className="pri-device-icon-rounded" platform={item.platform} width={24} />
            <Text bold>
              {t(
                `titles.enrollment_stepper.download_installer.${item.platform.toLowerCase()}${`_${item.type.toLowerCase()}`}`.toString(),
              )}
            </Text>
            <Filler />
            {enrollmentLinksLoading && <Loader spacing="sm" />}
            {item.buttonState === 'building' && !enrollmentLinksLoading && (
              <ThrottledButton
                variant="secondary-dark"
                timerStartedAt={enrollmentLinks?.lastBuildRequestedAt ?? undefined}
                onFinish={() => {
                  refreshEnrollmentLinks();
                }}
                timer={moment(packageShouldBeReadyAt).utc().diff(moment.utc().toDate(), 'seconds')}
                text={t('buttons.enrollment_stepper.building')}
              />
            )}
            {item.buttonState === 'request' && !enrollmentLinksLoading && (
              <Button
                loading={enrollmentLinksLoading}
                variant="secondary-dark"
                onClick={() => {
                  refreshEnrollmentLinks();
                }}
              >
                {t('buttons.enrollment_stepper.request')}
              </Button>
            )}
            {item.buttonState === 'ready' && !enrollmentLinksLoading && (
              <Button
                loading={enrollmentLinksLoading}
                variant="secondary-dark"
                onClick={() => {
                  handleLinkReady(item.url);
                }}
              >
                {t(`buttons.enrollment_stepper.${mode}`)}
              </Button>
            )}
          </Flex>
        ))}
      </Flex>
    </Bloc>
  );
};

export default EnrollmentCardList;
