import { Button as CarbonButton } from '@carbon/react';
import cn from 'classnames';
import React, { useMemo } from 'react';

import Loader from '~/components/shared/Loader';
import Tooltip from '~/components/shared/Tooltip';
import type { ButtonProps } from './types';

import { Title } from '../../typography';
import './index.scss';

export { ButtonProps };

const Button: FunctionComponent<ButtonProps> = ({
  className,
  children,
  disabled,
  disabledTooltip,
  onClick,
  loading,
  variant = 'primary',
  type = 'button',
  fullWidth,
  tooltipAlign,
  form,
  icon,
  iconPosition,
}) => {
  const button = useMemo(
    () => (
      <CarbonButton
        className={cn('pri-button', `pri-variant-${variant}`, className, {
          '--full-width': fullWidth,
          'pri-disabled': disabled || loading,
          'pri-icon-right': iconPosition === 'right',
          'pri-icon-only': !children,
        })}
        disabled={disabled}
        onClick={onClick}
        type={type}
        form={form}
      >
        {icon}
        {loading ? <Loader spacing="sm" /> : <Title className="pri-button-text">{children}</Title>}
      </CarbonButton>
    ),
    [children, className, disabled, form, fullWidth, icon, iconPosition, loading, onClick, type, variant],
  );

  return disabled && disabledTooltip ? (
    <Tooltip text={disabledTooltip || ''} align={tooltipAlign}>
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default Button;
