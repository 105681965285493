import type { MouseEvent, MouseEventHandler } from 'react';
import React, { useCallback } from 'react';
import { useForm, type FieldValues, type Path } from 'react-hook-form';
import type { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import type { CompanyExternalIntegration, ExternalIntegration } from '~/graphql/schema';

import Checkbox from '~/components/shared/forms/Checkbox';
import { RoundedImage } from '~/components/shared/images';
import IntegrationCard from '~/components/featured/cards/IntegrationCard';
import IntegrationPill from '~/components/featured/pills/IntegrationPill';

type ConnectedAppIntegrationCardProps<FV extends FieldValues> = {
  children?: never;
  className?: string;
  companyExternalIntegration: Pick<
    CompanyExternalIntegration,
    'provisioningEnabled' | 'provisioningRedactedToken' | 'provisioningInstallationPending'
  > & {
    externalIntegration?: Pick<ExternalIntegration, 'name' | 'logoUrl'> | null;
  };
  selected?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  checkbox?: {
    value: CompanyExternalIntegration;
    name: Path<FV>;
    register: UseFormRegister<FV>;
  };
};

const ConnectedAppIntegrationCard = <FV extends FieldValues>({
  className,
  companyExternalIntegration,
  checkbox,
  selected,
  disabled,
  onClick,
}: ConnectedAppIntegrationCardProps<FV>) => {
  const { t } = useTranslation();
  const form = useForm();

  const onTileClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        event.preventDefault();
        return;
      }
      onClick?.(event);
    },
    [disabled, onClick],
  );

  if (!companyExternalIntegration.externalIntegration) {
    return null;
  }

  const { logoUrl, name } = companyExternalIntegration.externalIntegration;

  return (
    <IntegrationCard
      title={t(`enums.external_integrations.${name?.toLowerCase()}`)}
      selected={selected}
      className={className}
      onClick={onTileClick}
      titleSuffix={
        checkbox ? (
          <Checkbox form={form} name={checkbox.name} checked={selected} />
        ) : (
          <IntegrationPill
            enabled={!!companyExternalIntegration.provisioningEnabled}
            pending={!!companyExternalIntegration.provisioningInstallationPending}
          />
        )
      }
      description={t(`enums.external_integrations.descriptions.${name?.toLowerCase()}`)}
      icon={<RoundedImage src={logoUrl} />}
    />
  );
};

export default ConnectedAppIntegrationCard;
