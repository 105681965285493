import { zodResolver } from '@hookform/resolvers/zod';
import type { TFunction } from 'i18next';
import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import zod from 'zod';

import Button from '~/components/shared/buttons/Button';
import { Form } from '~/components/shared/forms/Form';
import Radio from '~/components/shared/forms/Radio';
import Flex from '~/components/shared/shaping/Flex';
import { Title } from '~/components/shared/typography';
import { SSO_LOGIN_LINK } from '~/config/routes';
import { OtpChannel } from '~/graphql/schema';

import { SSOLoginContext } from './context';

const emailFormSchema = (t: TFunction) =>
  zod.object({
    channel: zod.nativeEnum(OtpChannel, { errorMap: () => ({ message: t('errors.invalid_channel_enum') }) }),
  });

type EmailFormFields = zod.infer<ReturnType<typeof emailFormSchema>>;

const ChannelForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { channel, email, sendOTPRequest, sendOTPRequestLoading, requestData, getChannelsData } =
    useContext(SSOLoginContext);
  const form = useForm<EmailFormFields>({
    defaultValues: { channel },
    resolver: zodResolver(emailFormSchema(t)),
  });
  const currentChannel = form.watch('channel');

  const onSubmit = useCallback(
    (data: EmailFormFields) => {
      if (requestData) {
        sendOTPRequest(email, data.channel);
      }
    },
    [email, requestData, sendOTPRequest],
  );

  useEffect(() => {
    if (slug && !email) {
      navigate(SSO_LOGIN_LINK(slug));
    }
  }, [email, slug, navigate]);

  return (
    <Flex direction="column" className="pri-p-2" gap={8}>
      <Flex direction="column" gap={2}>
        <Title size="largeLabel">{t('forms.titles.otp_channel')}</Title>
      </Flex>
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <Radio
          name="channel"
          className="pri-mb-6"
          options={[
            { label: getChannelsData?.email, value: OtpChannel.Email },
            { label: getChannelsData?.personalEmail, value: OtpChannel.PersonalEmail },
          ]}
          form={form}
        />
        <Flex className="pri-mt-8" gap={3} direction="column" fullWidth>
          <Button
            fullWidth
            type="submit"
            loading={sendOTPRequestLoading}
            disabled={sendOTPRequestLoading || !requestData || !currentChannel}
          >
            {t('buttons.continue')}
          </Button>
          {!!slug && (
            <Button className="flex-1" fullWidth variant="secondary-light" link={SSO_LOGIN_LINK(slug)}>
              {t('buttons.back')}
            </Button>
          )}
        </Flex>
      </Form>
    </Flex>
  );
};
export default ChannelForm;
