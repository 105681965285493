export const ROOT = '/';

/** LOGOUT */
export const LOGOUT_ROUTE = () => '/logout';

/** SIGNUP */
export const SIGNUP_ROUTE = () => '/signup';
export const SIGNUP_CALLBACK_ROUTE = () => '/signup/callback';

/** LOGIN */
export const LOGIN_ROUTE = () => '/login';
export const LOGIN_CALLBACK_ROUTE = () => '/login/callback';
export const LOGIN_REQUEST_RESET_PASSWORD_ROUTE = () => 'reset-password-request';
export const LOGIN_REQUEST_RESET_PASSWORD_LINK = () => `${LOGIN_ROUTE()}/${LOGIN_REQUEST_RESET_PASSWORD_ROUTE()}`;

/** EMPLOYEE SESSION */
export const EMPLOYEE_SESSION_BASE_ROUTE = () => '/:slug/employee';
export const EMPLOYEE_SESSION_BASE_ROUTE_LINK = (slug: string) => `/${slug}/employee/`;

/** EMPLOYEE SESSION EQUIPMENT */
export const EMPLOYEE_SESSION_EQUIPMENT_ROUTE = () => `equipment`;
export const EMPLOYEE_SESSION_EQUIPMENT_LINK = (slug: string) => `/${slug}/employee/equipment`;

/** EMPLOYEE SESSION DRAWER  */
export const EMPLOYEE_SESSION_DEVICE_RAWER_ROUTE = (id = ':deviceId') => `device/${id}`;
export const EMPLOYEE_SESSION_DEVICE_DRAWER_LINK = (slug: string, id = ':deviceId') =>
  `${EMPLOYEE_SESSION_EQUIPMENT_LINK(slug)}/${EMPLOYEE_SESSION_DEVICE_RAWER_ROUTE(id)}`;

/** EMPLOYEE SESSION LOGIN  */
export const EMPLOYEE_SESSION_LOGIN_ROUTE = () => `/login`;
export const EMPLOYEE_SESSION_LOGIN_LINK = (slug: string) => `/${slug}/employee/login`;
export const EMPLOYEE_SESSION_LOGIN_CALLBACK_ROUTE = () => `/login/callback`;

/** SSO LOGIN */
export const SSO_LOGIN_ROUTE = () => '/sso/:slug/login';
export const SSO_LOGIN_LINK = (slug: string) => `/sso/${slug}/login`;
export const SSO_LOGIN_CHANNEL_ROUTE = () => 'channels';
export const SSO_LOGIN_CHANNEL_LINK = (slug: string) => `${SSO_LOGIN_LINK(slug)}/${SSO_LOGIN_CHANNEL_ROUTE()}`;
export const SSO_LOGIN_OTP_ROUTE = () => 'otp';
export const SSO_LOGIN_OTP_LINK = (slug: string) => `${SSO_LOGIN_LINK(slug)}/${SSO_LOGIN_OTP_ROUTE()}`;

/** EMPLOYEES  */
export const EMPLOYEES_ROUTE = () => `employees`;
export const EMPLOYEES_LINK = () => `${ROOT}${EMPLOYEES_ROUTE()}/`;

export const EMPLOYEE_DETAILS_ROUTE = (id = ':employeeId') => `${id}`;
export const EMPLOYEE_DETAILS_LINK = (id = ':employeeId') => `${EMPLOYEES_LINK()}${EMPLOYEE_DETAILS_ROUTE(id)}`;

/** ONBOARDINGS  */
export const ONBOARDINGS_ROUTE = () => `onboardings`;
export const ONBOARDINGS_LINK = () => `${ROOT}${ONBOARDINGS_ROUTE()}/`;

export const ONBOARDINGS_DETAILS_ROUTE = (id = ':employeeId') => `${id}`;
export const ONBOARDINGS_DETAILS_LINK = (id = ':employeeId') => `${ONBOARDINGS_LINK()}${ONBOARDINGS_DETAILS_ROUTE(id)}`;

/** OFBOARDINGS  */
export const OFFBOARDINGS_ROUTE = () => `offboardings`;
export const OFFBOARDINGS_LINK = () => `${ROOT}${OFFBOARDINGS_ROUTE()}/`;

export const OFFBOARDINGS_DETAILS_ROUTE = (id = ':employeeId') => `${id}`;
export const OFFBOARDINGS_DETAILS_LINK = (id = ':employeeId') =>
  `${OFFBOARDINGS_LINK()}${OFFBOARDINGS_DETAILS_ROUTE(id)}`;

/**
 * DEVICES
 */
export const DEVICES_ROUTE = () => `devices`;
export const DEVICES_LINK = () => `${ROOT}${DEVICES_ROUTE()}/`;

/**
 * ACCESSORIES
 */
export const ACCESSORIES_ROUTE = () => `accessories`;
export const ACCESSORIES_LINK = () => `${ROOT}${ACCESSORIES_ROUTE()}/`;

/**
 * DEVICE DETAILS
 */
export const DEVICE_DETAILS_ROUTE = (id = ':deviceId') => `${id}`;
export const DEVICE_DETAILS_LINK = (id = ':deviceId') => `${DEVICES_LINK()}${DEVICE_DETAILS_ROUTE(id)}`;

/**
 * ACCESSORY DETAILS
 */
export const ACCESSORY_DETAILS_ROUTE = (id = ':accessoryId') => `${id}`;
export const ACCESSORY_DETAILS_LINK = (id = ':accessoryId') => `${ACCESSORIES_LINK()}${ACCESSORY_DETAILS_ROUTE(id)}`;

/**
 * DEVICE EMPLOYEE
 */
export const DEVICE_EMPLOYEE_DETAILS_ROUTE = (deviceId = ':deviceId', employeeId = ':employeeId') =>
  `${DEVICE_DETAILS_ROUTE(deviceId)}/${EMPLOYEES_ROUTE()}/${EMPLOYEE_DETAILS_ROUTE(employeeId)}`;
export const DEVICE_EMPLOYEE_DETAILS_LINK = (deviceId = ':deviceId', employeeId = ':employeeId') =>
  `${DEVICE_DETAILS_LINK(deviceId)}/${EMPLOYEES_ROUTE()}/${EMPLOYEE_DETAILS_ROUTE(employeeId)}`;

/**
 * ACCESSORY EMPLOYEE
 */
export const ACCESSORY_EMPLOYEE_DETAILS_ROUTE = (accessoryId = ':accessoryId', employeeId = ':employeeId') =>
  `${ACCESSORY_DETAILS_ROUTE(accessoryId)}/${EMPLOYEES_ROUTE()}/${EMPLOYEE_DETAILS_ROUTE(employeeId)}`;
export const ACCESSORY_EMPLOYEE_DETAILS_LINK = (accessoryId = ':accessoryId', employeeId = ':employeeId') =>
  `${ACCESSORY_DETAILS_LINK(accessoryId)}/${EMPLOYEES_ROUTE()}/${EMPLOYEE_DETAILS_ROUTE(employeeId)}`;

/**
 * EMPLOYEES DEVICE DETAILS
 */
export const EMPLOYEES_DEVICE_DETAILS_ROUTE = (employeeId = ':employeeId', deviceId = ':deviceId') =>
  `${EMPLOYEE_DETAILS_ROUTE(employeeId)}/${DEVICES_ROUTE()}/${DEVICE_DETAILS_ROUTE(deviceId)}`;
export const EMPLOYEES_DEVICE_DETAILS_LINK = (employeeId = ':employeeId', deviceId = ':deviceId') =>
  `${EMPLOYEE_DETAILS_LINK(employeeId)}/${DEVICES_ROUTE()}/${DEVICE_DETAILS_ROUTE(deviceId)}`;

/**
 * ONBOARDINGS DEVICE DETAILS
 */
export const ONBOARDINGS_DEVICE_DETAILS_ROUTE = (employeeId = ':employeeId', deviceId = ':deviceId') =>
  `${ONBOARDINGS_DETAILS_ROUTE(employeeId)}/${DEVICES_ROUTE()}/${DEVICE_DETAILS_ROUTE(deviceId)}`;
export const ONBOARDINGS_DEVICE_DETAILS_LINK = (employeeId = ':employeeId', deviceId = ':deviceId') =>
  `${ONBOARDINGS_DETAILS_LINK(employeeId)}/${DEVICES_ROUTE()}/${DEVICE_DETAILS_ROUTE(deviceId)}`;

/**
 * EMPLOYEES ACCESSORY DETAILS
 */
export const EMPLOYEES_ACCESSORY_DETAILS_ROUTE = (employeeId = ':employeeId', accessoryId = ':accessoryId') =>
  `${EMPLOYEE_DETAILS_ROUTE(employeeId)}/${DEVICES_ROUTE()}/${ACCESSORY_DETAILS_ROUTE(accessoryId)}`;
export const EMPLOYEES_ACCESSORY_DETAILS_LINK = (employeeId = ':employeeId', accessoryId = ':accessoryId') =>
  `${EMPLOYEE_DETAILS_LINK(employeeId)}/${DEVICES_ROUTE()}/${ACCESSORY_DETAILS_ROUTE(accessoryId)}`;

/**
 * ONBOARDINGS ACCESSORY DETAILS
 */
export const ONBOARDINGS_ACCESSORY_DETAILS_ROUTE = (employeeId = ':employeeId', accessoryId = ':accessoryId') =>
  `${ONBOARDINGS_DETAILS_ROUTE(employeeId)}/${ACCESSORY_DETAILS_ROUTE(accessoryId)}`;
export const ONBOARDINGS_ACCESSORY_DETAILS_LINK = (employeeId = ':employeeId', accessoryId = ':accessoryId') =>
  `${ONBOARDINGS_DETAILS_LINK(employeeId)}/${ACCESSORY_DETAILS_ROUTE(accessoryId)}`;

/**
 * APPS
 */
export const APPS_ROUTE = () => `apps`;
export const APPS_LINK = () => `${ROOT}${APPS_ROUTE()}/`;

export const APPS_DETAILS_ROUTE = (id = ':externalIntegrationId') => `${id}`;
export const APPS_DETAILS_LINK = (id = ':externalIntegrationId') => `${APPS_LINK()}${APPS_DETAILS_ROUTE(id)}`;

/**
 * ORDERS
 */
export const ORDERS_ROUTE = () => `orders`;
export const ORDERS_LINK = () => `${ROOT}${ORDERS_ROUTE()}/`;
export const ORDER_DETAILS_ROUTE = (id = ':orderId') => `${id}`;
export const ORDER_DETAILS_LINK = (id = ':orderId') => `${ORDERS_LINK()}${ORDER_DETAILS_ROUTE(id)}`;

/**
 * SHIPMENTS
 */
export const SHIPMENTS_ROUTE = () => `shipments`;
export const SHIPMENTS_LINK = () => `${ROOT}${SHIPMENTS_ROUTE()}/`;
export const SHIPMENT_DETAILS_ROUTE = (id = ':shipmentId') => `${id}`;
export const SHIPMENT_DETAILS_LINK = (id = ':shipmentId') => `${SHIPMENTS_LINK()}${SHIPMENT_DETAILS_ROUTE(id)}`;

/**
 * ORDER EMPLOYEE
 */
export const ORDERS_EMPLOYEE_ROUTE = (orderId = ':orderId', employeeId = ':employeeId') =>
  `${ORDER_DETAILS_ROUTE(orderId)}/employees/${employeeId}`;
export const ORDERS_DETAILS_EMPLOYEE_DETAILS_LINK = (orderId = ':orderId', employeeId = ':employeeId') =>
  `/${ORDERS_ROUTE()}/${orderId}/employees/${employeeId}`;

/**
 * SETTINGS
 */
export const SETTINGS_ROUTE = () => `settings`;
export const SETTINGS_LINK = () => `${ROOT}${SETTINGS_ROUTE()}/`;
export const SETTINGS_EMPLOYEES_LINK = () => `${SETTINGS_LINK()}?tab=employees`;
export const SETTINGS_MDM_LINK = () => `${SETTINGS_LINK()}?tab=mdm_settings`;
export const SETTINGS_INTEGRATIONS_TAB_LINK = () => `${SETTINGS_LINK()}?tab=integrations`;

/**
 * SETTINGS_INTEGRATIONS
 */
export const SETTINGS_INTEGRATIONS_ROUTE = () => `integrations`;
export const SETTINGS_INTEGRATIONS_LINK = () => `${SETTINGS_LINK()}${SETTINGS_INTEGRATIONS_ROUTE()}/`;

export const SETTINGS_INTEGRATIONS_ABM_ROUTE = () => `apple-business-manager`;
export const SETTINGS_INTEGRATIONS_ABM_LINK = () =>
  `${SETTINGS_INTEGRATIONS_LINK()}${SETTINGS_INTEGRATIONS_ABM_ROUTE()}`;

/**
 * DASHBOARD
 */
export const DASHBOARD_ROUTE = () => `dashboard`;
export const DASHBOARD_LINK = () => `${ROOT}${DASHBOARD_ROUTE()}/`;

/**
 * CATALOG
 */
export const CATALOG_ROUTE = () => `catalog`;
export const CATALOG_LINK = () => `${ROOT}${CATALOG_ROUTE()}/`;

/**
 * PROFILES
 */
export const PROFILES_ROUTE = () => `profiles`;
export const PROFILES_LINK = () => `${ROOT}${PROFILES_ROUTE()}/`;
export const PROFILE_DETAILS_ROUTE = (id = ':profileId') => `${id}`;
export const PROFILE_DETAILS_LINK = (id = ':profileId') => `${PROFILES_LINK()}${PROFILE_DETAILS_ROUTE(id)}`;

/**
 * CALLBACKS
 */
export const CALLBACK_OAUTH_SERVICE_PROVIDER_ROUTE = (externalIntegrationId = ':externalIntegrationId') =>
  `callback/oauth/service-provider/${externalIntegrationId}`;
