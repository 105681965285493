import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEqual } from 'lodash';
import ConnectedAppIntegrationCard from '~/components/featured/cards/ConnectedAppIntegrationCard';
import Flex from '~/components/shared/shaping/Flex';
import { Step, StepperContext } from '~/components/shared/Stepper';
import { Text, Title } from '~/components/shared/typography';

import type { CompanyExternalIntegration } from '~/graphql/schema';
import { useResolver } from '~/hooks/queries/useResolver';
import { OnboardingStepperContext } from '../context';

const ChooseAppsStep: FunctionComponent<{ companyId: string }> = ({ companyId }) => {
  const { t } = useTranslation();
  const { contextData, setContextData, onFinish } = useContext(OnboardingStepperContext);
  const { setFooterProps } = useContext(StepperContext);
  const form = useForm({
    defaultValues: { companyExternalIntegrations: contextData.companyExternalIntegrations },
  });

  const options = useMemo(
    () => ({
      runOnInit: true,
      variables: {
        enabledOnly: true,
        companyId,
      },
    }),
    [companyId],
  );
  const { result: fetchedCompanyExternalIntegrations } = useResolver(
    'useGetCompanyExternalIntegrationsLazyQuery',
    options,
  );

  const selectedCEIs = form.watch('companyExternalIntegrations');
  const selectedCEIsHasLicensingOrBackupEmail = selectedCEIs?.some(
    cei => cei.externalIntegration?.hasLicensing || cei.requireBackupEmail,
  );

  const totalPrice = useMemo(
    () => contextData.equipments?.catalog.reduce((acc, { item, quantity }) => acc + item.priceNoVAT * quantity, 0) || 0,
    [contextData.equipments?.catalog],
  );

  useEffect(() => {
    if (isEqual(contextData.companyExternalIntegrations, selectedCEIs)) return;
    setContextData({ ...contextData, companyExternalIntegrations: selectedCEIs });
  }, [selectedCEIs, contextData, setContextData]);

  const onNext = useCallback(async () => {
    const newContextData = { ...contextData, companyExternalIntegrations: selectedCEIs };
    setContextData(newContextData);

    if (selectedCEIsHasLicensingOrBackupEmail) return true;

    await onFinish(newContextData);
    return false;
  }, [contextData, selectedCEIs, setContextData, selectedCEIsHasLicensingOrBackupEmail, onFinish]);

  const onIntegrationCardClick = useCallback(
    (integration: CompanyExternalIntegration) => {
      if (!integration.externalIntegration) {
        return;
      }
      const companyExternalIntegrationsRecord = (selectedCEIs || []).reduce(
        (acc, next) => {
          acc[next.id] = next;
          return acc;
        },
        {} as Record<string, CompanyExternalIntegration>,
      );

      if (companyExternalIntegrationsRecord[integration.id]) {
        delete companyExternalIntegrationsRecord[integration.id];
      } else {
        companyExternalIntegrationsRecord[integration.id] = integration;
      }
      form.setValue('companyExternalIntegrations', Object.values(companyExternalIntegrationsRecord));
      if (isEqual(contextData.companyExternalIntegrations, selectedCEIs)) return;
      setContextData({ ...contextData, companyExternalIntegrations: selectedCEIs });
    },
    [contextData, form, selectedCEIs, setContextData],
  );

  useEffect(() => {
    if (selectedCEIsHasLicensingOrBackupEmail) {
      setFooterProps({ onNext });
      return;
    }
    setFooterProps({
      onNext,
      nextLabel:
        totalPrice > 0 ? t('steppers.onboarding.confirm_and_place_order') : t('steppers.onboarding.confirm_onboarding'),
    });
  }, [totalPrice, setFooterProps, onNext, t, selectedCEIsHasLicensingOrBackupEmail]);

  return (
    <Step size="lg">
      <Flex className="pri-mb-10" direction="column" gap={3}>
        <Title size="subHeading" bold>
          {t('steppers.onboarding.connect_employee_to_your_apps', {
            firstName: contextData.employee?.firstName ?? '',
          })}
        </Title>
        <Text>{t('steppers.onboarding.connect_employee_to_your_apps_description')}</Text>
      </Flex>
      <Flex fullWidth direction="row" wrap="wrap" gap={5}>
        {fetchedCompanyExternalIntegrations?.getCompanyExternalIntegrations?.map(integration =>
          integration.externalIntegration ? (
            <ConnectedAppIntegrationCard
              key={integration.externalIntegration.name}
              selected={(selectedCEIs || []).some(cei => cei.id === integration.id)}
              disabled={!!integration.provisioningInstallationPending}
              checkbox={{
                name: 'companyExternalIntegrations',
                value: integration,
                register: form.register,
              }}
              companyExternalIntegration={integration}
              onClick={e => {
                e.preventDefault();
                onIntegrationCardClick(integration);
              }}
            />
          ) : null,
        )}
      </Flex>
    </Step>
  );
};

export default ChooseAppsStep;
