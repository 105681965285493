import React, { useContext } from 'react';

import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

import Callout from '~/components/shared/Callout';
import Select from '~/components/shared/forms/Select';
import Loader from '~/components/shared/Loader';
import { useResolver } from '~/hooks/queries/useResolver';
import useBuildJsxOption from '~/utils/licenses/useBuildJsxOption';
import { OnboardingStepperContext } from '../../context';
import type { ChooseLicenseFormData } from './types';

const Licensing: FunctionComponent<{ form: UseFormReturn<ChooseLicenseFormData> }> = ({ form }) => {
  const { contextData } = useContext(OnboardingStepperContext);
  const { buildJsxOption } = useBuildJsxOption();
  const { t } = useTranslation();
  const { result: licenses, loading: licensesLoading } = useResolver(
    'useGetCompanyExternalIntegrationsLicensesLazyQuery',
    {
      runOnInit: true,
      variables: { companyExternalIntegrationsIds: contextData.companyExternalIntegrations?.map(cei => cei.id) },
    },
  );

  return licensesLoading ? (
    <Loader />
  ) : (
    contextData.companyExternalIntegrations?.map(cei => (
      <Flex direction="column" gap={5} fullWidth>
        <Select
          label={t('forms.labels.external_integration_license', {
            externalIntegrationName: cei.externalIntegration?.name,
          })}
          name={`companyExternalIntegrationLicenses.${cei.externalIntegrationId}`}
          form={form}
          options={
            licenses?.getCompanyExternalIntegrationsLicenses?.find(l => l.companyExternalIntegrationId === cei.id)
              ?.companyExternalIntegrationLicenses || []
          }
          isOptionDisabled={option => option.availableSeats <= 0}
          getOptionLabel={option => option.name}
          getOptionValue={option => option?.externalId}
          getOptionJsxLabel={buildJsxOption}
          isMulti
        />
        <Callout
          variant="info"
          title={t('titles.select_license_for_external_integration', {
            employeeName: contextData.employee?.firstName || '-',
            integrationName: t(`enums.external_integrations.${cei.externalIntegration?.name.toLowerCase()}`),
          })}
          description={
            <Text>
              {t('texts.select_license_for_external_integration')}&nbsp;
              {t('texts.manage_license_at')}&nbsp;
              <a href="https://admin.microsoft.com/Adminportal/Home#/subscriptions" target="_blank" rel="noreferrer">
                admin.microsoft.com
              </a>
              .
            </Text>
          }
        />
      </Flex>
    ))
  );
};

export default Licensing;
