import React from 'react';

import cn from 'classnames';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';

import Tile from '~/components/shared/Tile';
import './style.scss';

import RadioCheckedIcon from '~/components/icons/radioCheckedIcon';
import Bloc from '~/components/shared/shaping/Bloc';
import { DeviceEnrollmentStatusFilter } from '~/graphql/schema';
import { EmployeeDeviceEnrollementStatusPill } from '../../pills/devicePills/EmployeeDeviceEnrollementStatusPill';

type EnrollmentDeviceCardProps = {
  title: string;
  subTitle: string;
  selected?: boolean;
  className?: string;
  icon: React.ReactNode;
  enrolled?: boolean;
  onClick?: () => void;
};

const EnrollmentDeviceCard = ({
  className,
  title,
  subTitle,
  selected,
  onClick,
  icon,
  enrolled = false,
}: EnrollmentDeviceCardProps) => (
  <Tile
    selected={selected}
    className={cn('pri-enrollment-device-card', className, { '--not-selectable': !onClick })}
    onClick={onClick}
  >
    <Flex direction="column" gap={3}>
      <Flex justify="between">
        <Bloc className="pri-enrollment-device-card-icons">{icon}</Bloc>
        {selected && <RadioCheckedIcon width={26} />}
        {!selected && enrolled && (
          <EmployeeDeviceEnrollementStatusPill enrollmentStatus={DeviceEnrollmentStatusFilter.Enrolled} />
        )}
      </Flex>
      <Title size="largeLabel">{title}</Title>
      <Text size="caption" variant="muted" className="pri-enrollment-device-card-text">
        {subTitle}
      </Text>
    </Flex>
  </Tile>
);

export default EnrollmentDeviceCard;
