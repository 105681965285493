import cn from 'classnames';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';

import { Tile as CarbonTile } from '@carbon/react';
import ChevronRightIcon from '~/components/icons/chevronRight';
import Filler from '~/components/shared/shaping/Filler';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography/Text';
import type { PillProps } from '../Pill';
import './style.scss';

export type CardTileProps = {
  prefixIcon: ReactNode;
  title: ReactNode;
  subTitle?: string;
  statusPill?: ReactElement<PillProps> | ReactElement<PillProps>[];
  onClick?: () => void;
  suffixNode?: ReactNode;
  children?: ReactChildren;
  spacing?: 'md' | 'lg';
  noFiller?: boolean;
};

const CardTile: FunctionComponent<CardTileProps> = ({
  className,
  prefixIcon,
  subTitle,
  onClick,
  title,
  statusPill,
  suffixNode,
  spacing,
  noFiller,
  children,
}) => (
  <Flex
    onClick={onClick}
    className={cn(className, 'pri-cardTile', {
      'pri-cardTile-clickable': onClick,
      [`pri-cardTile-${spacing}`]: spacing,
    })}
  >
    <CarbonTile className="pri-w-100">
      <Flex direction="column" gap={5}>
        <Flex align="center" justify="between">
          <Flex className="flex-1" gap={4} align="center">
            {prefixIcon}
            <Flex className="flex-1" direction="column" gap={2}>
              <Text bold className="flex-1 title-overflow">
                {title}
              </Text>
              {subTitle && <Text variant="muted-light">{subTitle}</Text>}
            </Flex>
            {statusPill}
          </Flex>
          {(onClick || suffixNode) && !noFiller && <Filler />}
          {onClick ? suffixNode || <ChevronRightIcon className="pri-ml-8" color="black" /> : suffixNode}
        </Flex>
        {children}
      </Flex>
    </CarbonTile>
  </Flex>
);

export default CardTile;
