import { zodResolver } from '@hookform/resolvers/zod';
import { isRefusal } from '@primo/operation-result';
import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Callout from '~/components/shared/Callout';
import type { ModalProps } from '~/components/shared/Modal';
import Modal from '~/components/shared/Modal';
import Input from '~/components/shared/forms/Input';
import Flex from '~/components/shared/shaping/Flex';
import useMutationCreateCompanyBillingProfile from '~/hooks/queries/companyBillingProfiles/useMutationCreateCompanyBillingProfile';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import type { CompanyBillingProfileFormFields } from './schema';
import { companyBillingProfileFormSchema } from './schema';

type CreateCompanyBillingProfileModalProps = Pick<ModalProps, 'onClose'>;

export const CreateCompanyBillingProfileModal = ({ onClose }: CreateCompanyBillingProfileModalProps) => {
  const { t } = useTranslation();
  const { company } = useContext(PrimoAdminContext);
  const form = useForm<CompanyBillingProfileFormFields>({
    resolver: zodResolver(companyBillingProfileFormSchema(t)),
  });
  const { createCompanyBillingProfile, createCompanyBillingProfileDataLoading } =
    useMutationCreateCompanyBillingProfile();

  const onSubmit = useCallback(
    async (data: CompanyBillingProfileFormFields) => {
      if (!company) return;
      const result = await createCompanyBillingProfile({
        companyId: company.id,
        name: data.name,
      });
      if (isRefusal(result)) {
        result.reason.forEach(reason => {
          toast.error(<span>{t(`errors.refusals.create_company_billing_profile.${reason.toLowerCase()}`)}</span>);
        });
      } else {
        toast.success(<span>{t('success.create_company_billing_profile_success')}</span>);
        onClose?.();
      }
    },
    [company, createCompanyBillingProfile, onClose, t],
  );
  const { handleSubmit } = form;

  return (
    <Modal
      title={t('modals.titles.create_company_billing_profile')}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      footer={{
        cancelButton: true,
        confirmButton: t('actions.company_billing_profile.create'),
        confirmButtonLoading: createCompanyBillingProfileDataLoading,
      }}
    >
      <Flex direction="column" gap={6}>
        <Callout variant="info" title={t('modals.texts.create_company_billing_profile_info')} />
        <Input
          name="name"
          form={form}
          type="text"
          label={t('forms.labels.name')}
          placeholder={t('forms.placeholders.company_billing_profile_name')}
        />
      </Flex>
    </Modal>
  );
};
