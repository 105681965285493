import { ClassPrefix, ComposedModal, ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import cn from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useScroll } from 'react-use';

import type { ButtonProps } from '~/components/shared/buttons/Button';
import Button from '~/components/shared/buttons/Button';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';

import './index.scss';

export * from './Raw';

export type ModalProps = {
  onClose?: () => void;
  onConfirm?: () => void;
  onAlternate?: () => void;
  submitForm?: string;
  title: string;
  subTitle?: string;
  footer?:
    | {
        alternateButton?: boolean | string | null;
        cancelButton?: boolean | string | null;
        confirmButton?: boolean | string | null;
        confirmButtonVariant?: ButtonProps['variant'];
        confirmButtonLoading?: boolean;
        confirmButtonDisabled?: boolean;
      }
    | false;
  keepInitialHeight?: boolean;
  maxHeight?: number;
};

const Modal: FunctionComponent<ModalProps> = ({
  className,
  title,
  subTitle,
  children,
  footer,
  onClose,
  onAlternate,
  submitForm,
  onConfirm,
  keepInitialHeight,
  maxHeight,
}) => {
  const { confirmButton, confirmButtonVariant, confirmButtonLoading, cancelButton, confirmButtonDisabled } =
    footer || {};
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { current: currentScrollRef } = scrollRef;
  const { y } = useScroll(scrollRef);
  const modalRef = useRef<HTMLDivElement>(null);

  const root = useMemo(() => document.getElementById('root'), []);

  useEffect(() => {
    if (modalRef.current && (keepInitialHeight || maxHeight)) {
      const container = modalRef.current.firstChild as HTMLDivElement;
      container.style.maxHeight = `${maxHeight || container.scrollHeight}px`;
    }
  }, [maxHeight, keepInitialHeight]);

  const isScrolledToBottom = useMemo(() => {
    if (!currentScrollRef) {
      return true;
    }
    return currentScrollRef.scrollHeight - currentScrollRef.clientHeight <= y + 1;
  }, [y, currentScrollRef]);

  if (!root) return null;

  return createPortal(
    <ClassPrefix prefix="carbon">
      <ComposedModal
        ref={modalRef}
        open
        className={cn(className, 'pri-modal')}
        onClickCapture={element => {
          const targetClasses = (element.target as HTMLDivElement).classList;
          if (
            targetClasses.contains('carbon--modal') ||
            targetClasses.contains('carbon--modal-close__icon') ||
            targetClasses.contains('carbon--modal-close')
          ) {
            onClose?.();
          } else {
            // Edge case causing sometimes the click event to be on the child element
            const parentClasses = (element.target as HTMLDivElement).parentElement?.classList;
            if (
              parentClasses?.contains('carbon--modal') ||
              parentClasses?.contains('carbon--modal-close__icon') ||
              parentClasses?.contains('carbon--modal-close')
            ) {
              onClose?.();
            }
          }
        }}
        preventCloseOnClickOutside
      >
        <div className="pri-modal-scroll" ref={scrollRef}>
          <ModalHeader className="pri-modal-header">
            <Flex direction="column" gap={2}>
              <Title size="smallHeading" bold>
                {title}
              </Title>
              {subTitle && (
                <Text className="pri-modal-header-subtitle" variant="muted">
                  {subTitle}
                </Text>
              )}
            </Flex>
          </ModalHeader>
          <ModalBody className="pri-modal-body">
            <ClassPrefix prefix="cds">{children}</ClassPrefix>
          </ModalBody>
          {footer !== false && (
            <ModalFooter className={cn('pri-modal-footer', { '--shadow': !isScrolledToBottom })}>
              <ClassPrefix prefix="cds">
                <Flex justify="start" gap={3}>
                  {(!footer || cancelButton) && (
                    <Button variant="secondary-dark" onClick={onClose}>
                      {typeof cancelButton === 'string' ? cancelButton : t('buttons.cancel')}
                    </Button>
                  )}
                  {(!footer || confirmButton) && (
                    <Button
                      type={submitForm ? 'submit' : 'button'}
                      form={submitForm}
                      variant={confirmButtonVariant || 'primary'}
                      onClick={onConfirm}
                      loading={confirmButtonLoading}
                      disabled={confirmButtonDisabled}
                    >
                      {typeof confirmButton === 'string' ? confirmButton : t('buttons.confirm')}
                    </Button>
                  )}
                  {footer && typeof footer.alternateButton === 'string' && onAlternate && (
                    <Button variant="secondary-dark" onClick={onAlternate}>
                      {footer.alternateButton}
                    </Button>
                  )}
                </Flex>
              </ClassPrefix>
            </ModalFooter>
          )}
        </div>
      </ComposedModal>
    </ClassPrefix>,
    root,
  );
};

export default Modal;
