import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as theme from '~/assets/scss/theme.module.scss';
import { LaunchIcon } from '~/components/icons';
import Loader from '~/components/shared/Loader';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';
import { HasMDMAndSSOLogin } from '~/features/mdm/HasMDMSSOLogin';

import { type Company, type Employee } from '~/graphql/schema';
import { useResolver } from '~/hooks/queries/useResolver';

type MDMSSODeepLoginType = {
  company: Pick<Company, 'id' | 'mdmSSOLoginEnabled' | 'mdmProvider'>;
  loggedEmployee: Pick<Employee, 'id' | 'isAdmin' | 'companyId' | 'isOnlyEmployee'>;
};

export const MDMSSODeepLogin: FunctionComponent<MDMSSODeepLoginType> = ({ company, loggedEmployee }) => {
  const { t } = useTranslation();
  const { loading, executeResolver: requestSSOLoginUrl } = useResolver('useRequestMdmssoLoginUrlMutation');

  const handleSSOLoginClick = async () => {
    if (loading) return;
    try {
      const { result } = await requestSSOLoginUrl({ companyId: company.id });

      if (result?.requestMDMSSOLoginUrl) {
        window.open(result?.requestMDMSSOLoginUrl, '_blank');
      }
    } catch (err) {
      toast.error(t('errors.error_when_requesting_sso_url'));
    }
  };

  if (loggedEmployee.isOnlyEmployee || !loggedEmployee.isAdmin || !company.mdmSSOLoginEnabled || !company.mdmProvider)
    return null;

  return (
    <HasMDMAndSSOLogin>
      <Bloc className="pri-pt-5 pri-mdm-sso-login cursor-pointer" onClick={handleSSOLoginClick}>
        <Flex justify="between" align="center">
          <Text variant="light" size="caption" bold>
            {t(`buttons.${company.mdmProvider?.toLowerCase()}`)}
          </Text>
          {loading ? <Loader spacing="xs" /> : <LaunchIcon width={16} color={theme.colorWhite} />}
        </Flex>
      </Bloc>
    </HasMDMAndSSOLogin>
  );
};
