import type { ReactNode } from 'react';
import React from 'react';

import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

export type CtaButtonProps = {
  label: string;
  icon: ReactNode;
  onClick: () => void;
};

export const CtaButton: FunctionComponent<CtaButtonProps> = ({ icon, label, onClick }) => (
  <Bloc className="pri-cta-button pri-p-3 pri-flex-1 cursor-pointer" onClick={onClick}>
    <Flex direction="column" align="center" gap={3}>
      {icon}
      <Text className="pri-cta-button-label" variant="light" size="caption" bold>
        {label}
      </Text>
    </Flex>
  </Bloc>
);
