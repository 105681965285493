import React from 'react';
import type { UseFormReturn } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import Input from '~/components/shared/forms/Input';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';
import type { ChooseShippingAndBillingStepFields } from '~/features/steppers/OrderStepper/_steps/ChooseShippingAndBillingStep';

type AddCommentProps = {
  form: UseFormReturn<ChooseShippingAndBillingStepFields>;
};

const AddComment: FunctionComponent<AddCommentProps> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column">
      <Text className="pri-mb-4 pri-mt-10" size="bodyLarge" bold>
        {t('steppers.onboarding.anything_to_add')}
      </Text>
      <Input name="companyComment" label={t('forms.labels.comment')} form={form} />
    </Flex>
  );
};

export default AddComment;
