import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const Search: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 16 16" fill="none">
    <path
      d="M14.5 13.793L10.7239 10.0169C11.6313 8.92758 12.0838 7.53039 11.9872 6.11596C11.8907 4.70154 11.2525 3.37879 10.2055 2.42289C9.15855 1.46699 7.78335 0.951523 6.366 0.983731C4.94865 1.01594 3.59828 1.59334 2.59581 2.59581C1.59333 3.59829 1.01593 4.94866 0.983723 6.36601C0.951515 7.78336 1.46698 9.15856 2.42288 10.2055C3.37879 11.2525 4.70153 11.8907 6.11596 11.9872C7.53038 12.0838 8.92758 11.6313 10.0169 10.724L13.7929 14.5L14.5 13.793ZM2 6.5C2 5.60999 2.26392 4.73996 2.75838 3.99994C3.25285 3.25992 3.95565 2.68314 4.77792 2.34255C5.60019 2.00195 6.50499 1.91284 7.3779 2.08647C8.25082 2.2601 9.05264 2.68869 9.68198 3.31802C10.3113 3.94736 10.7399 4.74918 10.9135 5.6221C11.0872 6.49501 10.998 7.39981 10.6575 8.22208C10.3169 9.04435 9.74008 9.74715 9.00006 10.2416C8.26004 10.7361 7.39001 11 6.5 11C5.30693 10.9987 4.1631 10.5241 3.31948 9.68052C2.47585 8.8369 2.00132 7.69307 2 6.5V6.5Z"
      fill={color}
    />
  </svg>
);

export default Search;
