import type { ReactNode } from 'react';
import React, { useContext } from 'react';

import Accordion from '~/components/shared/Accordion';
import Badge from '~/components/shared/Badge';
import Flex from '~/components/shared/shaping/Flex';
import { NavContext } from './NavContext';
import { NavItem } from './NavItem';

export type NavGroupProps = {
  group: string;
  label: React.ReactNode;
  to: string;
  badge?: number | null;
  icon?: ReactNode;
};

export const NavGroup: FunctionComponent<NavGroupProps> = ({ children, group, label, to, icon, badge }) => {
  const { current } = useContext(NavContext);

  return (
    <Accordion
      active={current === group}
      label={
        <NavItem
          arrow
          arrowCollapsed={current !== group}
          activable={false}
          label={
            <>
              {label}
              {!!badge && current !== group && <Badge content={badge} />}
            </>
          }
          icon={icon}
          to={to}
          group={group}
        />
      }
    >
      <Flex direction="column" className="pri-pt-5" gap={5}>
        {children}
      </Flex>
    </Accordion>
  );
};
