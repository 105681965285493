import { Buffer } from 'buffer';
import pako from 'pako';
import { useMemo } from 'react';
import { useSearchParam } from 'react-use';

export const useSAMLRequest = () => {
  const SAMLRequest = useSearchParam('SAMLRequest');

  const data = useMemo(() => {
    if (SAMLRequest) {
      const decode = Buffer.from(SAMLRequest, 'base64');

      try {
        const inflated = pako.inflateRaw(new Uint8Array(decode), { to: 'string' });
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(inflated, 'text/xml');

        const samlpAuthnRequestElement = xmlDoc.getElementsByTagName('samlp:AuthnRequest')?.[0];
        const issuer = xmlDoc.getElementsByTagName('saml:Issuer')?.[0].textContent;
        const id = samlpAuthnRequestElement.getAttribute('ID');
        const callbackURL = samlpAuthnRequestElement.getAttribute('AssertionConsumerServiceURL');

        if (!issuer || !id || !callbackURL) {
          return null;
        }

        return { issuer, id, callbackURL };
      } catch (e) {
        return null;
      }
    }
    return null;
  }, [SAMLRequest]);

  return data;
};
