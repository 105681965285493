import React from 'react';
import cn from 'classnames';

import './style.scss';

type RoundedImageProps = {
  src: string;
  size?: 'sm' | 'md' | 'lg';
  cover?: boolean;
};

export const RoundedImage: FunctionComponent<RoundedImageProps> = ({ src, className, size, cover = false }) => (
  <div
    className={cn('pri-rounded-image', `pri-size-${size}`, className, { '--background-cover': cover })}
    style={{ backgroundImage: `url('${src}')` }}
  />
);
