import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import DeviceTypeIconV2 from '~/components/featured/typeIcon/DeviceTypeIcon';
import CardTile from '~/components/shared/CardTile';
import { DeviceNameV2 } from '~/components/shared/DeviceNameV2';
import { Form } from '~/components/shared/forms/Form';
import Loader from '~/components/shared/Loader';
import Flex from '~/components/shared/shaping/Flex';
import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { Text, Title } from '~/components/shared/typography';
import { HasMDM, HexnodeLegacy } from '~/features/mdm';
import { DevicePlatform, EmployeeOffboardingDeviceRecycle, HandleDeviceData, MdmAction } from '~/graphql/schema';
import { useCompany } from '~/hooks/useCompany';
import { Truthy } from '~/utils/truthy';

import DeviceTagsPills from '~/components/featured/pills/devicePills/DeviceTagsPills';
import { FlagIcon, WarningIcon } from '~/components/icons';
import Callout from '~/components/shared/Callout';
import Select from '~/components/shared/forms/Select';
import { OffboardingStepperContext } from '../context';
import type { OffboardingStepperContextData } from '../types';

type FieldsValue = {
  [key: string]: {
    handleDeviceData: HandleDeviceData;
    handleDeviceRecycle: EmployeeOffboardingDeviceRecycle;
  };
};

type DeviceOptionAvailability = {
  [key: string]: boolean;
};

const HandleDevicesStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const company = useCompany();
  const { loading, setContextData, contextData } = useContext(OffboardingStepperContext);
  const { currentStep, setFooterProps, setCurrentStep } = useContext(StepperContext);
  const form = useForm<FieldsValue>({
    defaultValues: {
      ...contextData.employee?.devices.reduce<FieldsValue>(
        (prev, next) => ({
          ...prev,
          [next.id]: {
            handleDeviceData: HandleDeviceData.Nothing,
            handleDeviceRecycle: EmployeeOffboardingDeviceRecycle.Nothing,
          },
        }),
        {},
      ),
    },
  });
  const { getValues, watch } = form;

  const watchedOptions = watch();

  const shouldHideLockOptionForDevice = useMemo<DeviceOptionAvailability>(
    () =>
      Object.entries(watchedOptions).reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]:
            value.handleDeviceRecycle === EmployeeOffboardingDeviceRecycle.SellOrRecycle ||
            value.handleDeviceRecycle === EmployeeOffboardingDeviceRecycle.Donate,
        }),
        {},
      ),
    [watchedOptions],
  );

  const wipeOptionIsSelected = useMemo<DeviceOptionAvailability>(
    () =>
      Object.entries(watchedOptions).reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]: value.handleDeviceData === HandleDeviceData.SoftWipe,
        }),
        {},
      ),
    [watchedOptions],
  );

  const hasLockOptionV2 = useMemo(() => company?.mdmActions?.includes(MdmAction.Lock), [company]);
  const hasWipeOptionV2 = useMemo(() => company?.mdmActions?.includes(MdmAction.Wipe), [company]);

  const onNext = useCallback(async () => {
    const values = getValues();
    const devices = Object.entries(values)
      .map(([id, { handleDeviceData, handleDeviceRecycle }]) => {
        const device = contextData.employee?.devices.find(({ id: deviceId }) => deviceId === id);
        if (!device) return null;
        return {
          ...device,
          handleDeviceData,
          handleDeviceRecycle,
        };
      })
      .filter(Truthy);
    const newContextData: OffboardingStepperContextData = { ...contextData, devices };

    setContextData(newContextData);
    return true;
  }, [contextData, getValues, setContextData]);

  useEffect(() => {
    setFooterProps({
      onNext,
      nextLabel: t('buttons.next'),
    });
  }, [setFooterProps, onNext, t]);

  useEffectOnce(() => {
    if (!contextData?.employee?.devices?.length) {
      setCurrentStep(currentStep + 1);
    }
  });

  const devices = contextData.employee?.devices || [];

  return (
    <Step size="lg">
      <Flex className="pri-mb-10" direction="column" gap={3}>
        <Title size="subHeading" bold>
          {t('steppers.offboarding.device_reallocation')}
        </Title>
        <Text>
          {t('steppers.offboarding.device_reallocation_description', {
            firstName: contextData?.employee?.firstName ?? '',
          })}
        </Text>
      </Flex>
      <Form form={form}>
        {loading ? (
          <Loader spacing="md" />
        ) : (
          <Flex direction="column" gap={7}>
            <Text className="pri-mb-4" size="bodyLarge" bold>
              {t('steppers.offboarding.what_to_do_with_equipment')}
            </Text>
            <Flex direction="column" gap={10}>
              {devices.map((device, index) => {
                const {
                  id: deviceId,
                  type,
                  platform,
                  activationLockEnabled,
                  computedActivationLockBypassKey: hasBypassKey,
                } = device;
                return (
                  <Flex direction="column" gap={6} key={deviceId}>
                    <CardTile
                      prefixIcon={<DeviceTypeIconV2 type={type} />}
                      title={<DeviceNameV2 {...device} withSerial />}
                      statusPill={<DeviceTagsPills device={device} />}
                    >
                      {wipeOptionIsSelected[deviceId] &&
                        platform === DevicePlatform.Macos &&
                        !!activationLockEnabled && (
                          <Callout
                            variant={hasBypassKey ? 'warning' : 'error'}
                            icon={hasBypassKey ? <FlagIcon width={16} /> : <WarningIcon width={16} />}
                            title={t('notices.permanent_lock_warning.title')}
                            description={
                              hasBypassKey
                                ? t('notices.permanent_lock_warning.with_bypass_code_text')
                                : t('notices.permanent_lock_warning.without_bypass_code_text')
                            }
                          />
                        )}
                    </CardTile>
                    <Select
                      name={`${device.id}.handleDeviceRecycle`}
                      form={form}
                      label={t('steppers.questions.handle_device_recycle')}
                      getOptionLabel={option => t(`forms.labels.device_recycle.${option.toLowerCase()}`)}
                      options={Object.values(EmployeeOffboardingDeviceRecycle)}
                      menuPlacement={devices.length > 1 && index === devices.length - 1 ? 'top' : 'bottom'}
                    />
                    <HexnodeLegacy>
                      <Flex direction="column">
                        <Select
                          name={`${device.id}.handleDeviceData`}
                          form={form}
                          label={t('steppers.questions.handle_device_data')}
                          getOptionLabel={option => t(`forms.labels.device_data.${option.toLowerCase()}`)}
                          options={
                            shouldHideLockOptionForDevice[device.id]
                              ? Object.values(HandleDeviceData).filter(v => v !== HandleDeviceData.Lock)
                              : Object.values(HandleDeviceData)
                          }
                          disabled={!device.agentEnabled}
                          menuPlacement={devices.length > 1 && index === devices.length - 1 ? 'top' : 'bottom'}
                          helper={!device.agentEnabled ? t('notices.why_disabled') : undefined}
                        />
                      </Flex>
                    </HexnodeLegacy>
                    <HasMDM>
                      <Flex direction="column">
                        <Select
                          name={`${device.id}.handleDeviceData`}
                          form={form}
                          label={t('steppers.questions.handle_device_data')}
                          getOptionLabel={option => t(`forms.labels.device_data.${option.toLowerCase()}`)}
                          options={Object.values(HandleDeviceData)
                            .filter(d =>
                              shouldHideLockOptionForDevice[device.id] ? d !== HandleDeviceData.Lock : true,
                            )
                            .filter(d => (!hasLockOptionV2 ? d !== HandleDeviceData.Lock : true))
                            .filter(d => (!hasWipeOptionV2 ? d !== HandleDeviceData.SoftWipe : true))}
                          disabled={!device.agentEnabled}
                          menuPlacement={devices.length > 1 && index === devices.length - 1 ? 'top' : 'auto'}
                          helper={!device.agentEnabled ? t('notices.why_disabled') : undefined}
                        />
                      </Flex>
                    </HasMDM>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        )}
      </Form>
    </Step>
  );
};

export default HandleDevicesStep;
