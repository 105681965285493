import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '~/components/shared/Loader';
import { Recap, StepperContext, Steps } from '~/components/shared/Stepper';
import { useCompany } from '~/hooks/useCompany';

import { Title } from '~/components/shared/typography';
import AppsRecap from './_recaps/AppsRecap';
import DevicesRecap from './_recaps/DevicesRecap';
import EmployeeRecap from './_recaps/EmployeeRecap';
import ChooseAppsStep from './_steps/ChooseApps';
import ChooseEmployeeStep from './_steps/ChooseEmployeeStep';
import FillOffboardingInformationStep from './_steps/FillOffboardingInformationStep';
import HandleDevicesStep from './_steps/HandleDevicesStep';
import { OffboardingStepperContext } from './context';

const OffboardingStepperContent: FunctionComponent<{ employeeId?: string | null }> = ({ employeeId }) => {
  const { t } = useTranslation();
  const { currentStep } = useContext(StepperContext);
  const {
    contextData: { employee },
  } = useContext(OffboardingStepperContext);
  const company = useCompany();

  if (!company) {
    return null;
  }

  if (employeeId && !employee) {
    return <Loader />;
  }

  return (
    <>
      <Steps>
        {employeeId ? undefined : <ChooseEmployeeStep />}
        {employee?.devices?.length ? <HandleDevicesStep /> : undefined}
        {company?.hasProvisioning && employee?.provisioning?.length ? <ChooseAppsStep /> : undefined}
        <FillOffboardingInformationStep />
      </Steps>
      {(employeeId || currentStep > 1) && employee && (
        <Recap>
          <Title size="subHeading">{t('titles.employee_offboarding', { firstName: employee?.firstName ?? '' })}</Title>
          <EmployeeRecap />
          <DevicesRecap employeeId={employeeId} />
          <AppsRecap employeeId={employeeId} />
        </Recap>
      )}
    </>
  );
};

export default OffboardingStepperContent;
