import React from 'react';

import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

type EquipmentPropertyProps = {
  name: string;
  value: string;
};

const EquipmentProperty: FunctionComponent<EquipmentPropertyProps> = ({ name, value }) => (
  <Flex justify="between">
    <Text size="caption" bold variant="muted">
      {name}
    </Text>
    <Text size="caption" maxLength={16} variant="light-muted">
      {value}
    </Text>
  </Flex>
);
export default EquipmentProperty;
