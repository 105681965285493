import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isRefusal } from '@primo/operation-result';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import CheckBox from '~/components/shared/Checkbox';
import Loader from '~/components/shared/Loader';
import Button from '~/components/shared/buttons/Button';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import { LOGIN_ROUTE } from '~/config/routes';
import useMutationCreateOrUpdateCompany from '~/hooks/queries/companies/useMutationCreateOrUpdateCompany';
import { SignupContext } from '~/pages/Signup/context';
import type { SelfSignupCompanyDetailsFields } from '~/validations/signupCompanyDetails';
import { selfSignupCompanyDetailsSchema } from '~/validations/signupCompanyDetails';
import { SignupCguModal } from '../SignupCguModal';

const SignupCompanyDetailPart: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setOrganization, setEmployeeData, removeOrganization } = useContext(SignupContext);
  const [showCguModal, toggleCguModal] = useToggle(false);
  const form = useForm<SelfSignupCompanyDetailsFields>({
    mode: 'onSubmit',
    defaultValues: {
      cguAgreement: false,
    },
    resolver: zodResolver(selfSignupCompanyDetailsSchema(t)),
  });
  const { createOrUpdateCompany, createOrUpdateCompanyLoading } = useMutationCreateOrUpdateCompany();
  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = form;

  const { cguAgreement } = watch();

  const onCGUConfirm = useCallback(() => {
    toggleCguModal();
    setValue('cguAgreement', true);
  }, [toggleCguModal, setValue]);

  const onSubmit = useCallback(
    async (variables: SelfSignupCompanyDetailsFields) => {
      const { employeeJob, companyName: name, companySize: size, employeeFirstName, employeeLastName } = variables;
      const result = await createOrUpdateCompany({ name, size, eulaAccepted: true, activated: true });
      if (isRefusal(result)) {
        result.reason.forEach(reason => {
          toast.error(<span>{t(`errors.refusals.create_or_update_company.${reason.toLowerCase()}`)}</span>);
        });
        removeOrganization();
        return;
      }

      const { auth0Id, employeesCount } = result.data;
      if (!auth0Id || !employeeFirstName || !employeeLastName || !employeeJob) {
        toast.error(<span>{t(`errors.refusals.create_or_update_company.generic`)}</span>);
        removeOrganization();
        return;
      }

      if (employeesCount > 0) {
        toast.error(<span>{t(`errors.refusals.create_or_update_company.employee_already_exists_for_company`)}</span>);
        removeOrganization();
        return;
      }

      setOrganization(auth0Id);
      setEmployeeData({
        jobTitle: employeeJob,
        firstName: employeeFirstName,
        lastName: employeeLastName,
        email: null,
      });
    },
    [createOrUpdateCompany, setOrganization, setEmployeeData, removeOrganization, t],
  );

  return (
    <Flex direction="column" className="pri-mb-10">
      <Title size="smallHeading">{t('titles.enter_company_information')}</Title>
      <Form form={form} className="pri-mt-7" onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="row" gap={5}>
          <Input
            name="employeeFirstName"
            label={t('forms.labels.firstName')}
            placeholder={t('forms.placeholders.your_first_name')}
            form={form}
            required
            type="text"
          />
          <Input
            name="employeeLastName"
            label={t('forms.labels.lastName')}
            placeholder={t('forms.placeholders.your_last_name')}
            form={form}
            required
            type="text"
          />
        </Flex>
        <Input
          name="employeeJob"
          label={t('forms.labels.company_employee_role')}
          placeholder={t('forms.placeholders.company_employee_role')}
          form={form}
          required
          type="text"
        />
        <Flex direction="row" gap={5}>
          <Input
            name="companyName"
            label={t('forms.labels.company_name')}
            placeholder={t('forms.placeholders.company_name')}
            form={form}
            required
            type="text"
          />
          <Input
            name="companySize"
            label={t('forms.labels.company_size')}
            placeholder={t('forms.placeholders.company_size')}
            form={form}
            required
            type="number"
          />
        </Flex>
        <Flex direction="row" justify="between" align="baseline">
          <CheckBox
            onClick={() => setValue('cguAgreement', !cguAgreement)}
            checked={cguAgreement}
            label={t('forms.labels.accept_terms')}
            invalid={!!errors?.cguAgreement}
            invalidText={errors.cguAgreement?.message}
          />
          <Button onClick={toggleCguModal} variant="link">
            {t('buttons.read_in_details')}
          </Button>
        </Flex>

        <Flex direction="row">
          {createOrUpdateCompanyLoading ? (
            <Flex fullHeight fullWidth justify="center" align="center">
              <Loader />
            </Flex>
          ) : (
            <Button disabled={createOrUpdateCompanyLoading} className="pri-flex-1 pri-mt-5" type="submit" fullWidth>
              {t('buttons.next')}
            </Button>
          )}
        </Flex>
      </Form>
      {showCguModal && <SignupCguModal onClose={toggleCguModal} onButtonConfirm={onCGUConfirm} />}
      <Flex className="pri-mt-5" direction="row" justify="center" align="center" gap={2}>
        <Text>{t('texts.already_client')}</Text>
        <Link to={LOGIN_ROUTE()} className="pri-flex pri-flex-align-center">
          <Text>{t('links.login')}</Text>
        </Link>
      </Flex>
    </Flex>
  );
};

export default SignupCompanyDetailPart;
