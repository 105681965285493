import moment from 'moment';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsyncFn, useSearchParam } from 'react-use';

import { toast } from 'react-toastify';
import { SSO_LOGIN_CHANNEL_LINK, SSO_LOGIN_OTP_LINK } from '~/config/routes';
import { OtpChannel, useGetRedactedUserDataLazyQuery, useSendOtpMutation } from '~/graphql/schema';
import { useSAMLRequest } from '~/hooks/useSAMLRequest';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { useSAMLResponseFormSubmit } from './helper/useSAMLResponseFormSubmit';
import { useSSOLoginRequest } from './helper/useSSOLoginRequest';
import { LoginType } from './helper/useSSOLoginRequest/types';

type ChannelData = { email: string; personalEmail: string };

export type SSOLoginContextProps = {
  email: string;
  requestData?: { issuer: string; id: string; callbackURL: string } | null;
  relayState: string | null;
  sendOTPRequest: (email: string, channel?: OtpChannel, redirect?: boolean) => void;
  sendOTPRequestError: Error | undefined;
  sendOTPRequestLoading: boolean;
  sendOTPRequestNextTryAt: Date | null;
  getChannels: (email: string) => void;
  getChannelsError: Error | undefined;
  getChannelsLoading: boolean;
  getChannelsData?: ChannelData;
  channel?: OtpChannel;
  loading?: boolean;
};

export const SSOLoginContext = createContext<SSOLoginContextProps>({
  email: '',
  requestData: null,
  relayState: null,
  sendOTPRequest: () => {},
  sendOTPRequestError: undefined,
  sendOTPRequestLoading: false,
  sendOTPRequestNextTryAt: null,
  getChannels: () => {},
  getChannelsError: undefined,
  getChannelsLoading: false,
  getChannelsData: undefined,
  channel: undefined,
  loading: undefined,
});

export const SSOLoginProvider: FunctionComponent = ({ children }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { slug } = useParams();
  const [email, setEmail] = useState('');
  const [getChannelsData, setGetChannelsData] = useState<ChannelData | undefined>();
  const [channel, setChannel] = useState<OtpChannel | undefined>();
  const [sendOTPRequestError, setSendOTPRequestError] = useState<Error | undefined>();
  const [getChannelsError, setGetChannelsError] = useState<Error | undefined>();
  const [sendOTPRequestNextTryAt, setNextTryAt] = useState<Date | null>(null);
  const [sendOTP] = useSendOtpMutation();
  const requestData = useSAMLRequest();
  const relayState = useSearchParam('RelayState');
  const { submitSAMLResponseForm, loading: samlFormSubmitLoading } = useSAMLResponseFormSubmit();
  const { submitSSOLoginRequest } = useSSOLoginRequest();
  const { isAuthenticated, token, isLoading: primoContextLoading } = useContext(PrimoAdminContext);

  const [{ loading: primoAutoLoginLoading }, triggerPrimoAutoLogin] = useAsyncFn(
    async (samlRequestData: { issuer: string; id: string; callbackURL: string }, accessToken: string) => {
      if (!slug) {
        toast.error(<span>{t('errors.failed_to_autologin_missing_slug')}</span>);
        return;
      }

      try {
        const { samlResponse } = await submitSSOLoginRequest({
          slug,
          type: LoginType.PRIMO,
          requestData: {
            issuer: samlRequestData.issuer,
            requestId: samlRequestData.id,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!samlResponse) {
          toast.error(<span>{t('errors.failed_to_autologin_missing_response')}</span>);
          return;
        }

        submitSAMLResponseForm({
          callbackURL: samlRequestData.callbackURL,
          rawSamlResponse: samlResponse,
          relayState,
        });
      } catch (err) {
        toast.error(<span>{t('errors.failed_to_autologin_missing_unknown')}</span>);
      }
    },
  );

  useEffect(() => {
    if (isAuthenticated && slug && requestData && token) {
      triggerPrimoAutoLogin(requestData, token);
    }
  }, [isAuthenticated, requestData, slug, token, triggerPrimoAutoLogin, t]);

  const [getChannelsQuery, { loading: getChannelsLoading }] = useGetRedactedUserDataLazyQuery({
    onCompleted: data => {
      if (data.getRedactedUserData?.__typename === 'QueryGetRedactedUserDataError') {
        setGetChannelsError(
          new Error(
            data.getRedactedUserData.reasons
              .map(r => t(`errors.${r.toLowerCase()}`, { defaultValue: t('errors.fail_to_get_channels') }))
              .join(', '),
          ),
        );
        return;
      }
      if (data.getRedactedUserData?.__typename === 'QueryGetRedactedUserDataSuccess') {
        setGetChannelsError(undefined);
        if (!data.getRedactedUserData?.data?.personalEmail) {
          setChannel(OtpChannel.Email);
          sendOTPRequest(email, OtpChannel.Email);
          return;
        }

        if (slug) {
          setGetChannelsData({
            email: data.getRedactedUserData.data.email,
            personalEmail: data.getRedactedUserData.data.personalEmail,
          });
          navigate(SSO_LOGIN_CHANNEL_LINK(slug));
        }
      }
    },
    onError: () => {
      setGetChannelsError(new Error(t('errors.fail_to_get_channels')));
    },
  });

  const [, getChannels] = useAsyncFn(async (inputEmail: string) => {
    if (inputEmail !== email) {
      setEmail(inputEmail);
    }
    setGetChannelsData(undefined);
    await getChannelsQuery({ variables: { email: inputEmail, slug } });
  });

  const [{ loading: sendOTPRequestLoading }, sendOTPRequest] = useAsyncFn(
    async (inputEmail: string, inputChannel?: OtpChannel, redirect = true) => {
      if (inputEmail !== email) {
        setEmail(inputEmail);
      }

      setSendOTPRequestError(undefined);
      try {
        if (!inputChannel) {
          setSendOTPRequestError(new Error(t('errors.no_channel_selected')));
          return;
        }
        setChannel(inputChannel);
        const { data } = await sendOTP({ variables: { company: slug, email: inputEmail, channel: inputChannel } });

        if (data?.sendOTP?.__typename === 'MutationSendOTPError') {
          setSendOTPRequestError(
            new Error(
              data.sendOTP.reasons
                .map(r => t(`errors.otp.${r.toLowerCase()}`, { defaultValue: t('errors.fail_to_send_otp_request') }))
                .join(', '),
            ),
          );
          return;
        }

        if (data?.sendOTP?.__typename === 'MutationSendOTPSuccess') {
          setNextTryAt(moment.utc(data.sendOTP.data).add(1, 'second').toDate());
          if (slug && redirect) {
            navigate(SSO_LOGIN_OTP_LINK(slug));
          }
        }
      } catch (err) {
        setSendOTPRequestError(new Error(t('errors.fail_to_send_otp_request')));
      }
    },
  );

  const value = useMemo(
    () => ({
      loading: primoAutoLoginLoading || primoContextLoading || samlFormSubmitLoading,
      requestData,
      relayState,
      email,
      sendOTPRequest,
      sendOTPRequestError,
      sendOTPRequestLoading,
      sendOTPRequestNextTryAt,
      getChannels,
      getChannelsError,
      getChannelsLoading,
      getChannelsData,
      channel,
    }),
    [
      samlFormSubmitLoading,
      primoAutoLoginLoading,
      primoContextLoading,
      email,
      requestData,
      relayState,
      sendOTPRequest,
      sendOTPRequestError,
      sendOTPRequestLoading,
      sendOTPRequestNextTryAt,
      getChannels,
      getChannelsError,
      getChannelsLoading,
      getChannelsData,
      channel,
    ],
  );

  return <SSOLoginContext.Provider value={value}>{children}</SSOLoginContext.Provider>;
};
