import { isRefusal } from '@primo/operation-result';
import React, { useCallback, useContext, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useBoolean } from 'react-use';
import ShoppingIcon from '~/components/icons/shopping';
import CardTile from '~/components/shared/CardTile';
import Radio from '~/components/shared/forms/Radio';
import Loader from '~/components/shared/Loader';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';
import { CompleteCompanyBillingProfileConfirmationModal } from '~/features/modals/CompleteCompanyBillingProfileConfirmationModal';
import { CreateCompanyBillingProfileModal } from '~/features/modals/CreateCompanyBillingProfileModal';
import type { ChooseShippingAndBillingStepFields } from '~/features/steppers/OrderStepper/_steps/ChooseShippingAndBillingStep';
import useQueryGetCompanyBillingProfiles from '~/hooks/queries/companyBillingProfiles/useQueryGetCompanyBillingProfiles';
import type { PartialCompanyBillingProfile } from '~/providers/primo/PrimoAdminProvider';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

type ChooseBillingProfileProps = {
  form: UseFormReturn<ChooseShippingAndBillingStepFields>;
};

const ChooseBillingProfile: FunctionComponent<ChooseBillingProfileProps> = ({ form }) => {
  const { t } = useTranslation();
  const { company, billingProfiles } = useContext(PrimoAdminContext);
  const { getCompanyBillingProfiles } = useQueryGetCompanyBillingProfiles();

  const [showCreateCompanyBillingProfileModal, toggleCreateCompanyBillingProfileModal] = useBoolean(false);
  const [showCompleteCompanyBillingProfileConfirmationModal, toggleCompleteCompanyBillingProfileConfirmationModal] =
    useBoolean(false);
  const [selectedBillingProfile, setSelectedBillingProfile] = useState<PartialCompanyBillingProfile | null>(null);

  const { setValue } = form;

  const handleCloseCreateCompanyBillingProfileModal = useCallback(() => {
    setValue('billingProfileId', null);
    setSelectedBillingProfile(null);
    toggleCreateCompanyBillingProfileModal();
  }, [toggleCreateCompanyBillingProfileModal, setValue]);

  const handleCloseCompleteCompanyBillingProfileConfirmationModal = useCallback(() => {
    setValue('billingProfileId', null);
    setSelectedBillingProfile(null);
    toggleCompleteCompanyBillingProfileConfirmationModal();
  }, [toggleCompleteCompanyBillingProfileConfirmationModal, setValue]);

  const handleSelectBillingProfile = useCallback(
    async (billingProfileId: string) => {
      if (!company) return;

      const billingProfilesResult = await getCompanyBillingProfiles({ companyId: company.id });
      if (isRefusal(billingProfilesResult)) {
        toast.error(<>{t('errors.chosen_billing_profile_invalid')}</>);
        return;
      }

      if (billingProfileId === 'new') {
        toggleCreateCompanyBillingProfileModal();
        return;
      }

      const billingProfile = billingProfilesResult.data.find(({ id }) => id === billingProfileId);
      if (!billingProfile) {
        toast.error(<>{t('errors.chosen_billing_profile_invalid')}</>);
        return;
      }

      if (!billingProfile.checkoutCompleted) {
        setSelectedBillingProfile(billingProfile);
        toggleCompleteCompanyBillingProfileConfirmationModal();
        return;
      }

      setSelectedBillingProfile(billingProfile);
      setValue('billingProfileId', billingProfileId);
    },
    [
      company,
      getCompanyBillingProfiles,
      toggleCreateCompanyBillingProfileModal,
      toggleCompleteCompanyBillingProfileConfirmationModal,
      setValue,
      t,
    ],
  );

  const options = [
    ...billingProfiles.map(billingProfile => ({
      label: (
        <CardTile
          spacing="lg"
          title={billingProfile.name}
          prefixIcon={<ShoppingIcon color="grey" />}
          key={`card-${billingProfile.id}`}
        />
      ),
      value: billingProfile.id,
      onClick: () => handleSelectBillingProfile(billingProfile.id),
      key: `option-${billingProfile.id}`,
    })),
    {
      label: (
        <CardTile
          spacing="lg"
          title={t('steppers.orders.new_billing_profile')}
          prefixIcon={<ShoppingIcon color="grey" />}
          key="card-new_billing_profile"
        />
      ),
      value: 'new',
      onClick: () => handleSelectBillingProfile('new'),
      key: 'option-new_billing_profile',
    },
  ];

  if (!company) return <Loader spacing="md" />;

  return (
    <Flex direction="column" gap={7} className="pri-mt-10">
      <Text className="pri-mb-4" size="bodyLarge" bold>
        {t('steppers.orders.choose_the_billing_profile')}
      </Text>
      <Radio dotAlignment="center" name="billingProfileId" form={form} options={options} />
      {showCreateCompanyBillingProfileModal && (
        <CreateCompanyBillingProfileModal onClose={handleCloseCreateCompanyBillingProfileModal} />
      )}
      {showCompleteCompanyBillingProfileConfirmationModal && (
        <CompleteCompanyBillingProfileConfirmationModal
          onClose={handleCloseCompleteCompanyBillingProfileConfirmationModal}
          billingProfileCheckoutUrl={selectedBillingProfile?.checkoutUrl}
        />
      )}
    </Flex>
  );
};

export default ChooseBillingProfile;
