import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import type { Company, Employee, GetCompanyForEmployeeQuery } from '~/graphql/schema';
import { EmployeeRoles, useGetCompanyForEmployeeLazyQuery, useGetPrimoContextMutation } from '~/graphql/schema';
import useFullLoadedOnce from '~/hooks/useFullLoadedOnce';
import usePrimoAccessToken from '~/hooks/PrimoContexts/useAccessToken';
import useLogout from '~/hooks/PrimoContexts/useLogout';
import useDatadogRefreshUser from '~/hooks/PrimoContexts/useDatadogRefreshUser';
import { EMPLOYEE_SESSION_BASE_ROUTE_LINK } from '~/config/routes';
import useSessionSwitcher from '~/hooks/PrimoContexts/useSessionSwitch';

export type PrimoEmployeeContextProps = {
  isAuthenticated: boolean;
  isLoading: boolean;
  employee: Pick<
    Employee,
    | 'id'
    | 'fullName'
    | 'companyId'
    | 'email'
    | 'picture'
    | 'initials'
    | 'isInternalEmployee'
    | 'firstName'
    | 'lastName'
    | 'phone'
    | 'isOnlyEmployee'
    | 'isAdmin'
  > | null;
  company: Pick<Company, 'id' | 'name' | 'mdmPlatforms' | 'canonicalName'> | null;
  token: string | undefined;
  isBetaActivated: boolean | undefined;
  setAccessToken: (token: string) => void;
  setEmployeeConnectionError: (err: string) => void;
  removeEmployeeConnectionError: () => void;
  employeeConnectionError: string | undefined;
  logout: (to?: string) => void;
};

export const PrimoEmployeeContext = createContext<PrimoEmployeeContextProps>({
  isAuthenticated: false,
  isLoading: true,
  employeeConnectionError: undefined,
  employee: null,
  company: null,
  isBetaActivated: false,
  token: undefined,
  removeEmployeeConnectionError: () => undefined,
  logout: () => undefined,
  setAccessToken: () => undefined,
  setEmployeeConnectionError: () => undefined,
});

const getCompany = (data: GetCompanyForEmployeeQuery | undefined) => {
  if (data?.getCompany?.__typename === 'QueryGetCompanySuccess') {
    return { company: data.getCompany.data, error: null };
  }
  if (data?.getCompany?.__typename === 'QueryGetCompanyError') {
    return { company: null, error: data.getCompany.reasons.join('; ') };
  }
  return { company: null, error: null };
};

const PrimoEmployeeProvider: FunctionComponent = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isBetaActivated] = useLocalStorage('betaMode', false);
  const { slug } = useParams();

  const logoutCallBack = useCallback(() => {
    window.location.href = EMPLOYEE_SESSION_BASE_ROUTE_LINK(slug || '');
  }, [slug]);

  const [employeeConnectionError, setEmployeeConnectionError, removeEmployeeConnectionError] =
    useLocalStorage<string>('employeeConnectionError');

  const { logout } = useLogout(logoutCallBack);
  const { hasAuthenticatedClient, setAccessToken, accessToken } = usePrimoAccessToken(
    logout,
    setLoading,
    setEmployeeConnectionError,
    removeEmployeeConnectionError,
  );

  /** Primo context is use to sync and fetch the current employee from the access token */
  const [getPrimoEmployeeContext, { data: getPrimoEmployeeContextData, loading: getPrimoEmployeeContextLoading }] =
    useGetPrimoContextMutation({
      onError: () => setLoading(false),
      onCompleted: () => setLoading(false),
    });

  const [getEmployeeCompany, { data: employeeCompanyData, loading: getEmployeeCompanyLoading }] =
    useGetCompanyForEmployeeLazyQuery();

  /** Get employee from the result of the graphql query */
  const employee = useMemo(() => {
    if (getPrimoEmployeeContextData?.syncCurrentEmployee?.__typename === 'MutationSyncCurrentEmployeeSuccess') {
      return getPrimoEmployeeContextData.syncCurrentEmployee.data;
    }
    return null;
  }, [getPrimoEmployeeContextData]);

  /* Record datadog user when it changes */
  useDatadogRefreshUser(employee);

  /** Get employee from the result of the graphql query */
  const company = useMemo(() => getCompany(employeeCompanyData).company, [employeeCompanyData]);
  const fullLoadedOnce = useFullLoadedOnce([loading, getPrimoEmployeeContextLoading, getEmployeeCompanyLoading]);

  const value = useMemo(
    () => ({
      token: accessToken,
      fullLoadedOnce,
      setAccessToken,
      isAuthenticated: !!employee,
      isLoading: !fullLoadedOnce,
      employee,
      isBetaActivated,
      employeeConnectionError,
      setEmployeeConnectionError,
      removeEmployeeConnectionError,
      logout,
      company,
    }),
    [
      accessToken,
      setAccessToken,
      isBetaActivated,
      fullLoadedOnce,
      employee,
      company,
      logout,
      employeeConnectionError,
      setEmployeeConnectionError,
      removeEmployeeConnectionError,
    ],
  );

  /** Check if user is correctly logged in when authenticated client is set */
  useEffect(() => {
    if (hasAuthenticatedClient) {
      getPrimoEmployeeContext();
    }
  }, [hasAuthenticatedClient, getPrimoEmployeeContext]);

  /** Fetch employee's company when employee is defined and got a company id */
  useEffect(() => {
    if (employee?.companyId) {
      getEmployeeCompany({
        variables: {
          companyId: employee.companyId,
        },
      });
    }
  }, [employee, getEmployeeCompany]);

  const { setCurrentSession, currentSession } = useSessionSwitcher(company, employee);

  useEffect(() => {
    if (fullLoadedOnce && currentSession !== EmployeeRoles.CompanyEmployee) {
      setCurrentSession(EmployeeRoles.CompanyEmployee);
    }
  }, [fullLoadedOnce, currentSession, setCurrentSession]);

  return <PrimoEmployeeContext.Provider value={value}>{children}</PrimoEmployeeContext.Provider>;
};

export default PrimoEmployeeProvider;
