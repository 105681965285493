import React from 'react';
import { components } from 'react-select';

export const useMenuComponent = (className?: string) => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MenuComponent: (menuProps: any) => (
    <components.MenuPortal
      {...menuProps}
      className={
        className
          ? className
              .split(' ')
              .map(cn => `${cn}-menu`)
              .join(' ')
          : undefined
      }
    />
  ),
});
