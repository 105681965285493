import classnames from 'classnames';
import type { ReactNode } from 'react';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { matchPath, useLocation, useNavigate as useReactNavigate, useSearchParams } from 'react-router-dom';

import { ChevronBottomIcon } from '~/components/icons';
import Badge from '~/components/shared/Badge';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';
import { NavContext } from './NavContext';

export type NavItemProps = {
  label: ReactNode;
  to: string;
  group?: string;
  arrow?: true;
  arrowCollapsed?: boolean;
  activable?: boolean;
  badge?: number | null;
  icon?: ReactNode;
  tab?: string;
  isDefault?: boolean;
};

export const NavItem: FunctionComponent<NavItemProps> = ({
  arrow = false,
  arrowCollapsed = true,
  activable = true,
  label,
  group,
  to,
  className,
  badge,
  icon,
  tab,
  isDefault,
}) => {
  const { toggle, current } = useContext(NavContext);
  const { pathname } = useLocation();
  const navigate = useReactNavigate();
  const [params] = useSearchParams();

  const active = useMemo(() => {
    const matchSearch = !tab || params.get('tab') === tab;
    const matchRoute = matchPath(
      {
        path: `${to}`,
      },
      pathname,
    );
    if (isDefault && matchRoute && !params.get('tab')) return true;
    return matchSearch && matchRoute && activable;
  }, [tab, params, to, pathname, isDefault, activable]);

  const handleOnClick = useCallback(() => {
    if (active && group && current !== group) {
      toggle(group);
    }

    const searchParams = new URLSearchParams(tab ? { tab } : {});
    navigate(`${to}?${searchParams.toString()}`, { replace: true });
  }, [active, current, group, navigate, tab, to, toggle]);

  useEffect(() => {
    if (active && current !== group) {
      toggle(group || null);
    }
  }, [active, current, group, toggle, label]);

  return (
    <Bloc className={classnames('pri-nav-item', { active }, className)} onClick={handleOnClick}>
      <Flex direction="row" align="center" gap={3}>
        <Flex justify="between" fullWidth align="center" gap={3}>
          {icon || <Bloc style={{ width: 12 }} />}
          <Text variant={active ? 'primary' : 'light'} size="caption" bold>
            {label} {!!badge && <Badge content={badge} />}
          </Text>
          {arrow && (
            <ChevronBottomIcon
              width={10}
              color="white"
              className={classnames('pri-ml-2 -arrow', { '--collapsed': arrowCollapsed })}
            />
          )}
        </Flex>
      </Flex>
    </Bloc>
  );
};
