import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import Loader from '~/components/shared/Loader';
import { EMPLOYEE_SESSION_BASE_ROUTE_LINK } from '~/config/routes';
import { useSamlLoginUrl } from '~/hooks/useSamlLogin';

import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';

/**
 * Will redirect to <children> or "Service provider / IDP flow" page based on Primo IDP auth session
 * @param children -
 * @constructor
 */
const EmployeeAuthenticatedGuard: FunctionComponent = ({ children = null }) => {
  const samlLoginUrl = useSamlLoginUrl();
  const { isAuthenticated, isLoading, company, logout } = useContext(PrimoEmployeeContext);
  const [, setShowEmployeeEnrollmentStepper] = useLocalStorage('enrollment', false);
  const { slug } = useParams();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const hasEnrollmentParams = !!queryParams.get('enrollment');
    if (hasEnrollmentParams) {
      setShowEmployeeEnrollmentStepper(hasEnrollmentParams);
    }

    // Trying to access a different company's dashboard
    if (isAuthenticated && slug && company?.canonicalName && slug !== company.canonicalName) {
      logout(EMPLOYEE_SESSION_BASE_ROUTE_LINK(slug));
      return;
    }

    if (isAuthenticated) {
      return;
    }

    if (!isAuthenticated && !isLoading) {
      window.location.href = samlLoginUrl;
    }
  }, [isAuthenticated, isLoading, samlLoginUrl, slug, company, setShowEmployeeEnrollmentStepper, logout]);

  if (isLoading) return <Loader />;

  if (!isAuthenticated) {
    return null;
  }
  return <div>{children}</div>;
};

export default EmployeeAuthenticatedGuard;
