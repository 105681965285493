import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { isRefusal } from '@primo/operation-result';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useBoolean } from 'react-use';
import Button from '~/components/shared/buttons/Button';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Loader from '~/components/shared/Loader';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import { LOGIN_ROUTE } from '~/config/routes';
import type { BooleanResponse } from '~/graphql/schema';
import { SendResetPasswordRequestRefusalReasons } from '~/graphql/schema';
import useMutationSendResetPasswordRequest from '~/hooks/queries/employees/useMutationSendResetPasswordRequest';
import type { ResetPasswordRequestFormFields } from '~/validations/login';
import { resetPasswordRequestSchema } from '~/validations/login';

const ResetPasswordRequestPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [isSent, setIsSent] = useBoolean(false);
  const { sendResetPasswordRequest, sendResetPasswordRequestLoading } = useMutationSendResetPasswordRequest();
  const form = useForm<ResetPasswordRequestFormFields>({
    mode: 'onSubmit',
    resolver: zodResolver(resetPasswordRequestSchema(t)),
  });

  const { handleSubmit } = form;

  const onSubmit = useCallback(
    async (data: ResetPasswordRequestFormFields) => {
      const result = await sendResetPasswordRequest({
        email: data.email,
      });

      if (isRefusal(result)) {
        toast.error(<span>{t('errors.refusals.send_reset_password_request.error_occurred')}</span>);
        return setIsSent(false);
      }

      const { message, success } = result.data as BooleanResponse;

      const isNonExistingAdmin =
        message &&
        [
          SendResetPasswordRequestRefusalReasons.NoEmployeeToSendTheResetPasswordEmail,
          SendResetPasswordRequestRefusalReasons.NoAdminEmployeeToSendTheResetPasswordEmail,
        ].includes(message as SendResetPasswordRequestRefusalReasons);

      if (success || isNonExistingAdmin) return setIsSent(true);

      if (!message) {
        toast.error(<span>{t('errors.refusals.send_reset_password_request.error_occurred')}</span>);
        return setIsSent(false);
      }

      toast.error(<span>{t(`notices.send_reset_password_request.${message.toLowerCase()}`)}</span>);
      return setIsSent(false);
    },
    [sendResetPasswordRequest, setIsSent, t],
  );

  if (sendResetPasswordRequestLoading) {
    return (
      <Flex fullHeight fullWidth justify="center" align="center">
        <Loader />
      </Flex>
    );
  }

  if (isSent) {
    return (
      <Flex direction="column" align="center">
        <Text>{t('notices.send_reset_password_request.reset_password_email_sent_if_exists')}</Text>
        <Link to={LOGIN_ROUTE()} className="pri-mt-10">
          <Text>{t('links.back_to_login')}</Text>
        </Link>
      </Flex>
    );
  }

  return (
    <>
      <Title size="smallHeading">{t('titles.reset_password_request')}</Title>
      <Flex direction="column">
        <Form form={form} className="pri-mt-7" onSubmit={handleSubmit(onSubmit)}>
          <Input name="email" label={t('forms.labels.email')} form={form} required />
          <Flex direction="column" align="center">
            <Button className="pri-w-100" type="submit">
              {t('buttons.continue')}
            </Button>

            <Link to={LOGIN_ROUTE()} className="pri-mt-10">
              <Text>{t('links.back_to_login')}</Text>
            </Link>
          </Flex>
        </Form>
      </Flex>
    </>
  );
};

export default ResetPasswordRequestPage;
