import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type {
  CreateDeviceMutation,
  DeviceCondition,
  DeviceKeyboardLayout,
  DevicePlatform,
  DeviceSource,
  DeviceType,
} from '~/graphql/schema';
import { useCreateDeviceMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseCreateDeviceVariables = {
  companyId: string;
  name: string;
  platform: DevicePlatform;
  source: DeviceSource;
  type?: DeviceType | null;
  brand?: string | null;
  year?: number | null;
  notes?: string | null;
  ownerId?: string;
  serialNumber?: string | null;
  inchScreenSize?: number | null;
  keyboardLayout?: DeviceKeyboardLayout | null;
  condition?: DeviceCondition | null;
  processor?: string | null;
  ram?: number | null;
  storage?: number | null;
  batteryHealth?: string | null;
};

const useMutationCreateDevice = (
  queryOptions: UseQueryOptions<UseCreateDeviceVariables> & { additionalRefetchQueries?: string[] } = {},
) => {
  const serializeCreateDeviceData = useCallback((data: CreateDeviceMutation | undefined | null) => {
    if (data?.createDevice?.__typename === 'MutationCreateDeviceSuccess') {
      return ok(data.createDevice.data);
    }
    if (data?.createDevice?.__typename === 'MutationCreateDeviceError') return refuse(data.createDevice.reasons);
    return refuse(['Unknown error']);
  }, []);

  const { additionalRefetchQueries } = queryOptions;

  const [mutation, { data: createDeviceData, loading: createDeviceLoading, called: createDeviceCalled }] =
    useCreateDeviceMutation({
      refetchQueries: [
        'GetViewDevices',
        'ListInStockDevicesPage',
        'GetDeviceDetailsPageDocument',
        'GetDeviceDetailsPage',
        ...(additionalRefetchQueries || []),
      ],
    });

  const createDeviceResult = useMemo(
    () => serializeCreateDeviceData(createDeviceData),
    [serializeCreateDeviceData, createDeviceData],
  );

  const createDevice = useCallback(
    async (variables: UseCreateDeviceVariables) => {
      const result = await mutation({
        variables,
      });
      return serializeCreateDeviceData(result.data);
    },
    [mutation, serializeCreateDeviceData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !createDeviceCalled) createDevice({ ...queryOptions });
  }, [createDevice, queryOptions, createDeviceCalled]);

  return useMemo(
    () => ({
      createDevice,
      createDeviceResult,
      createDeviceLoading,
      createDeviceCalled,
    }),
    [createDevice, createDeviceResult, createDeviceLoading, createDeviceCalled],
  );
};

export default useMutationCreateDevice;
