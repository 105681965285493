import React, { useContext } from 'react';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { EmployeeNavPanel } from '~/features/layout/NavPanels/employee';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';

const PrimoEmployeeLayout: FunctionComponent = ({ children }) => {
  const { isLoading } = useContext(PrimoEmployeeContext);
  if (isLoading) return null;

  return (
    <Flex direction="row" justify="start" align="stretch" fullHeight>
      <EmployeeNavPanel />
      <Bloc backgroundColor="white" className="pri-py-10 pri-px-11 pri-w-100 pri-vh-100" scrollable>
        {children}
      </Bloc>
    </Flex>
  );
};
export default PrimoEmployeeLayout;
