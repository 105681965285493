import { WebAuth } from 'auth0-js';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { AUTH0_AUDIENCE_API, AUTH0_CLIENT_ID, AUTH0_DOMAIN_NAME, AUTH0_SIGNUP_CLIENT_ID } from '~/config/env';
import {
  EmployeeRoles,
  type Company,
  type CompanyBillingProfile,
  type CompanyProfile,
  type CompanySetup,
  type Employee,
} from '~/graphql/schema';

import useFullLoadedOnce from '~/hooks/useFullLoadedOnce';

import usePrimoAccessToken from '~/hooks/PrimoContexts/useAccessToken';
import useDatadogRefreshUser from '~/hooks/PrimoContexts/useDatadogRefreshUser';
import useLogout from '~/hooks/PrimoContexts/useLogout';
import returnToURL from '~/hooks/PrimoContexts/utils/returnToURL';
import { useResolver } from '~/hooks/queries/useResolver';
import useSessionSwitch from '~/hooks/PrimoContexts/useSessionSwitch';

const webAuth = new WebAuth({
  domain: AUTH0_DOMAIN_NAME,
  clientID: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE_API,
  scope: 'offline_access read:current_user',
  responseType: 'token',
});

const webAuthForSignup = new WebAuth({
  domain: AUTH0_DOMAIN_NAME,
  clientID: AUTH0_SIGNUP_CLIENT_ID,
  audience: AUTH0_AUDIENCE_API,
  scope: 'offline_access read:current_user',
  responseType: 'token',
});

export type PartialCompanyBillingProfile = Pick<
  CompanyBillingProfile,
  'name' | 'billingCenterUrl' | 'checkoutUrl' | 'checkoutCompleted' | 'id'
>;

export type PrimoAdminContextProps = {
  isAuthenticated: boolean;
  isLoading: boolean;
  isBetaActivated: boolean;
  isCatalogSetup: boolean;
  connectionError: string | undefined;
  employee: Pick<
    Employee,
    | 'id'
    | 'fullName'
    | 'companyId'
    | 'email'
    | 'picture'
    | 'initials'
    | 'isInternalEmployee'
    | 'firstName'
    | 'lastName'
    | 'phone'
    | 'isAdmin'
    | 'isOnlyEmployee'
  > | null;
  company: Pick<
    Company,
    | 'addresses'
    | 'mdmSSOLoginEnabled'
    | 'billingCenterUrl'
    | 'canonicalName'
    | 'domains'
    | 'drataEnabled'
    | 'hasMDM'
    | 'hasNonAdminEmployee'
    | 'hasProvisioning'
    | 'hasProvisioning'
    | 'hasProvisioningPlan'
    | 'hrisLastSyncAt'
    | 'hrisId'
    | 'hrisName'
    | 'hrisProvider'
    | 'hrisSyncStatus'
    | 'id'
    | 'inlineAddress'
    | 'isConnectedToHris'
    | 'isHrisSyncEnabled'
    | 'isSyncedWithHris'
    | 'malwarebytesEnabled'
    | 'mdmActions'
    | 'mdmLegacy'
    | 'mdmPlatforms'
    | 'mdmProvider'
    | 'name'
    | 'subscriptionCheckoutUrl'
    | 'appleBMConfigured'
  > | null;
  defaultProfile?: Pick<
    CompanyProfile,
    | 'id'
    | 'companyId'
    | 'isDefault'
    | 'name'
    | 'apps'
    | 'forceEncryption'
    | 'settings'
    | 'malwarebytesDeploymentEnabled'
  > | null;
  companySetup: Pick<
    CompanySetup,
    | 'id'
    | 'notificationEmail'
    | 'orderEmail'
    | 'companyId'
    | 'hasMdm'
    | 'appleBMAccess'
    | 'appleOrgId'
    | 'drataIntegration'
  > | null;
  webAuth: WebAuth;
  webAuthForSignup: WebAuth;
  toggleBeta: (activated: boolean) => void;
  setImpersonateCompanyId: (companyId: string) => void;
  setAccessToken: (token: string) => void;
  setConnectionError: (error: string) => void;
  removeConnectionError: () => void;
  logout: (to?: string) => void;
  token?: string;
  companyInitialSetup?: {
    billingCheckoutCompleted: boolean;
    eulaAccepted: boolean;
    billingCheckoutUrl?: string | null;
  };
  billingProfiles: PartialCompanyBillingProfile[];
};

export const PrimoAdminContext = createContext<PrimoAdminContextProps>({
  isAuthenticated: false,
  isLoading: true,
  isBetaActivated: false,
  isCatalogSetup: false,
  connectionError: undefined,
  employee: null,
  company: null,
  companySetup: null,
  webAuth,
  webAuthForSignup,
  toggleBeta: () => undefined,
  setImpersonateCompanyId: () => undefined,
  setAccessToken: () => undefined,
  setConnectionError: () => undefined,
  removeConnectionError: () => undefined,
  logout: () => undefined,
  companyInitialSetup: undefined,
  billingProfiles: [],
});

const PrimoAdminProvider: FunctionComponent = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isBetaActivated, setIsBetaActivated] = useLocalStorage('betaMode', false);
  const [impersonateCompanyId, setImpersonateCompanyId] = useState<string | undefined>(undefined);
  const [connectionError, setConnectionError, removeConnectionError] = useLocalStorage<string>('connectionError');

  const logoutCallBack = useCallback((returnTo?: string) => {
    webAuth.logout({ returnTo: returnToURL(returnTo) });
  }, []);

  const { logout } = useLogout(logoutCallBack);

  const { accessToken, hasAuthenticatedClient, setAccessToken } = usePrimoAccessToken(
    logout,
    setLoading,
    setConnectionError,
    removeConnectionError,
  );

  /** Primo context is use to sync and fetch the current employee from the access token */
  const {
    executeResolver: getPrimoAdminContext,
    result: getPrimoAdminContextData,
    loading: getPrimoAdminContextLoading,
  } = useResolver('useGetPrimoContextMutation');
  const {
    executeResolver: getCockpitDataQuery,
    result: getCockpitData,
    loading: getCockpitDataLoading,
  } = useResolver('useGetCockpitDataQueryLazyQuery');

  /** Get employee from the result of the graphql query */
  const employee = useMemo(() => getPrimoAdminContextData?.syncCurrentEmployee ?? null, [getPrimoAdminContextData]);
  const companyId = useMemo(
    () => (impersonateCompanyId && employee?.isInternalEmployee ? impersonateCompanyId : employee?.companyId),
    [impersonateCompanyId, employee],
  );

  const catalog = useMemo(() => getCockpitData?.getCompanyCatalog ?? null, [getCockpitData]);
  const company = useMemo(() => getCockpitData?.getCompany ?? null, [getCockpitData]);
  const companySetup = useMemo(() => getCockpitData?.getCompanySetup ?? null, [getCockpitData]);
  const companyInitialSetup = useMemo(() => getCockpitData?.getCompanyInitialSetup ?? undefined, [getCockpitData]);
  const billingProfiles = useMemo(() => getCockpitData?.getCompanyBillingProfiles ?? [], [getCockpitData]);
  const defaultProfile = useMemo(() => getCockpitData?.getCompanyProfile ?? null, [getCockpitData]);

  useEffect(() => {
    if (companyId) {
      getCockpitDataQuery({ companyId });
    }
  }, [companyId, getCockpitDataQuery]);

  const toggleBeta = useCallback(
    (activated: boolean) => {
      if (employee && employee.isInternalEmployee) setIsBetaActivated(activated);
    },
    [employee, setIsBetaActivated],
  );

  /** Set datadog user when employee changes */
  useDatadogRefreshUser(employee);

  const fullLoadedOnce = useFullLoadedOnce([loading, getPrimoAdminContextLoading, getCockpitDataLoading]);

  const { setCurrentSession, currentSession } = useSessionSwitch(company, employee);

  useEffect(() => {
    if (fullLoadedOnce && currentSession !== EmployeeRoles.CompanyAdmin) {
      setCurrentSession(EmployeeRoles.CompanyAdmin);
    }
  }, [fullLoadedOnce, currentSession, setCurrentSession]);

  const value = useMemo(
    () => ({
      connectionError,
      isAuthenticated: !!employee,
      isLoading: !fullLoadedOnce,
      isBetaActivated: isBetaActivated || false,
      isCatalogSetup: (catalog || []).length > 0,
      employee,
      company,
      companySetup,
      webAuth,
      webAuthForSignup,
      token: accessToken,
      setImpersonateCompanyId,
      setAccessToken,
      setConnectionError,
      removeConnectionError,
      logout,
      toggleBeta,
      companyInitialSetup,
      billingProfiles,
      defaultProfile,
    }),
    [
      connectionError,
      fullLoadedOnce,
      employee,
      isBetaActivated,
      company,
      companySetup,
      accessToken,
      setImpersonateCompanyId,
      setAccessToken,
      setConnectionError,
      removeConnectionError,
      logout,
      toggleBeta,
      catalog,
      companyInitialSetup,
      billingProfiles,
      defaultProfile,
    ],
  );

  /** Check if user is correctly logged in when authenticated client is set */
  useEffect(() => {
    if (hasAuthenticatedClient) {
      getPrimoAdminContext();
      setLoading(false);
    }
  }, [hasAuthenticatedClient, getPrimoAdminContext]);

  return <PrimoAdminContext.Provider value={value}>{children}</PrimoAdminContext.Provider>;
};

export default PrimoAdminProvider;
