import cn from 'classnames';
import React, { useMemo } from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';
import * as theme from '~/assets/scss/theme.module.scss';
import { CheckmarkIcon } from '~/components/icons';
import { Text } from '~/components/shared/typography';
import type { Option, Value } from '../types';

const OptionComponent = <Val extends Value>(props: OptionProps<Val>) => {
  const { data, isSelected } = props;
  const { label, jsxLabel } = data as Option<Val>;
  const { children } = props;
  const selectedIcon = useMemo(
    () => <CheckmarkIcon className={cn('pri-mr-2', { 'pri-opacity-0': !isSelected })} color={theme.colorGrey700} />,
    [isSelected],
  );
  if (!label)
    return (
      <components.Option {...props}>
        {selectedIcon}
        {children}
      </components.Option>
    );
  return (
    <components.Option {...props}>
      {selectedIcon}
      {jsxLabel || <Text>{label}</Text>}
    </components.Option>
  );
};

export default OptionComponent;
