import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Callout from '~/components/shared/Callout';
import { SSOLoginContext } from './context';

const Errors: FunctionComponent = () => {
  const { t } = useTranslation();
  const { sendOTPRequestError, getChannelsError, requestData } = useContext(SSOLoginContext);

  if (!requestData) {
    return <Callout variant="error" className="pri-pt-6 pri-px-6" title={t('errors.fail_to_parse_saml_request')} />;
  }
  return sendOTPRequestError || getChannelsError ? (
    <Callout
      variant="error"
      className="pri-pt-6 pri-px-6"
      title={sendOTPRequestError?.message || getChannelsError?.message || ''}
    />
  ) : null;
};
export default Errors;
