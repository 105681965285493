import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type {
  DeviceCondition,
  DeviceKeyboardLayout,
  DevicePlatform,
  DeviceType,
  InputMaybe,
  UpdateDeviceMutation,
} from '~/graphql/schema';
import { useUpdateDeviceMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseUpdateDeviceVariables = {
  deviceId: string;
  platform?: InputMaybe<DevicePlatform>;
  name?: string | null;
  type?: DeviceType | null;
  brand?: string | null;
  year?: number | null;
  notes?: string | null;
  serialNumber?: string | null;
  inchScreenSize?: number | null;
  keyboardLayout?: DeviceKeyboardLayout | null;
  condition?: DeviceCondition | null;
  processor?: string | null;
  ram?: number | null;
  storage?: number | null;
  batteryHealth?: string | null;
};

const useMutationUpdateDevice = (
  queryOptions: UseQueryOptions<UseUpdateDeviceVariables> & { additionalRefetchQueries?: string[] } = {},
) => {
  const serializeCreateDeviceData = useCallback((data: UpdateDeviceMutation | undefined | null) => {
    if (data?.updateDevice?.__typename === 'MutationUpdateDeviceSuccess') {
      return ok(data.updateDevice.data);
    }
    if (data?.updateDevice?.__typename === 'MutationUpdateDeviceError') return refuse(data.updateDevice.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [mutation, { data: updateDeviceData, loading: updateDeviceLoading, called: updateDeviceCalled }] =
    useUpdateDeviceMutation({
      refetchQueries: [
        'GetViewDevices',
        'ListInStockDevicesPage',
        'ListInStockDevicesPage',
        'GetDeviceDetailsPageDocument',
        'GetDeviceDetailsPage',
        ...(queryOptions.additionalRefetchQueries || []),
      ],
    });

  const updateDeviceResult = useMemo(
    () => serializeCreateDeviceData(updateDeviceData),
    [serializeCreateDeviceData, updateDeviceData],
  );

  const updateDevice = useCallback(
    async (variables: UseUpdateDeviceVariables) => {
      const result = await mutation({
        variables,
      });
      return serializeCreateDeviceData(result.data);
    },
    [mutation, serializeCreateDeviceData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !updateDeviceCalled) updateDevice({ ...queryOptions });
  }, [updateDevice, queryOptions, updateDeviceCalled]);

  return useMemo(
    () => ({
      updateDevice,
      updateDeviceResult,
      updateDeviceLoading,
      updateDeviceCalled,
    }),
    [updateDevice, updateDeviceResult, updateDeviceLoading, updateDeviceCalled],
  );
};

export default useMutationUpdateDevice;
