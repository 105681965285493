import { Tab as CarbonTab } from '@carbon/react';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '~/components/shared/Badge';

import './style.scss';

export type TabProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  children?: React.ReactNode;
  tabKey: string;
  title?: string;
  onClick?: () => void;
  selected?: boolean;
  className?: string;
  suffixIcon?: React.ReactNode;
  badge?: number | null;
  disabled?: boolean;
};

/**
 * Note, the render as array is used because the Carbon Tab component infer the content as a tooltip,
 * it must not be an object and should not have null children
 */
const Tab = React.forwardRef<HTMLElement, TabProps>((props, ref) => {
  const { t } = useTranslation();
  const { title, selected, className, tabKey, onClick, suffixIcon, badge, disabled } = props;

  return (
    <CarbonTab
      className={cn(className, 'pri-tab', { 'pri-tab-selected': selected, 'pri-tab-disabled': disabled })}
      ref={ref}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      title=""
    >
      {title ?? t(`tabs.${tabKey}`)}
      {!!badge && <Badge content={badge} />}
      {suffixIcon}
    </CarbonTab>
  );
});

export default Tab;
