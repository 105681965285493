import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import AddIcon from '~/components/icons/add';
import EditPlainIcon from '~/components/icons/editPlain';
import Pill from '~/components/shared/Pill';
import Flex from '~/components/shared/shaping/Flex';
import UpdateDeviceTagsModal from '~/features/modals/UpdateDeviceTagsModal';
import type { Device } from '~/graphql/schema';

export interface AddTagPillProps {
  device: Pick<Device, 'id' | 'tags'>;
  withLabel?: boolean;
}

export const AddTagPill: FunctionComponent<AddTagPillProps> = ({ device, withLabel }) => {
  const { t } = useTranslation();
  const [isModalVisible, toggleModalVisible] = useBoolean(false);

  return (
    <Flex gap={3}>
      <Pill
        className="tag-cell-add-tag"
        color="grey"
        onClick={e => {
          e.stopPropagation();
          toggleModalVisible();
        }}
      >
        <Flex gap={3} align="center">
          {withLabel && t('forms.labels.add_tag')}
          {device.tags.length >= 3 ? <EditPlainIcon width={10} /> : <AddIcon width={10} />}
        </Flex>
      </Pill>
      {isModalVisible && <UpdateDeviceTagsModal device={device} onClose={toggleModalVisible} />}
    </Flex>
  );
};

export default AddTagPill;
