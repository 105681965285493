import React from 'react';
import * as theme from '~/assets/scss/theme.module.scss';

const AndroidIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 56.693 56.693"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        opacity="0.8"
        d="M35.021,8.568l0.547-0.819l0.537-0.808l1.209-1.813c0.148-0.223,0.088-0.523-0.133-0.672   c-0.225-0.149-0.525-0.089-0.674,0.135l-1.295,1.944l-0.545,0.817L34.114,8.18c-1.752-0.679-3.705-1.06-5.768-1.06   c-2.061,0-4.014,0.381-5.766,1.06L22.03,7.352l-0.545-0.817L20.188,4.59c-0.148-0.223-0.449-0.283-0.673-0.135   c-0.222,0.148-0.282,0.449-0.134,0.672l1.208,1.813l0.539,0.808l0.547,0.819c-4.115,1.916-6.898,5.546-6.898,9.701h27.142   C41.919,14.114,39.136,10.484,35.021,8.568z M22.532,14.391c-0.805,0-1.455-0.651-1.455-1.454s0.65-1.453,1.455-1.453   c0.803,0,1.453,0.65,1.453,1.453S23.335,14.391,22.532,14.391z M34.163,14.391c-0.803,0-1.453-0.651-1.453-1.454   s0.65-1.453,1.453-1.453c0.805,0,1.455,0.65,1.455,1.453S34.968,14.391,34.163,14.391z"
        fill={color}
      />
      <path
        opacity="0.8"
        d="M14.986,20.208h-0.209v2.418v1.973v16.936c0,1.693,1.376,3.07,3.069,3.07h2.216c-0.074,0.256-0.116,0.527-0.116,0.807   v0.162v0.969v5.01c0,1.605,1.303,2.908,2.909,2.908s2.908-1.303,2.908-2.908v-5.01v-0.969v-0.162c0-0.279-0.043-0.551-0.115-0.807   h5.4c-0.074,0.256-0.115,0.527-0.115,0.807v0.162v0.969v5.01c0,1.605,1.303,2.908,2.908,2.908s2.908-1.303,2.908-2.908v-5.01   v-0.969v-0.162c0-0.279-0.041-0.551-0.115-0.807h2.215c1.693,0,3.07-1.377,3.07-3.07V24.599v-1.973v-2.418H41.71H14.986z"
        fill={color}
      />
      <path
        opacity="0.8"
        d="M9.929,20.208c-1.606,0-2.908,1.301-2.908,2.909v12.439c0,1.605,1.302,2.908,2.908,2.908c1.605,0,2.908-1.303,2.908-2.908   V23.116C12.837,21.509,11.535,20.208,9.929,20.208z"
        fill={color}
      />
      <path
        opacity="0.8"
        d="M46.767,20.208c-1.607,0-2.908,1.301-2.908,2.909v12.439c0,1.605,1.301,2.908,2.908,2.908c1.605,0,2.906-1.303,2.906-2.908   V23.116C49.673,21.509,48.372,20.208,46.767,20.208z"
        fill={color}
      />
    </g>
    <path
      opacity="0.8"
      d="M9.929,20.208c-1.606,0-2.908,1.301-2.908,2.909v12.439c0,1.605,1.302,2.908,2.908,2.908c1.605,0,2.908-1.303,2.908-2.908   V23.116C12.837,21.509,11.535,20.208,9.929,20.208z"
      fill={color}
    />
  </svg>
);

export default AndroidIcon;
