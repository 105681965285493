import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Callout from '~/components/shared/Callout';
import type { ModalProps } from '~/components/shared/Modal';
import Modal from '~/components/shared/Modal';
import Flex from '~/components/shared/shaping/Flex';

type CompleteCompanyBillingProfileConfirmationModalProps = Pick<ModalProps, 'onClose'> & {
  billingProfileCheckoutUrl?: string | null;
};

export const CompleteCompanyBillingProfileConfirmationModal = ({
  onClose,
  billingProfileCheckoutUrl,
}: CompleteCompanyBillingProfileConfirmationModalProps) => {
  const { t } = useTranslation();

  const handleOnClick = useCallback(() => {
    onClose?.();
    if (!billingProfileCheckoutUrl) {
      toast.error(<>{t('errors.chosen_billing_profile_invalid')}</>);
      return;
    }

    window.open(billingProfileCheckoutUrl, '_blank', 'noreferrer');
  }, [onClose, billingProfileCheckoutUrl, t]);

  return (
    <Modal
      title={t('modals.titles.complete_company_billing_profile')}
      onClose={onClose}
      onConfirm={handleOnClick}
      footer={{ cancelButton: true, confirmButton: t('actions.company_billing_profile.complete') }}
    >
      <Flex direction="column">
        <Callout variant="info" title={t('modals.texts.complete_company_billing_profile_info')} />
      </Flex>
    </Modal>
  );
};
