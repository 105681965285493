import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Recap, StepperContext, Steps } from '~/components/shared/Stepper';

import EquipmentsRecap from './_recaps/EquipmentsRecap';
import PriceRecap from './_recaps/PriceRecap';
import ChooseEquipmentsStep from './_steps/ChooseEquipmentsStep';
import ChooseShippingAndBillingStep from './_steps/ChooseShippingAndBillingStep';
import { OrderStepperContext } from './context';

const OrderStepperContent: FunctionComponent = () => {
  const { t } = useTranslation();
  const { contextData } = useContext(OrderStepperContext);
  const { currentStep } = useContext(StepperContext);

  const shouldDisplayEquipmentsRecap = currentStep > 1 && contextData.equipments && contextData.equipments.length > 0;

  return (
    <>
      <Steps title={t('steppers.orders.order')}>
        <ChooseEquipmentsStep />
        <ChooseShippingAndBillingStep />
      </Steps>
      {shouldDisplayEquipmentsRecap && (
        <Recap>
          <EquipmentsRecap />
          <PriceRecap />
        </Recap>
      )}
    </>
  );
};

export default OrderStepperContent;
