import cn from 'classnames';
import React, { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import * as theme from '~/assets/scss/theme.module.scss';
import { LogoutIcon } from '~/components/icons';

import { Form } from '~/components/shared/forms/Form';
import Select from '~/components/shared/forms/Select';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';
import { useResolver } from '~/hooks/queries/useResolver';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';

export const CompanySelector: FunctionComponent<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const { logout: adminLogout, employee, company, setImpersonateCompanyId } = useContext(PrimoAdminContext);
  const {
    logout: employeeLogout,
    employee: loggedEmployee,
    company: loggedEmployeeCompany,
  } = useContext(PrimoEmployeeContext);

  const employeeToUse = employee || loggedEmployee;
  const companyToUse = company || loggedEmployeeCompany;
  const [searchParams, setSearchParams] = useSearchParams();
  const [companyIdFromStorage, setCompanyIdInStorage] = useLocalStorage<string>('impersonatedCompanyId');
  const isInternalEmployee = Boolean(employeeToUse?.isInternalEmployee);
  const { t } = useTranslation();

  const { result } = useResolver('useGetActiveCompaniesLazyQuery', { runOnInit: isAdmin });
  const companies = useMemo(
    () =>
      isInternalEmployee
        ? result?.getCompanies || []
        : [{ id: companyToUse?.id || '', name: companyToUse?.name || '', activated: true }],
    [isInternalEmployee, companyToUse, result?.getCompanies],
  );

  const form = useForm({
    defaultValues: {
      company: '',
    },
  });

  useEffect(() => {
    if (!employeeToUse?.isInternalEmployee) {
      form.setValue('company', employeeToUse?.companyId || '');
      return;
    }
    const companyIdFromParams = searchParams.get('companyId');
    const requestedCompanyId = companyIdFromParams || companyIdFromStorage;
    const defaultSelected = companies.find(c => c?.id === requestedCompanyId);
    if (defaultSelected) {
      form.setValue('company', defaultSelected.id || '');
    }
  }, [
    searchParams,
    companyIdFromStorage,
    isInternalEmployee,
    companies,
    form,
    employeeToUse?.companyId,
    employeeToUse?.isInternalEmployee,
    employeeToUse,
  ]);

  useEffect(() => {
    if (!companyIdFromStorage) return;
    setTimeout(() => {
      if (searchParams.get('companyId') === companyIdFromStorage) return;
      setSearchParams(
        previousParams => {
          const params = new URLSearchParams(previousParams);
          if (isInternalEmployee) {
            params.set('companyId', companyIdFromStorage);
          } else {
            params.delete('companyId');
          }
          return params;
        },
        { replace: false },
      );
    }, 0); // This is a trick to make it pushed to the end of the event loop, so after the others setSearchParams
    setImpersonateCompanyId(companyIdFromStorage);
  }, [
    companyIdFromStorage,
    isInternalEmployee,
    searchParams,
    setCompanyIdInStorage,
    setImpersonateCompanyId,
    setSearchParams,
  ]);

  if (!employeeToUse?.companyId) return null;

  return (
    <Form form={form} className="pri-w-100 pri-mt-2 pri-company-selector-wrapper">
      <Select
        className={cn(
          'pri-company-selector',
          isInternalEmployee ? 'pri-company-selector-internal' : 'pri-company-selector-employee',
        )}
        dark
        name="company"
        form={form}
        options={companies}
        getOptionLabel={c => c.name}
        getOptionValue={c => c.id}
        menuPlacement="top"
        searchable={employeeToUse?.isInternalEmployee}
        onChange={c => {
          if (!c) return;
          const { id: companyId } = c;
          setCompanyIdInStorage(companyId);
        }}
      />
      <Bloc
        className="pri-logout-button cursor-pointer"
        onMouseDown={() => {
          adminLogout?.();
          employeeLogout?.();
        }}
      >
        <Flex align="center" gap={2}>
          <LogoutIcon width={16} color={theme.colorError600} />
          <Text size="caption" variant="danger" bold>
            {t('buttons.logout')}
          </Text>
        </Flex>
      </Bloc>
    </Form>
  );
};
