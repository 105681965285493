import CarbonTag from '@carbon/react/lib/components/Tag/Tag';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { Text } from '../typography/Text';

import './index.scss';

export type PillProps = {
  color?: 'yellow' | 'red' | 'green' | 'blue' | 'grey' | 'cream';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
};

const Pill: FunctionComponent<PillProps> = ({ className, children, color = 'blue', onClick, icon, iconPosition }) => {
  const specificClassName = useMemo(
    () => (typeof children === 'string' ? `pri-pill-${children.toLowerCase().replaceAll(' ', '_')}` : ''),
    [children],
  );

  return (
    <CarbonTag
      className={cn('pri-pill', `pri-pill-color-${color}`, className, specificClassName, {
        'pri-pill-with-icon': icon,
        'pri-pill-icon-right': iconPosition === 'right',
      })}
      onClick={onClick}
    >
      {icon}
      <Text size="caption">{children}</Text>
    </CarbonTag>
  );
};

export default Pill;
