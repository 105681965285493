import { useMemo } from 'react';
import { useAssignAccessoryMutation, useAssignDeviceMutation } from '~/graphql/schema';

const useAssignFromStock = () => {
  const [assignAccessory, { loading: assignAccessoryLoading }] = useAssignAccessoryMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: ['GetViewEmployees', 'GetViewDevices'],
  });
  const [assignDevice, { loading: assignDeviceLoading }] = useAssignDeviceMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: ['GetViewEmployees', 'GetViewDevices'],
  });

  return useMemo(
    () => ({
      assignAccessory,
      assignAccessoryLoading,
      assignDevice,
      assignDeviceLoading,
    }),
    [assignAccessory, assignAccessoryLoading, assignDevice, assignDeviceLoading],
  );
};

export default useAssignFromStock;
