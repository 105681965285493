import { ClassPrefix, TextArea, TextInput } from '@carbon/react';
import React, { type ReactNode, useMemo } from 'react';

import cn from 'classnames';
import type { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import Flex from '../../shaping/Flex';
import Error from '../_shared/Error';
import Helper from '../_shared/Helper';
import Label from '../_shared/Label';
import './index.scss';

export type InputProps<FV extends FieldValues> = {
  name: Path<FV>;
  label?: string;
  helper?: string | ReactNode;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  type?: 'text' | 'password' | 'email' | 'number' | 'textarea';
  className?: string;
  noMargin?: boolean;

  form: UseFormReturn<FV>;
};

const Input = <FV extends FieldValues>(props: InputProps<FV>) => {
  const { type = 'text', label, required, name, className, helper, noMargin, form, ...inputProps } = props;
  const id = useMemo(() => `input-${Math.random().toFixed(10).slice(2, 10)}`, []);
  const {
    register,
    formState: { errors },
  } = form;

  const error = errors[name];

  const computedProps = useMemo(
    () => ({
      className: cn(className, 'pri-input-text', { 'pri-input-required': required }),
      type,
      id,
      labelText: '',
      invalid: !!error,
      ...register(name, { required }),
      ...inputProps,
    }),
    [className, required, type, id, error, register, name, inputProps],
  );

  return (
    <ClassPrefix prefix="carbon">
      <Flex className={cn('pri-input-text-wrapper', 'pri-flex-1', { 'pri-pb-5': !noMargin })} direction="column">
        {label && <Label className="pri-mb-4" required={required} label={label} />}
        {type === 'textarea' ? <TextArea {...computedProps} /> : <TextInput {...computedProps} />}
        {error?.message && <Error error={error.message.toString()} />}
        {!error && helper && <Helper helper={helper} />}
      </Flex>
    </ClassPrefix>
  );
};

export default Input;
