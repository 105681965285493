import { zodResolver } from '@hookform/resolvers/zod';
import type { TFunction } from 'i18next';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import zod from 'zod';

import axios from 'axios';
import Button from '~/components/shared/buttons/Button';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';

import { SSO_LOGIN_LINK } from '~/config/routes';
import { SSOLoginContext } from './context';
import ResendOTPButton from './ResendOTPButton';
import { useSAMLResponseFormSubmit } from './helper/useSAMLResponseFormSubmit';
import { useSSOLoginRequest } from './helper/useSSOLoginRequest';
import { LoginType } from './helper/useSSOLoginRequest/types';

const otpFormSchema = (t: TFunction) =>
  zod.object({
    otp: zod.string().length(6, t('forms.validations.invalid_otp', { length: 6 })),
  });

type OTPFormFields = zod.infer<ReturnType<typeof otpFormSchema>>;

const OTPForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const { email, requestData, relayState } = useContext(SSOLoginContext);
  const navigate = useNavigate();
  const form = useForm<OTPFormFields>({
    resolver: zodResolver(otpFormSchema(t)),
  });
  const { submitSAMLResponseForm } = useSAMLResponseFormSubmit();
  const { submitSSOLoginRequest } = useSSOLoginRequest();
  const { setError } = form;

  const [{ loading }, onSubmit] = useAsyncFn(async (fields: OTPFormFields) => {
    if (!requestData || !slug || !email) {
      setError('otp', { message: t('errors.fail_to_parse_saml_request') });
      return;
    }
    try {
      const { samlResponse } = await submitSSOLoginRequest({
        slug,
        type: LoginType.OTP,
        payload: { otp: fields.otp, email },
        requestData: {
          issuer: requestData.issuer,
          requestId: requestData.id,
        },
      });

      submitSAMLResponseForm({
        callbackURL: requestData.callbackURL,
        rawSamlResponse: samlResponse,
        relayState,
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 429) {
          setError('otp', {
            message: t(`errors.validate_otp.too_many_request`),
          });
          return;
        }
        const error = err?.response?.data;
        if (typeof error === 'string') {
          setError('otp', {
            message: error
              .split(';')
              .map(r =>
                t(`errors.validate_otp.${r.toLowerCase()}`, { defaultValue: t('errors.fail_to_send_otp_request') }),
              )
              .join(', '),
          });
          return;
        }
      }
      setError('otp', { message: t('errors.fail_to_validate_otp_request') });
    }
  });

  useEffect(() => {
    if (slug && !email) {
      navigate(SSO_LOGIN_LINK(slug));
    }
  }, [email, slug, navigate]);

  return (
    <Flex direction="column" className="pri-mt-3" gap={8}>
      <Flex direction="column" gap={2}>
        <Title size="largeLabel">{t('forms.titles.otp')}</Title>
        <Text variant="muted" noWrap={false}>
          {t('forms.subtitles.otp', { email })}
        </Text>
      </Flex>
      <Flex justify="between" align="center" className="pri-mt-2" gap={3}>
        <Text maxLength={30}>{email}</Text>
        <ResendOTPButton />
      </Flex>
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <Input name="otp" label={t('forms.labels.otp')} form={form} required />
        <Flex className="pri-otp-form-login-button" gap={3} direction="column" fullWidth>
          <Button disabled={loading} type="submit" fullWidth>
            {t('buttons.login')}
          </Button>
          {!!slug && (
            <Button className="flex-1" fullWidth variant="secondary-light" link={SSO_LOGIN_LINK(slug)}>
              {t('buttons.back')}
            </Button>
          )}
        </Flex>
      </Form>
    </Flex>
  );
};
export default OTPForm;
