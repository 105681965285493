import { Tabs as CarbonTabs, ClassPrefix, TabList, TabPanel, TabPanels } from '@carbon/react';
import cn from 'classnames';
import type { ReactElement } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Truthy } from '~/utils/truthy';
import Bloc from '../shaping/Bloc';
import type { TabProps } from './Tab';

import './style.scss';

export * from './Tab';

type Child = ReactElement<TabProps> | null;

export type TabsProps = {
  children: Child | Child[];
  onChange?: (tab: string) => void;
  disableURLSearchUpdate?: true;
  fullWidth?: true;
};

const Tabs: FunctionComponent<TabsProps> = ({ className, children, disableURLSearchUpdate, fullWidth, onChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');
  const clearedChildren = (Array.isArray(children) ? (children as Child[]) : [children]).filter(Truthy);
  const childrenKeys = clearedChildren.map(child => child.props.tabKey);
  const childDefaultIndex = useMemo(() => {
    const index = childrenKeys.findIndex(k => k === currentTab);
    return index === -1 ? 0 : index;
  }, [childrenKeys, currentTab]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(childDefaultIndex);

  const onSelectTab = useCallback(
    ({ selectedIndex }: { selectedIndex: number }) => {
      const tab = clearedChildren[selectedIndex].props;
      if (tab.onClick) return;
      setSelectedTabIndex(selectedIndex);
      onChange?.(tab.tabKey);
      if (!disableURLSearchUpdate) {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('tab', tab.tabKey);
        setSearchParams(newParams);
      }
    },
    [clearedChildren, disableURLSearchUpdate, onChange, searchParams, setSearchParams],
  );

  useEffect(() => {
    if (
      typeof selectedTabIndex === 'number' &&
      currentTab &&
      currentTab !== clearedChildren[selectedTabIndex]?.props.tabKey
    ) {
      setSelectedTabIndex(clearedChildren.findIndex(child => child.props.tabKey === currentTab));
    }
  }, [currentTab, childDefaultIndex, clearedChildren, selectedTabIndex]);

  return (
    <ClassPrefix prefix="carbon">
      <Bloc className={cn('pri-tabs', className)}>
        <CarbonTabs selectedIndex={selectedTabIndex} onChange={onSelectTab}>
          <TabList aria-label="pri-tab-list" className={cn('pri-tab-list', { '--full-width': fullWidth })}>
            {clearedChildren.map((child, index) => (
              <child.type
                {...child.props}
                selected={index === selectedTabIndex}
                key={`tab-${index.toString()}`}
                className={cn('pri-tab', child.props.className)}
              />
            ))}
          </TabList>
          <TabPanels>
            {clearedChildren.map((child, index) => (
              <TabPanel key={`tab-content-${index.toString()}`} className="pri-tab-content">
                <ClassPrefix prefix="cds">{child.props.children}</ClassPrefix>
              </TabPanel>
            ))}
          </TabPanels>
        </CarbonTabs>
      </Bloc>
    </ClassPrefix>
  );
};

export default Tabs;
