import React from 'react';
import { useTranslation } from 'react-i18next';
import QuestionIcon from '~/components/icons/question';
import BulletPointList from '~/components/shared/BulletPointList';

import CollapsibleSection from '~/components/shared/CollapsibleSection';

const STEPS_AGENT_INSTALL = [
  'instructions.download_missing_agent.how_to_1',
  'instructions.download_missing_agent.how_to_2',
  'instructions.download_missing_agent.how_to_3',
];

export const HowToFixPartiallyEnrolled: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <CollapsibleSection
      title={t('titles.how_to_fix_partially_enrolled')}
      icon={<QuestionIcon />}
      collapsedByDefault={false}
    >
      <BulletPointList bulletPoints={STEPS_AGENT_INSTALL.map(key => ({ title: t(key), textSize: 'body' }))} numbered />
    </CollapsibleSection>
  );
};
