import cn from 'classnames';
import type { ReactElement } from 'react';
import React from 'react';

import Flex from '~/components/shared/shaping/Flex';
import type { TextProps } from '~/components/shared/typography';
import { Text } from '~/components/shared/typography';
import BulletPointDot from '../BulletPointDot';

import './style.scss';

type BulletPoint = {
  title: string;
  textSize?: TextProps['size'];
  description?: string;
  component?: ReactElement;
  disabled?: boolean;
};

export type BulletPointListProps = {
  bulletPoints: BulletPoint[];
  separator?: boolean;
  numbered?: boolean;
  children?: never;
};
const BulletPointList: FunctionComponent<BulletPointListProps> = ({
  className,
  bulletPoints,
  numbered = false,
  separator = false,
}) => (
  <Flex direction="column" className={cn(className, 'pri-bullet-point-list')} gap={7}>
    {bulletPoints.map((bulletPoint, index) => (
      <Flex direction="column" key={bulletPoint.title}>
        <Flex
          direction="row"
          gap={5}
          className={cn('pri-bullet-point', bulletPoint.disabled ? 'disabled' : '')}
          align="start"
        >
          <BulletPointDot number={numbered ? index + 1 : undefined} />
          <Flex className="pri-bullet-point-item" direction="column" gap={2} fullWidth>
            <Text size={bulletPoint.textSize || 'body'} bold>
              {bulletPoint.title}
            </Text>
            {bulletPoint.description && <Text variant="light-muted">{bulletPoint.description}</Text>}
            {bulletPoint.component}
          </Flex>
        </Flex>
        {separator && index !== bulletPoints.length - 1 && <hr className="pri-bullet-point-separator" />}
      </Flex>
    ))}
  </Flex>
);

export default BulletPointList;
