import { Toggle as CarbonToggle, ClassPrefix } from '@carbon/react';
import cn from 'classnames';
import React, { useMemo } from 'react';
import type { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import './index.scss';

export type ToggleProps<FV extends FieldValues> = {
  label?: string;
  className?: string;
  disabled?: boolean;
  name: Path<FV>;
  form: UseFormReturn<FV>;
};

const Toggle = <FV extends FieldValues>({ label = '', className, disabled, ...props }: ToggleProps<FV>) => {
  const id = useMemo(() => `toggle-${Math.random().toFixed(10).slice(2, 10)}`, []);
  const { control } = props.form;

  const sharedProps = {
    className: cn('pri-toggle', className),
    id,
    labelA: '',
    labelB: '',
    size: 'sm' as const,
    labelText: label,
  };

  return (
    <ClassPrefix prefix="carbon">
      <Controller
        control={control}
        name={props.name}
        render={({ field: { value, onChange, ref, ...rest } }) => (
          <CarbonToggle {...rest} disabled={disabled} toggled={value} onToggle={onChange} {...sharedProps} />
        )}
      />
    </ClassPrefix>
  );
};

export default Toggle;
