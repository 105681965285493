import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import logoFleet from '~/assets/logo_fleet.svg';
import brandmark from '~/assets/logo_white.svg';
import smallSizeBg from '~/assets/small-size-bg.svg';
import Button from '~/components/shared/buttons/Button';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import useOnResize from '~/hooks/useOnResize';

const SmallScreenWarning: FunctionComponent = () => {
  const { t } = useTranslation();
  const [smallScreenWarningAccepted, setSmallScreenWarningAccepted] = useLocalStorage(
    'small-screen-warning-accepted',
    false,
  );

  const viewport = useOnResize();

  if (viewport.width > 700 || smallScreenWarningAccepted) return null;

  return (
    <Bloc
      style={{
        width: '100vw',
        height: '100vh',
        zIndex: 9999,
        position: 'absolute',
        backgroundImage: `url(${smallSizeBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: 32,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Flex direction="column" gap={8}>
        <Flex gap={2}>
          <img src={logoFleet} height={20} alt="Logo" />
          <img src={brandmark} height={20} alt="Brandmark" />
        </Flex>

        <Flex direction="column" gap={3}>
          <Title variant="light" size="subHeading">
            {t('titles.small_screen')}
          </Title>
          <Text variant="light" size="bodyLarge">
            {t('texts.small_screen')}
          </Text>
        </Flex>

        <Button onClick={() => setSmallScreenWarningAccepted(true)}>Access anyway</Button>
      </Flex>
    </Bloc>
  );
};
export default SmallScreenWarning;
