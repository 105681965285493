import { ok, refuse } from '@primo/operation-result';
import { useCallback, useEffect, useMemo } from 'react';
import type { CreateFirstAdminInCompanyMutation, EmployeeSignupInput } from '~/graphql/schema';
import { useCreateFirstAdminInCompanyMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseCreateFirstAdminInCompanyVariables = {
  companyAuth0Id: string;
  employeeAuth0Id: string;
  employeeData: EmployeeSignupInput;
};

const useMutationCreateFirstAdminInCompany = (
  queryOptions: UseQueryOptions<UseCreateFirstAdminInCompanyVariables> = {},
) => {
  const serializeCreateFirstAdminInCompanyData = useCallback(
    (data: CreateFirstAdminInCompanyMutation | undefined | null) => {
      if (data?.createFirstAdminInCompany?.__typename === 'MutationCreateFirstAdminInCompanySuccess') {
        return ok(data.createFirstAdminInCompany.data);
      }
      if (data?.createFirstAdminInCompany?.__typename === 'MutationCreateFirstAdminInCompanyError')
        return refuse(data.createFirstAdminInCompany.reasons);
      return refuse(['Unknown error']);
    },
    [],
  );

  const [
    mutation,
    {
      data: createFirstAdminInCompanyData,
      loading: createFirstAdminInCompanyLoading,
      called: createFirstAdminInCompanyCalled,
    },
  ] = useCreateFirstAdminInCompanyMutation();

  const createFirstAdminInCompanyResult = useMemo(
    () => serializeCreateFirstAdminInCompanyData(createFirstAdminInCompanyData),
    [serializeCreateFirstAdminInCompanyData, createFirstAdminInCompanyData],
  );

  const createFirstAdminInCompany = useCallback(
    async (variables: UseCreateFirstAdminInCompanyVariables) => {
      const result = await mutation({
        variables,
      });
      return serializeCreateFirstAdminInCompanyData(result.data);
    },
    [mutation, serializeCreateFirstAdminInCompanyData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !createFirstAdminInCompanyCalled) createFirstAdminInCompany({ ...queryOptions });
  }, [createFirstAdminInCompany, queryOptions, createFirstAdminInCompanyCalled]);

  return useMemo(
    () => ({
      createFirstAdminInCompany,
      createFirstAdminInCompanyResult,
      createFirstAdminInCompanyLoading,
      createFirstAdminInCompanyCalled,
    }),
    [
      createFirstAdminInCompany,
      createFirstAdminInCompanyResult,
      createFirstAdminInCompanyLoading,
      createFirstAdminInCompanyCalled,
    ],
  );
};

export default useMutationCreateFirstAdminInCompany;
