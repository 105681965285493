import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { HowToFindSerialNumberHelper } from '~/components/featured/cards/HowToFindSerialNumber';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Loader from '~/components/shared/Loader';
import Message from '~/components/shared/Message';
import Flex from '~/components/shared/shaping/Flex';
import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { Text, Title } from '~/components/shared/typography';
import { EmployeeEnrollmentStepperContext } from '../../context';
import { updateDeviceForEnrollmentSchema } from '../../context/schema';
import type { Fields } from './schema';
import { schema } from './schema';

const ConfirmSerialStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    contextData: { selectedDevice },
    loading,
    createDeviceLoading,
    setContextData,
    contextData,
    updateDeviceForEnrollment,
  } = useContext(EmployeeEnrollmentStepperContext);
  const { setFooterProps } = useContext(StepperContext);
  const form = useForm<Fields>({
    defaultValues: { serialNumber: undefined },
    resolver: zodResolver(schema(t)),
  });

  const { trigger, getValues } = form;

  const onNext = useCallback(async () => {
    const isValid = await trigger();
    if (!isValid) return false;

    if (!selectedDevice) return false;

    const { serialNumber } = getValues();

    if (serialNumber && serialNumber !== selectedDevice.serialNumber) {
      const variable = updateDeviceForEnrollmentSchema.safeParse({ serialNumber });

      if (!variable.success) {
        toast.error(`${t('errors.missing_information_to_update_device')}`);
        return false;
      }

      const updateSuccess = await updateDeviceForEnrollment({ serialNumber });

      if (!updateSuccess) {
        toast.error(`${t('errors.error_when_updating_device')}`);
        return false;
      }

      setContextData({
        ...contextData,
        selectedDevice: { ...selectedDevice, serialNumber },
      });
    }

    return true;
  }, [updateDeviceForEnrollment, trigger, getValues, t, setContextData, selectedDevice, contextData]);

  useEffect(() => {
    setFooterProps({
      onNext,
      nextLabel: t('buttons.next'),
    });
  }, [setFooterProps, onNext, t]);

  if (!selectedDevice) return null;

  return (
    <Step size="lg">
      {loading || createDeviceLoading ? (
        <Loader spacing="md" />
      ) : (
        <Flex direction="column" gap={8}>
          <Title
            size="subHeading"
            bold
          >{`${t('titles.enrollment_stepper.confirm_serial.title')} ${selectedDevice.name}`}</Title>
          {selectedDevice.serialNumber && (
            <Message>
              <Flex justify="between">
                <Text variant="muted-light">{t('texts.possible_serial_number')}</Text>
                <Text bold>{selectedDevice.serialNumber}</Text>
              </Flex>
            </Message>
          )}
          <Flex direction="column" gap={3}>
            <Form form={form}>
              <Flex direction="column" gap={7}>
                <Flex direction="column" fullWidth>
                  <Input
                    required
                    placeholder="015D7489-92HF-91GD-82613BB"
                    name="serialNumber"
                    label={t('forms.labels.serial_number')}
                    form={form}
                  />
                </Flex>
              </Flex>
            </Form>
            <HowToFindSerialNumberHelper />
          </Flex>
        </Flex>
      )}
    </Step>
  );
};

export default ConfirmSerialStep;
