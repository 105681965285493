import React, { useCallback, useContext, useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import AddressCardTile from '~/components/featured/cards/AddressCardTile';
import EditIcon from '~/components/icons/edit';
import Radio from '~/components/shared/forms/Radio';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';
import CreateOrUpdateAddressModal from '~/features/modals/CreateOrUpdateAddressModal';
import type { ChooseShippingAndBillingStepFields } from '~/features/steppers/OrderStepper/_steps/ChooseShippingAndBillingStep';
import { OrderStepperContext } from '~/features/steppers/OrderStepper/context';
import type { Address, AddressItem } from '~/graphql/schema';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { Truthy } from '~/utils/truthy';

type ChooseAddressProps = {
  companyAddresses: AddressItem[];
  employeeAddress: Address | null;
  newAddress: AddressItem | null;
  setCompanyAddresses: (addresses: AddressItem[] | null) => void;
  setEmployeeAddress: (address: Address | null) => void;
  setNewAddress: (address: AddressItem | null) => void;
  form: UseFormReturn<ChooseShippingAndBillingStepFields>;
};
const ChooseAddress: FunctionComponent<ChooseAddressProps> = ({
  companyAddresses,
  employeeAddress,
  newAddress,
  setCompanyAddresses,
  setEmployeeAddress,
  setNewAddress,
  form,
}) => {
  const { t } = useTranslation();
  const { employee } = useContext(OrderStepperContext);
  const { company } = useContext(PrimoAdminContext);
  const [showUpdateAddressModal, toggleUpdateAddressModal] = useBoolean(false);
  const { watch, setValue } = form;
  const choice = watch('addressChoice');

  const handleCloseUpdateAddressModal = useCallback(
    (address: Address | AddressItem | null) => {
      if (address) {
        if (choice === 'new') setNewAddress(address as AddressItem);
        if (choice === 'home') setEmployeeAddress(address as Address);
        if (choice?.includes('office')) {
          const addressItem = address as AddressItem;
          setCompanyAddresses(
            companyAddresses.map(companyAddress =>
              companyAddress.id === addressItem.id ? addressItem : companyAddress,
            ),
          );
        }
      }

      if (!address) setValue('addressChoice', null);

      toggleUpdateAddressModal();
    },
    [
      choice,
      companyAddresses,
      setCompanyAddresses,
      setEmployeeAddress,
      setNewAddress,
      setValue,
      toggleUpdateAddressModal,
    ],
  );

  const addressToDisplayInUpdateModal = useMemo(() => {
    switch (choice) {
      case 'home':
        return employeeAddress;
      case 'new':
        return newAddress;
      default: {
        const companyAddress = companyAddresses.find(({ id }) => `office-${id}` === choice);
        return companyAddress;
      }
    }
  }, [choice, companyAddresses, employeeAddress, newAddress]);

  return (
    <Flex direction="column" gap={7}>
      <Text className="pri-mb-4" size="bodyLarge" bold>
        {t('steppers.orders.choose_the_address')}
      </Text>
      <Radio
        dotAlignment="center"
        name="addressChoice"
        form={form}
        options={[
          ...companyAddresses.map(companyAddress => ({
            label: (
              <AddressCardTile
                address={companyAddress}
                actionIcon={<EditIcon width={8} />}
                onActionIconClick={() => {
                  setValue('addressChoice', `office-${companyAddress.id}` as 'office');
                  toggleUpdateAddressModal();
                }}
              />
            ),
            value: `office-${companyAddress.id}`,
            onClick: () => (companyAddress?.phone ? null : toggleUpdateAddressModal()),
          })),
          {
            label: (
              <AddressCardTile
                address={employeeAddress}
                title={t('enums.order_shipping_address_choice.home', {
                  firstName: employee?.firstName || t('texts.order_shipping_address_choice_employee'),
                })}
                actionIcon={employee?.address ? <EditIcon width={8} /> : undefined}
                onActionIconClick={() => {
                  if (!employee?.address) return;
                  setValue('addressChoice', 'home');
                  toggleUpdateAddressModal();
                }}
              />
            ),
            value: 'home',
            onClick: () => (employeeAddress?.phone ? null : toggleUpdateAddressModal()),
          },
          {
            label: <AddressCardTile title={t('enums.order_shipping_address_choice.new')} address={newAddress} />,
            value: 'new',
            onClick: () => toggleUpdateAddressModal(),
          },
        ].filter(Truthy)}
      />
      {showUpdateAddressModal && (
        <CreateOrUpdateAddressModal
          companyId={company?.id || ''}
          address={addressToDisplayInUpdateModal}
          shouldNotSave
          onClose={handleCloseUpdateAddressModal}
        />
      )}
    </Flex>
  );
};

export default ChooseAddress;
