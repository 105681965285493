import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CompanyLoginConnectionStrategy } from '~/graphql/schema';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

import EditIcon from '~/components/icons/edit';
import Button from '~/components/shared/buttons/Button';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import { SIGNUP_ROUTE } from '~/config/routes';
import { LoginContext } from '../context';
import GoogleLoginPart from './GoogleLoginPart';
import PasswordLoginPart from './PasswordLoginPart';
import PasswordSignUpPart from './PasswordSignUpPart';

type LoginPartProps = {
  organization: string;
};

const LoginPart: FunctionComponent<LoginPartProps> = ({ organization }) => {
  const { t } = useTranslation();
  const { connectionError, removeConnectionError } = useContext(PrimoAdminContext);
  const { connections, invitation, removeOrganization } = useContext(LoginContext);
  const auth0Connection = useMemo(
    () => connections.find(connection => connection.strategy === CompanyLoginConnectionStrategy.Auth0),
    [connections],
  );

  useEffect(() => {
    if (connectionError) {
      const toastError = toast.error(<span>{t(connectionError, connectionError)}</span>, {
        toastId: 'connectionError',
      });
      toast.onChange(e => {
        if (e.id === toastError && e.status === 'removed') removeConnectionError();
      });
    }
  }, [connectionError, removeConnectionError, t]);

  return (
    <Bloc className="login-form">
      <Title size="smallHeading">
        {invitation ? t('titles.sign_up_primo_account') : t('titles.sign_in_primo_account')}
      </Title>
      <Flex className="pri-mt-5" align="center" gap={4}>
        <Text>{t('forms.labels.company')}</Text>
        <Text>{organization}</Text>
        <Button onClick={() => removeOrganization()} variant="secondary-dark" icon={<EditIcon />} />
      </Flex>

      <Flex direction="column" gap={4} className="pri-mt-6">
        {auth0Connection &&
          (invitation ? (
            <PasswordSignUpPart connection={auth0Connection} />
          ) : (
            <PasswordLoginPart connection={auth0Connection} />
          ))}
        <Text align="center">{t('texts.or')}</Text>
        {connections.map(connection => {
          switch (connection.strategy) {
            case CompanyLoginConnectionStrategy.Google:
              return <GoogleLoginPart key="google" connection={connection} />;
            default:
              return null;
          }
        })}
      </Flex>

      {!invitation && (
        <Flex className="pri-mt-5" direction="row" justify="center" align="baseline" gap={2}>
          <Text>{t('texts.no_account_yet')}</Text>
          <Link onClick={() => removeOrganization()} to={SIGNUP_ROUTE()}>
            <Text>{t('links.sign_up')}</Text>
          </Link>
        </Flex>
      )}
    </Bloc>
  );
};

export default LoginPart;
