import { zodResolver } from '@hookform/resolvers/zod';
import { isRefusal } from '@primo/operation-result';
import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Modal from '~/components/shared/Modal';
import Filler from '~/components/shared/shaping/Filler';
import Flex from '~/components/shared/shaping/Flex';
import type { ShippingAddressFormFields } from '~/features/modals/CreateOrUpdateAddressModal/schema';
import { shippingAddressFormSchema } from '~/features/modals/CreateOrUpdateAddressModal/schema';
import type { Address, AddressItem } from '~/graphql/schema';
import useMutationCreateOrUpdateCompanyAddress from '~/hooks/queries/companies/useMutationCreateOrUpdateCompanyAddress';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

type UpdateAddressModalProps = {
  onClose: (address: Address | AddressItem | null) => void;
  companyId: string;
  address?: Address | AddressItem | null;
  shouldNotSave?: boolean;
};

const CreateOrUpdateAddressModal: FunctionComponent<UpdateAddressModalProps> = ({
  onClose,
  companyId,
  address,
  shouldNotSave,
}) => {
  const { t } = useTranslation();
  const { company } = useContext(PrimoAdminContext);
  const isCreation = !address;

  const { createOrUpdateCompanyAddress, createOrUpdateCompanyAddressLoading } =
    useMutationCreateOrUpdateCompanyAddress();

  const form = useForm<ShippingAddressFormFields>({
    defaultValues: address
      ? { ...address, name: address.name || company?.name || '', phone: address.phone || '' }
      : undefined,
    mode: 'onSubmit',
    resolver: zodResolver(shippingAddressFormSchema(t)),
  });

  const handleSubmit = useCallback(async () => {
    const { name, contactName, city, complement, country, postalCode, street, phone } = form.getValues();

    const computedAddress = {
      id: address && 'id' in address ? address.id : undefined,
      name: name.trim(),
      contactName: contactName?.trim(),
      city: city.trim(),
      complement: complement?.trim(),
      country: country.trim(),
      postalCode: postalCode.trim(),
      street: street.trim(),
      phone: phone?.trim(),
    };

    if (shouldNotSave) return onClose(computedAddress);

    const result = await createOrUpdateCompanyAddress({
      address: computedAddress,
      companyId,
    });

    if (isRefusal(result)) {
      toast.error(<span>{t(`errors.${isCreation ? 'create' : 'update'}_company_address_fail`)}</span>);
      return null;
    }
    toast.success(<span>{t(`success.${isCreation ? 'create' : 'update'}_company_address_success`)}</span>);

    return onClose(computedAddress);
  }, [form, address, shouldNotSave, onClose, createOrUpdateCompanyAddress, companyId, t, isCreation]);

  return (
    <Modal
      title={t(`modals.titles.${isCreation ? 'create' : 'update'}_address`)}
      onConfirm={form.handleSubmit(handleSubmit)}
      onClose={() => onClose(null)}
      footer={{
        confirmButtonLoading: createOrUpdateCompanyAddressLoading,
        confirmButton: t(`buttons.${isCreation ? 'create' : 'update'}`),
        cancelButton: true,
      }}
    >
      <Form form={form} noValidate>
        <Flex direction="column">
          <Flex direction="row" gap={5}>
            <Input label={t('forms.labels.address_label')} name="name" form={form} required />
            <Filler />
          </Flex>
          <Flex direction="row" gap={5}>
            <Input label={t('forms.labels.contact_name')} name="contactName" form={form} />
            <Input label={t('forms.labels.contact_phone')} name="phone" form={form} required />
          </Flex>
          <Input label={t('forms.labels.street')} name="street" form={form} required />
          <Input label={t('forms.labels.complement')} name="complement" form={form} />
          <Flex direction="row" gap={3}>
            <Input label={t('forms.labels.postalCode')} name="postalCode" form={form} required />
            <Input label={t('forms.labels.city')} name="city" form={form} required />
            <Input label={t('forms.labels.country')} name="country" form={form} required />
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};

export default CreateOrUpdateAddressModal;
