import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import google from '~/assets/google_g.svg';
import Button from '~/components/shared/buttons/Button';
import Flex from '~/components/shared/shaping/Flex';
import useSignupWithGoogleCallback from '~/pages/Signup/part/SocialSignup/SignupWithGooglePart/useSignupWithGoogleCallback';

const SignupWithGooglePart: FunctionComponent = () => {
  const { t } = useTranslation();
  const signupWithGoogle = useSignupWithGoogleCallback();

  const onSubmit = useCallback(async () => {
    await signupWithGoogle();
  }, [signupWithGoogle]);

  return (
    <Flex>
      <Button
        onClick={onSubmit}
        className="pri-w-100"
        variant="secondary-dark"
        fullWidth
        icon={<img className="pri-mr-4" src={google} alt="Google" height="16" />}
      >
        {t('buttons.socials.google_sign_up')}
      </Button>
    </Flex>
  );
};

export default SignupWithGooglePart;
