import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import EnrollmentDeviceCard from '~/components/featured/cards/EnrollmentDeviceCard';
import { AddIcon, LaptopIcon } from '~/components/icons';
import { useFormat } from '~/components/shared/formatDate/Date';
import Loader from '~/components/shared/Loader';
import Flex from '~/components/shared/shaping/Flex';
import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { Text, Title } from '~/components/shared/typography';
import type { EmployeeDevice } from '~/graphql/schema';
import { EmployeeEnrollmentStepperContext } from '../context';

const SelectDeviceStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    loading,
    toEnrollDevices,
    enrolledDevices,
    setContextData,
    contextData: { selectedDevice: preSelectedDevice },
  } = useContext(EmployeeEnrollmentStepperContext);
  const { setFooterProps, currentStep, setCurrentStep } = useContext(StepperContext);
  const dateFormat = useFormat('human_date');
  const [selectedDevice, setSelectedDevice] = useState<Partial<EmployeeDevice> | null>(null);

  const onNext = useCallback(() => {
    setContextData({ selectedDevice });

    return true;
  }, [setContextData, selectedDevice]);

  const handleSelectDevice = useCallback(
    (device: EmployeeDevice) => {
      setSelectedDevice(device);
    },
    [setSelectedDevice],
  );

  const handleOnClickCreateDevice = useCallback(() => {
    setSelectedDevice(null);
    setContextData({ selectedDevice: null });
    setCurrentStep(currentStep + 1);
  }, [setSelectedDevice, setCurrentStep, currentStep, setContextData]);

  useEffectOnce(() => {
    if (preSelectedDevice) {
      setSelectedDevice(preSelectedDevice);
      return;
    }
    if (toEnrollDevices.length > 0 && !selectedDevice) {
      setSelectedDevice(toEnrollDevices[0]);
    }
  });

  useEffect(() => {
    setFooterProps({
      onNext,
      nextLabel: t('buttons.next'),
    });
  }, [setFooterProps, onNext, t]);

  if (!enrolledDevices && !toEnrollDevices) return null;

  return (
    <Step size="lg">
      {loading ? (
        <Loader spacing="md" />
      ) : (
        <Flex direction="column" gap={8}>
          <Flex direction="column" gap={3}>
            <Title size="subHeading" bold>
              {t('titles.enrollment_stepper.select_device.title')}
            </Title>
            <Text variant="muted">{t('titles.enrollment_stepper.select_device.subtitle')}</Text>
          </Flex>
          <Flex direction="column" className="pri-mt-5" gap={3}>
            <Text bold>{t('titles.enrollment_stepper.select_device.to_enroll')}</Text>
            <Flex direction="row" gap={7} wrap="wrap">
              {toEnrollDevices.map(device => (
                <EnrollmentDeviceCard
                  key={device.id}
                  icon={<LaptopIcon />}
                  title={device.name ?? device.modelName ?? ''}
                  subTitle={t('texts.enrollment_stepper.select_device.assigned_on', {
                    date: device.ownerAssignedAt ? moment().utc(device.ownerAssignedAt).format(dateFormat) : '-',
                  })}
                  selected={selectedDevice?.id === device.id}
                  onClick={() => handleSelectDevice(device)}
                />
              ))}
              <EnrollmentDeviceCard
                key="add_device"
                icon={<AddIcon width={12} />}
                title={t('texts.enrollment_stepper.select_device.enroll_another_computer')}
                subTitle={t('texts.enrollment_stepper.select_device.fill_in_device_informations')}
                onClick={() => handleOnClickCreateDevice()}
              />
            </Flex>
          </Flex>

          {enrolledDevices.length >= 1 && (
            <Flex direction="column" gap={3}>
              <Text bold>{t('titles.enrollment_stepper.select_device.enrolled')}</Text>
              <Flex direction="row" gap={7} wrap="wrap">
                {enrolledDevices.map(device => (
                  <EnrollmentDeviceCard
                    key={device.id}
                    icon={<LaptopIcon />}
                    title={device.name ?? device.modelName ?? ''}
                    subTitle={t('texts.enrollment_stepper.select_device.enrolled_since', {
                      date: device.enrolledAt ? moment().utc(device.enrolledAt).format(dateFormat).toString() : '-',
                    })}
                    enrolled
                  />
                ))}
              </Flex>
            </Flex>
          )}
        </Flex>
      )}
    </Step>
  );
};

export default SelectDeviceStep;
