import type { AxiosHeaderValue } from 'axios';

export enum LoginType {
  OTP = 'otp',
  PRIMO = 'primo',
}

export type BaseSSOLoginRequestParams = {
  slug: string;
  type: LoginType;
  requestData: {
    issuer: string;
    requestId: string;
  };
  headers?: {
    Authorization: AxiosHeaderValue;
  };
};

export type PayloadOTPLogin = { otp: string; email: string };

export type SSOLoginRequestParams =
  | (BaseSSOLoginRequestParams & {
      type: LoginType.PRIMO;
      payload?: undefined;
      headers: {
        Authorization: AxiosHeaderValue;
      };
    })
  | (BaseSSOLoginRequestParams & {
      type: LoginType.OTP;
      payload: PayloadOTPLogin;
    });

export type SSOLoginRequestResponse = {
  samlResponse: string;
};
