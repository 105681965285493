import React, { Suspense, lazy, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import analytics from '~/analytics';
import {
  ACCESSORIES_ROUTE,
  ACCESSORY_DETAILS_ROUTE,
  ACCESSORY_EMPLOYEE_DETAILS_ROUTE,
  APPS_DETAILS_ROUTE,
  APPS_ROUTE,
  CALLBACK_OAUTH_SERVICE_PROVIDER_ROUTE,
  CATALOG_ROUTE,
  DASHBOARD_ROUTE,
  DEVICES_ROUTE,
  DEVICE_DETAILS_ROUTE,
  DEVICE_EMPLOYEE_DETAILS_ROUTE,
  EMPLOYEES_ACCESSORY_DETAILS_ROUTE,
  EMPLOYEES_DEVICE_DETAILS_ROUTE,
  EMPLOYEES_ROUTE,
  EMPLOYEE_DETAILS_ROUTE,
  LOGIN_CALLBACK_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  OFFBOARDINGS_DETAILS_ROUTE,
  OFFBOARDINGS_ROUTE,
  ONBOARDINGS_ACCESSORY_DETAILS_ROUTE,
  ONBOARDINGS_DETAILS_ROUTE,
  ONBOARDINGS_DEVICE_DETAILS_ROUTE,
  ONBOARDINGS_ROUTE,
  ORDERS_EMPLOYEE_ROUTE,
  ORDERS_ROUTE,
  ORDER_DETAILS_ROUTE,
  PROFILES_ROUTE,
  PROFILE_DETAILS_ROUTE,
  SETTINGS_INTEGRATIONS_ABM_LINK,
  SETTINGS_ROUTE,
  SHIPMENTS_ROUTE,
  SHIPMENT_DETAILS_ROUTE,
  SIGNUP_CALLBACK_ROUTE,
  SIGNUP_ROUTE,
  SSO_LOGIN_ROUTE,
} from '~/config/routes';
import AdminAuthenticatedGuard from '~/features/guards/AdminAuthenticatedGuard';
import PrimoLayout from '~/features/layout/PrimoLayout';
import LoginPage from '~/pages/Login';
import LoginCallbackPage from '~/pages/Login/Callback';
import LogoutPage from '~/pages/Logout';
import SignUpPage from '~/pages/Signup';
import SignupCallbackPage from '~/pages/Signup/part/SocialSignup/Callback';
import SSOLoginPage from '~/pages/SSOLogin';
import { SteppersProvider } from '~/providers/steppers/AdminStepperProvider/index';

const AccessoryDrawer = lazy(() => import('~/features/drawers/AccessoryDrawer'));
const AppsPage = lazy(() => import('~/pages/Apps'));
const AppDrawer = lazy(() => import('~/features/drawers/AppDrawer'));
const CatalogPage = lazy(() => import('~/pages/Catalog'));
const OAuthServiceProviderPage = lazy(() => import('~/pages/Callback/OAuthServiceProvider'));
const DashboardPage = lazy(() => import('~/pages/Dashboard'));
const DeviceDrawer = lazy(() => import('~/features/drawers/DeviceDrawer'));
const EmployeeDrawer = lazy(() => import('~/features/drawers/EmployeeDrawer'));
const EmployeesPage = lazy(() => import('~/pages/Employees/CurrentEmployees'));
const OnboardingsPage = lazy(() => import('~/pages/Employees/Onboardings'));
const OffboardingsPage = lazy(() => import('~/pages/Employees/Offboardings'));
const DevicesPage = lazy(() => import('~/pages/Devices'));
const AccessoriesPage = lazy(() => import('~/pages/Accessories'));
const OrderDrawer = lazy(() => import('~/features/drawers/OrderDrawer'));
const OrdersPageV2 = lazy(() => import('~/pages/Orders'));
const SettingsPageV2 = lazy(() => import('~/pages/Settings'));
const AppleBusinessManagerIntegrationPage = lazy(
  () => import('~/pages/Settings/integrations/_parts/AppleBusinessManager'),
);
const ShipmentDrawer = lazy(() => import('~/features/drawers/ShipmentDrawer'));
const ShipmentsPage = lazy(() => import('~/pages/Shipments'));
const ProfilesPage = lazy(() => import('~/pages/Profiles'));
const ProfilesDetails = lazy(() => import('~/pages/Profiles/_parts/ProfileDetails'));

export const AdminRouter = () => {
  const location = useLocation();

  useEffect(() => {
    analytics?.page();
  }, [location]);

  return (
    <Routes>
      <Route path={`${SSO_LOGIN_ROUTE()}/*`} element={<SSOLoginPage />} />
      <Route path={LOGOUT_ROUTE()} element={<LogoutPage />} />
      <Route path={`${LOGIN_ROUTE()}/*`} element={<LoginPage />} />
      <Route path={`${SIGNUP_ROUTE()}/*`} element={<SignUpPage />} />
      <Route path={LOGIN_CALLBACK_ROUTE()} element={<LoginCallbackPage />} />
      <Route path={SIGNUP_CALLBACK_ROUTE()} element={<SignupCallbackPage />} />
      <Route
        path="/*"
        element={
          <AdminAuthenticatedGuard>
            <SteppersProvider>
              <PrimoLayout>
                <Suspense fallback={null}>
                  <Routes>
                    <Route index element={<Navigate to={DASHBOARD_ROUTE()} />} />
                    <Route path={CALLBACK_OAUTH_SERVICE_PROVIDER_ROUTE()} element={<OAuthServiceProviderPage />} />
                    <Route path={DASHBOARD_ROUTE()} element={<DashboardPage />} />
                    <Route path={DEVICES_ROUTE()} element={<DevicesPage />}>
                      <Route path={DEVICE_DETAILS_ROUTE()} element={<DeviceDrawer />} />
                      <Route path={DEVICE_EMPLOYEE_DETAILS_ROUTE()} element={<EmployeeDrawer />} />
                    </Route>
                    <Route path={ACCESSORIES_ROUTE()} element={<AccessoriesPage />}>
                      <Route path={ACCESSORY_DETAILS_ROUTE()} element={<AccessoryDrawer />} />
                      <Route path={ACCESSORY_EMPLOYEE_DETAILS_ROUTE()} element={<EmployeeDrawer />} />
                    </Route>
                    <Route path={APPS_ROUTE()} element={<AppsPage />}>
                      <Route path={APPS_DETAILS_ROUTE()} element={<AppDrawer />} />
                    </Route>
                    <Route path={ORDERS_ROUTE()} element={<OrdersPageV2 />}>
                      <Route path={ORDER_DETAILS_ROUTE()} element={<OrderDrawer />} />
                      <Route path={ORDERS_EMPLOYEE_ROUTE()} element={<EmployeeDrawer />} />
                    </Route>
                    <Route path={SHIPMENTS_ROUTE()} element={<ShipmentsPage />}>
                      <Route path={SHIPMENT_DETAILS_ROUTE()} element={<ShipmentDrawer />} />
                    </Route>
                    <Route path={EMPLOYEES_ROUTE()} element={<EmployeesPage />}>
                      <Route path={EMPLOYEE_DETAILS_ROUTE()} element={<EmployeeDrawer />} />
                      <Route path={EMPLOYEES_DEVICE_DETAILS_ROUTE()} element={<DeviceDrawer />} />
                      <Route path={EMPLOYEES_ACCESSORY_DETAILS_ROUTE()} element={<AccessoryDrawer />} />
                    </Route>
                    <Route path={ONBOARDINGS_ROUTE()} element={<OnboardingsPage />}>
                      <Route path={ONBOARDINGS_DETAILS_ROUTE()} element={<EmployeeDrawer />} />
                      <Route path={ONBOARDINGS_DEVICE_DETAILS_ROUTE()} element={<DeviceDrawer />} />
                      <Route path={ONBOARDINGS_ACCESSORY_DETAILS_ROUTE()} element={<AccessoryDrawer />} />
                    </Route>
                    <Route path={OFFBOARDINGS_ROUTE()} element={<OffboardingsPage />}>
                      <Route path={OFFBOARDINGS_DETAILS_ROUTE()} element={<EmployeeDrawer />} />
                    </Route>
                    <Route path={CATALOG_ROUTE()} element={<CatalogPage />} />
                    <Route path={SETTINGS_ROUTE()} element={<SettingsPageV2 />} />
                    <Route path={SETTINGS_INTEGRATIONS_ABM_LINK()} element={<AppleBusinessManagerIntegrationPage />} />
                    <Route path={PROFILES_ROUTE()} element={<ProfilesPage />}>
                      <Route path={PROFILE_DETAILS_ROUTE()} element={<ProfilesDetails />} />
                    </Route>
                  </Routes>
                </Suspense>
              </PrimoLayout>
            </SteppersProvider>
          </AdminAuthenticatedGuard>
        }
      />
    </Routes>
  );
};
