import React from 'react';
import * as theme from '~/assets/scss/theme.module.scss';

const WarningFilledIcon: SvgComponent = ({
  className,
  width = 24,
  color = theme.colorError200,
  backgroundColor = '#FFFFFF',
}) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1C4.15 1 1 4.15 1 8C1 11.85 4.15 15 8 15C11.85 15 15 11.85 15 8C15 4.15 11.85 1 8 1Z"
      fill={backgroundColor}
    />
    <g aria-label="fill-color">
      <path
        d="M7.45 4H8.55V9.5H7.45V4ZM8 12.5C7.6 12.5 7.25 12.15 7.25 11.75C7.25 11.35 7.6 11 8 11C8.4 11 8.75 11.35 8.75 11.75C8.75 12.15 8.4 12.5 8 12.5Z"
        style={{ fill: color }}
      />
    </g>
  </svg>
);

export default WarningFilledIcon;
