import cn from 'classnames';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';

import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

type CardExtendedTitleProps = {
  children?: never;
  title: string;
  subtitle?: string;
  icon?: ReactElement<SvgComponentProps> | React.ReactNode;
  iconWithBackground?: boolean;
  helperText?: string | ReactNode;
};

const iconComponent = (icon: ReactElement<SvgComponentProps> | React.ReactNode, iconWithBackground: boolean) => {
  if (iconWithBackground) {
    return <div className="pri-card-extended-title-icon">{icon}</div>;
  }

  return icon;
};

export const CardExtendedTitle: FunctionComponent<CardExtendedTitleProps> = ({
  icon,
  title,
  subtitle,
  className,
  helperText,
  iconWithBackground = true,
}) => (
  <Flex className={cn('pri-card-extended-title', className)} justify="start" align="center" gap={5}>
    {icon && iconComponent(icon, iconWithBackground)}
    <Flex direction="column" align="start" gap={2}>
      <Text bold>{title}</Text>
      {subtitle && <Text variant="muted-light">{subtitle}</Text>}
      {typeof helperText === 'string' ? (
        <Text size="caption" variant="muted-light">
          {helperText}
        </Text>
      ) : (
        helperText
      )}
    </Flex>
  </Flex>
);
