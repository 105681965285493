import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CompanyLoginConnectionStrategy } from '~/graphql/schema';
import { SignupContext } from '~/pages/Signup/context';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

const useSignupWithGoogleCallback = () => {
  const { t } = useTranslation();
  const { webAuthForSignup } = useContext(PrimoAdminContext);
  const { employeeData, connections, organization, redirectToSignup } = useContext(SignupContext);
  const currentConnection = useMemo(
    () => connections.find(connection => connection.strategy === CompanyLoginConnectionStrategy.Google),
    [connections],
  );
  const { jobTitle } = employeeData ?? {};

  return useCallback(async () => {
    const connectionProvider = currentConnection?.name;

    const areSignupRequiredFieldsValid = !!jobTitle && !!connectionProvider && !!organization;
    if (!areSignupRequiredFieldsValid) {
      toast.error(<span>{t(`errors.refusals.grant_employee_access_to_primo.generic`)}</span>);
    } else {
      webAuthForSignup.authorize({
        connection: connectionProvider,
        scope: 'openid email profile',
        redirectUri: redirectToSignup,
      });
    }
  }, [t, webAuthForSignup, currentConnection, jobTitle, organization, redirectToSignup]);
};

export default useSignupWithGoogleCallback;
