import { Checkbox as CarbonCheckbox, ClassPrefix } from '@carbon/react';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import type { FieldValues, Path } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form/dist/types/form';

import Flex from '../../shaping/Flex';
import Helper from '../_shared/Helper';
import Label from '../_shared/Label';
import './index.scss';

export type CheckboxProps<FV extends FieldValues> = {
  className?: string;
  label?: string | ReactNode;
  labelAlignment?: 'left' | 'right';
  helper?: string | ReactNode;
  disabled?: boolean;
  required?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  value?: string;

  name: Path<FV>;
  form: UseFormReturn<FV>;
};

const Checkbox = <FV extends FieldValues>({
  label,
  labelAlignment = 'right',
  form,
  name,
  className,
  helper,
  ...props
}: CheckboxProps<FV>) => {
  const id = useMemo(() => `input-${Math.random().toFixed(10).slice(2, 10)}`, []);
  const {
    register,
    formState: { errors },
  } = form;

  const error = errors[name];

  return (
    <ClassPrefix prefix="carbon">
      <Flex direction="column" className={className}>
        <Flex className={`pri-align-${labelAlignment}`} gap={5}>
          {label && typeof label === 'string' ? <Label label={label} /> : label}
          <CarbonCheckbox
            className="pri-checkbox"
            id={id}
            labelText=""
            invalid={!!error}
            invalidText={error?.message?.toString() || ''}
            {...register(name)}
            {...props}
          />
        </Flex>
        {helper && <Helper helper={helper} />}
      </Flex>
    </ClassPrefix>
  );
};

export default Checkbox;
