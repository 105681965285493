import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmailIcon } from '~/components/icons';
import Pill from '~/components/shared/Pill';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import type { ExternalIntegrationLicense } from '~/graphql/schema';

const useBuildJsxOption = () => {
  const { t } = useTranslation();

  return {
    buildJsxOption: (license: ExternalIntegrationLicense) => (
      <Flex justify="between" align="center" fullWidth>
        <Flex direction="column" gap={2}>
          <Title size="largeLabel">
            {license.name}&nbsp;({t('texts.seats_available', { seats: license.availableSeats })})
          </Title>
          <Text maxLength={80} align="left" variant="muted-light" size="caption" noTooltip>
            {license.description || ''}
          </Text>
        </Flex>
        {license.description?.toLowerCase().includes('exchange online') && (
          <Pill icon={<EmailIcon width={16} />} color="blue">
            {t('texts.creates_email')}
          </Pill>
        )}
      </Flex>
    ),
  };
};

export default useBuildJsxOption;
