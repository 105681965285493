import cn from 'classnames';
import React from 'react';

import { NavProvider } from './NavContext';

import Flex from '~/components/shared/shaping/Flex';
import './index.scss';

export * from './CompanySelector';
export * from './CtaButton';
export * from './NavContext';
export * from './NavGroup';
export * from './NavItem';
export * from './NavSubItem';
export * from './SessionSwitcher';

export const Nav: FunctionComponent = ({ children, className }) => (
  <NavProvider>
    <Flex direction="column" fullHeight className={cn('pri-nav-v2', className)}>
      {children}
    </Flex>
  </NavProvider>
);
