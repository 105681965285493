import cn from 'classnames';
import React from 'react';

import { Text } from '~/components/shared/typography';
import Bloc from '../shaping/Bloc';
import './style.scss';

type BulletPointDotProps = {
  number?: number;
};

const BulletPointDot: FunctionComponent<BulletPointDotProps> = ({ className, number }) => (
  <Bloc className={cn('pri-bullet-point-dot', className, { 'pri-bullet-point-numbered': !!number })}>
    {number ? (
      <Text bold size="caption">
        {number}
      </Text>
    ) : (
      <>&nbsp;</>
    )}
  </Bloc>
);

export default BulletPointDot;
