import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useBoolean } from 'react-use';
import * as theme from '~/assets/scss/theme.module.scss';
import {
  DashboardIcon,
  DeliveryIcon,
  EmployeesIcon,
  LaptopIcon,
  SettingsIcon,
  ShoppingIcon,
  SubtractAltIcon,
  UserFollowIcon,
  UserSettingsIcon,
} from '~/components/icons';
import AppIcon from '~/components/icons/app';
import LogoFleetIcon from '~/components/icons/logoFleet';
import LogoTypoIcon from '~/components/icons/logoTypo';
import Beta from '~/components/shared/Beta';
import CountrySelector from '~/components/shared/forms/CountrySelector';
import Toggle from '~/components/shared/forms/Toggle';
import Bloc from '~/components/shared/shaping/Bloc';
import Filler from '~/components/shared/shaping/Filler';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';
import {
  ACCESSORIES_LINK,
  APPS_LINK,
  CATALOG_LINK,
  DASHBOARD_LINK,
  DEVICES_LINK,
  EMPLOYEES_LINK,
  OFFBOARDINGS_LINK,
  ONBOARDINGS_LINK,
  ORDERS_LINK,
  PROFILES_LINK,
  PROFILE_DETAILS_LINK,
  SETTINGS_LINK,
} from '~/config/routes';
import { HasMDM } from '~/features/mdm';
import { HasProvisioningPlan } from '~/features/mdm/HasProvisioningPlan';
import BlockingCatalogModal from '~/features/modals/BlockingCatalogModal';
import { HrisProvider } from '~/graphql/schema';
import { useResolver } from '~/hooks/queries/useResolver';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { SteppersContext } from '~/providers/steppers/AdminStepperProvider/index';
import { CompanySelector, CtaButton, Nav, NavGroup, NavItem, SessionSwitcher } from '../_parts';
import { HasMDMAndSSOLogin } from '~/features/mdm/HasMDMSSOLogin';
import { MDMSSODeepLogin } from '../_parts/MDMSSODeepLogin';

export const NavPanel: FunctionComponent = () => {
  const { isCatalogSetup, company, billingProfiles, employee, toggleBeta, isBetaActivated } =
    useContext(PrimoAdminContext);
  const { openOnboardingStepper, openOffboardingStepper } = useContext(SteppersContext);
  const [showBlockingCatalogModal, toggleShowBlockingCatalogModal] = useBoolean(false);
  const { t } = useTranslation();
  const settingsHrisBadgeCount = useMemo(() => (company?.hrisProvider === HrisProvider.Merge ? 1 : 0), [company]);
  const settingsBillingProfilesBadgeCount = useMemo(
    () => billingProfiles.filter(profile => !profile.checkoutCompleted).length,
    [billingProfiles],
  );
  const { executeResolver: getCompanyProfiles, result: companyProfiles } = useResolver(
    'useGetCompanyProfilesLazyQuery',
  );
  const form = useForm({
    defaultValues: {
      beta: !!isBetaActivated,
    },
  });
  const beta = form.watch('beta');

  const handleOpenOffBoarding = useCallback(() => {
    openOffboardingStepper({ employeeId: null });
  }, [openOffboardingStepper]);

  const handleOpenOnboarding = useCallback(() => {
    if (!isCatalogSetup) {
      toggleShowBlockingCatalogModal();
      return;
    }
    openOnboardingStepper({ employeeId: null });
  }, [isCatalogSetup, openOnboardingStepper, toggleShowBlockingCatalogModal]);

  useEffect(() => {
    if (company?.id) {
      getCompanyProfiles({ companyId: company.id });
    }
  }, [company, getCompanyProfiles]);

  useEffect(() => {
    toggleBeta(beta);
  }, [beta, toggleBeta]);

  if (!company || !employee) return null;

  return (
    <Bloc
      className="pri-nav-panel notranslate"
      style={{ minWidth: 215, maxWidth: 215, height: '100vh' }}
      backgroundColor="crow"
    >
      <Bloc className="pri-nav-panel-gradient">
        <Flex className="pri-p-5 pri-pt-7" direction="column" fullHeight fullWidth>
          <Link to={DASHBOARD_LINK()}>
            <Flex direction="row" gap={1} fullWidth>
              <LogoFleetIcon width={24} color={theme.colorBrand500} />
              <LogoTypoIcon width={70} />
            </Flex>
          </Link>
          <Flex className="pri-menus" direction="column" fullHeight>
            <Nav>
              <Flex className="pri-py-8" direction="column" gap={7}>
                <NavItem label={t('menus.dashboard')} to={DASHBOARD_LINK()} icon={<DashboardIcon width={12} />} />
                <NavGroup
                  group="employees"
                  to={EMPLOYEES_LINK()}
                  label={t('menus.employees')}
                  icon={<EmployeesIcon width={12} />}
                >
                  <NavItem group="employees" label={t('menus.all')} to={EMPLOYEES_LINK()} />
                  <NavItem group="employees" label={t('menus.onboardings')} to={`${ONBOARDINGS_LINK()}`} />
                  <NavItem group="employees" label={t('menus.offboardings')} to={`${OFFBOARDINGS_LINK()}`} />
                </NavGroup>
                <NavGroup
                  group="myfleet"
                  to={DEVICES_LINK()}
                  label={t('menus.my_fleet')}
                  icon={<LaptopIcon width={12} />}
                >
                  <NavItem group="myfleet" label={t('menus.devices')} to={`${DEVICES_LINK()}`} />
                  <NavItem group="myfleet" label={t('menus.accessories')} to={`${ACCESSORIES_LINK()}`} />
                </NavGroup>
                <HasProvisioningPlan>
                  <NavItem label={t('menus.apps')} to={APPS_LINK()} icon={<AppIcon width={12} />} />
                </HasProvisioningPlan>
                <NavItem label={t('menus.catalog')} to={CATALOG_LINK()} icon={<ShoppingIcon width={12} />} />
                <NavItem label={t('menus.orders')} to={ORDERS_LINK()} icon={<DeliveryIcon width={12} />} />
                <HasMDM>
                  <NavItem
                    label={t('menus.profiles')}
                    to={
                      companyProfiles?.getCompanyProfiles?.total === 1
                        ? PROFILE_DETAILS_LINK(companyProfiles.getCompanyProfiles.list[0].id)
                        : PROFILES_LINK()
                    }
                    icon={<UserSettingsIcon width={12} />}
                  />
                </HasMDM>
                <NavItem
                  label={t('menus.settings')}
                  to={SETTINGS_LINK()}
                  badge={settingsHrisBadgeCount + settingsBillingProfilesBadgeCount}
                  icon={<SettingsIcon width={12} />}
                />
              </Flex>
              <Flex justify="between" gap={3}>
                <CtaButton
                  label={t('buttons.onboard')}
                  icon={<UserFollowIcon color={theme.colorWhite} width={16} />}
                  onClick={handleOpenOnboarding}
                />
                <CtaButton
                  label={t('buttons.offboard')}
                  icon={<SubtractAltIcon color={theme.colorWhite} width={16} />}
                  onClick={handleOpenOffBoarding}
                />
              </Flex>
              <Filler />
            </Nav>
          </Flex>
          <Flex direction="column" align="start" fullWidth>
            <CountrySelector dark />
            <CompanySelector isAdmin={employee.isAdmin} />
            <Beta className="pri-w-100">
              <HasMDMAndSSOLogin>
                <>
                  <Bloc className="pri-menu-separator">&nbsp;</Bloc>
                  <MDMSSODeepLogin company={company} loggedEmployee={employee} />
                </>
              </HasMDMAndSSOLogin>
            </Beta>
            <Beta className="pri-w-100">
              <Bloc className="pri-menu-separator">&nbsp;</Bloc>
              <SessionSwitcher company={company} loggedEmployee={employee} />
            </Beta>
            {employee.isAdmin && employee.isInternalEmployee && (
              <>
                <Bloc className="pri-menu-separator">&nbsp;</Bloc>
                <Flex className="pri-pt-5" align="center" justify="between" fullWidth>
                  <Text size="caption" variant="light" bold>
                    {t('texts.beta').toUpperCase()}
                  </Text>
                  <Toggle form={form} name="beta" />
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
        {showBlockingCatalogModal && <BlockingCatalogModal onClose={toggleShowBlockingCatalogModal} />}
      </Bloc>
    </Bloc>
  );
};
