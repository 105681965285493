import React from 'react';
import type { ReactElement, ReactNode } from 'react';
import cn from 'classnames';

import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import Button from '~/components/shared/buttons/Button';
import { CloseIcon } from '~/components/icons';

import './index.scss';

export type CalloutProps = {
  title?: string;
  description?: string | ReactNode;
  icon?: ReactElement<SvgComponentProps>;
  variant?: 'info' | 'warning' | 'error';
  children?: never;
  onClose?: () => void;
};

const Callout: FunctionComponent<CalloutProps> = ({
  className,
  title,
  description,
  variant = 'info',
  icon,
  onClose,
}) => (
  <Bloc className={cn('pri-callout', { [`pri-callout-${variant}`]: variant }, className)}>
    <Flex justify="between" fullWidth>
      <Flex direction="row" gap={7} align="center" fullWidth>
        {icon}
        <Flex direction="column" fullWidth>
          <Title size="largeLabel">{title}</Title>
          {description && (typeof description === 'string' ? <Text>{description}</Text> : description)}
        </Flex>
      </Flex>
      {onClose && <Button icon={<CloseIcon width={24} />} variant="no-style" onClick={onClose} />}
    </Flex>
  </Bloc>
);

export default Callout;
