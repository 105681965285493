import React from 'react';

import type { TextProps } from '~/components/shared/typography';
import { Text, Title } from '~/components/shared/typography';
import type { Device } from '~/graphql/schema';

import './style.scss';

export type DeviceNameV2Props = Partial<Pick<Device, 'name'>> &
  Pick<TextProps, 'maxLength'> & { asTitle?: boolean; withSerial?: boolean; bold?: true };

export const DeviceNameV2: FunctionComponent<DeviceNameV2Props> = ({ name, maxLength = 50, asTitle, bold }) =>
  asTitle ? (
    <Title maxLength={maxLength} size="subHeading" bold>
      {name ?? ''}
    </Title>
  ) : (
    <Text maxLength={maxLength} bold={bold}>
      {name ?? ''}
    </Text>
  );
