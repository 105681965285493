import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '~/components/icons/info';
import Card from '~/components/shared/Card';
import Price from '~/components/shared/Price';
import Filler from '~/components/shared/shaping/Filler';
import Flex from '~/components/shared/shaping/Flex';
import Tooltip from '~/components/shared/Tooltip';
import { Text } from '~/components/shared/typography';
import { FR_VAT } from '~/utils/orders';
import { OrderStepperContext } from '../context';

const PriceRecap: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    contextData: { equipments },
  } = useContext(OrderStepperContext);

  const total = useMemo(
    () => (equipments || []).reduce((acc, { item, quantity }) => acc + item.priceNoVAT * quantity, 0),
    [equipments],
  );

  return (
    <Card title={t('steppers.orders.price_details')}>
      {(equipments || []).map(({ item, quantity }) => (
        <Flex key={item.id} gap={3} align="center" fullWidth>
          <Text variant="dark">
            {item.name} {quantity > 1 ? `x ${quantity}` : ''}
          </Text>
          <Filler />
          <Text>
            <Price value={item.priceNoVAT * quantity} />
          </Text>
        </Flex>
      ))}
      <Flex align="center">
        <Text>{t('steppers.onboarding.shipping')}</Text>
        <Filler />
        <Text>{t('steppers.onboarding.free')}</Text>
        <Tooltip className="pri-ml-2" text={t('steppers.orders.additional_fees')} align="topRight">
          <InfoIcon width={20} />
        </Tooltip>
      </Flex>
      <hr className="pri-my-0" />
      <Flex gap={3} align="center" fullWidth>
        <Text variant="dark">{t('drawers.order.price.no_vat')}</Text>
        <Filler />
        <Text>
          <Price value={total} />
        </Text>
      </Flex>
      <Flex gap={3} align="center" fullWidth>
        <Text variant="dark">{t('drawers.order.price.total')}</Text>
        <Filler />
        <Text bold>
          <Price value={total} vat={FR_VAT} />
        </Text>
      </Flex>
    </Card>
  );
};

export default PriceRecap;
