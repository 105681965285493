import { ClassPrefix, RadioButton, RadioButtonGroup } from '@carbon/react';
import cn from 'classnames';
import type { BaseSyntheticEvent, ReactNode } from 'react';
import React, { useMemo } from 'react';
import type { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import Flex from '~/components/shared/shaping/Flex';
import Callout from '../../Callout';
import Label from '../_shared/Label';
import './index.scss';

export type RadioProps<FV extends FieldValues> = {
  dotAlignment?: 'center' | 'top';
  className?: string;
  label?: string;
  defaultOption?: string;
  inline?: boolean;
  name: Path<FV>;
  form: UseFormReturn<FV>;
  options: (
    | {
        onClick?: () => void;
        label?: ReactNode;
        value: string;
        dotAlignment?: 'center' | 'top';
        disabled?: boolean;
      }
    | { separator: ReactNode }
  )[];
  isInvalid?: boolean;
};

const Radio = <FV extends FieldValues>({
  label,
  className,
  options,
  inline,
  dotAlignment,
  isInvalid,
  name,
  form,
  ...props
}: RadioProps<FV>) => {
  const id = useMemo(() => `radio-${Math.random().toFixed(10).slice(2, 10)}`, []);
  const isRawRadio = useMemo(
    () => options?.length === 1 && !('separator' in options[0]) && !options[0].label,
    [options],
  );

  const {
    control,
    formState: { errors },
  } = form;

  const error = errors[name];

  const computedClassName = cn(
    'pri-radio',
    { 'pri-radio-inline': inline, [`pri-radio-align-${dotAlignment}`]: dotAlignment, 'is-invalid': isInvalid },
    className,
  );

  const sharedProps = {
    name: id,
    className: computedClassName,
    id,
    defaultSelected: props.defaultOption,
    onClick: (e: BaseSyntheticEvent) => {
      if (!e?.target?.classList) return;
      if ([...e.target.classList].join(',').includes('pri-select')) {
        // a bit ugly but not really another way to do it efficiently
        e.stopPropagation();
        e.preventDefault();
      }
    },
  };

  const computedOptions = useMemo(
    () =>
      options.map((option, i) =>
        'separator' in option ? (
          <Flex key={`separator-${i.toString()}`}>{option.separator}</Flex>
        ) : (
          <RadioButton
            onClick={option.onClick}
            className={cn('pri-radio-button', {
              [`pri-radio-button-align-${option.dotAlignment}`]: option.dotAlignment,
              [`pri-radio-button-disabled`]: option.disabled,
              '--raw': isRawRadio,
            })}
            key={String(option.label)}
            labelText={option.label ?? ''}
            value={option.value}
            disabled={option.disabled}
          />
        ),
      ),
    [options, isRawRadio],
  );

  const message = error?.message?.toString() || '';

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ref, ...rest } }) => (
        <ClassPrefix prefix="cds">
          {label && <Label className="pri-mb-4" label={label} />}
          <RadioButtonGroup onChange={onChange} valueSelected={value} {...rest} {...sharedProps}>
            {computedOptions}
          </RadioButtonGroup>
          {error && <Callout variant="error" title={message} className="pri-px-4 pri-radio-error" />}
        </ClassPrefix>
      )}
    />
  );
};

export default Radio;
