import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DevicesIcon, LeftArrowIcon } from '~/components/icons';
import Button from '~/components/shared/buttons/Button';
import Card from '~/components/shared/Card';
import CardItem from '~/components/shared/CardItem';
import CardItemProperty from '~/components/shared/CardItem/property';
import Price from '~/components/shared/Price';
import { StepperContext } from '~/components/shared/Stepper';
import { Text } from '~/components/shared/typography';

import { OnboardingStepperContext } from '../context';

const EquipmentsRecap: FunctionComponent<{ employeeId?: string | null }> = ({ employeeId }) => {
  const { t } = useTranslation();
  const {
    contextData: { equipments },
  } = useContext(OnboardingStepperContext);
  const { setCurrentStep } = useContext(StepperContext);

  const { inventory, catalog } = equipments || {};

  return (
    <Card
      title={t('steppers.onboarding.equipments')}
      icon={<DevicesIcon />}
      action={
        <Button
          variant="secondary-dark"
          onClick={() => setCurrentStep(employeeId ? 2 : 3)}
          icon={<LeftArrowIcon width={16} className="pri-mr-4" />}
        >
          {t('buttons.edit')}
        </Button>
      }
    >
      {[...(inventory || []), ...(catalog || [])].map(({ item, quantity }) =>
        Array.from({ length: quantity }).map((_, i) => (
          <CardItem key={`${item.id}-${i.toString()}`}>
            <CardItemProperty
              label={item.name || '-'}
              value={
                'priceNoVAT' in item ? (
                  <Text>
                    <Price value={item.priceNoVAT} />
                  </Text>
                ) : (
                  t('forms.labels.inventory')
                )
              }
            />
          </CardItem>
        )),
      )}
    </Card>
  );
};

export default EquipmentsRecap;
