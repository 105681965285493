import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'react-use';

import Button from '~/components/shared/buttons/Button';

import { SSOLoginContext } from './context';

const ResendOTPButton: FunctionComponent = () => {
  const { t } = useTranslation();
  const { email, sendOTPRequest, sendOTPRequestLoading, sendOTPRequestNextTryAt, channel } =
    useContext(SSOLoginContext);
  const [isAfter, setIsAfter] = useState(!!sendOTPRequestNextTryAt);
  const [seconds, setSeconds] = useState(
    sendOTPRequestNextTryAt ? moment.utc(sendOTPRequestNextTryAt).diff(moment.utc(), 'seconds') : 0,
  );

  useInterval(
    () => {
      setSeconds(sendOTPRequestNextTryAt ? moment.utc(sendOTPRequestNextTryAt).diff(moment.utc(), 'seconds') : 0);
      setIsAfter(!!sendOTPRequestNextTryAt && moment.utc(sendOTPRequestNextTryAt).isAfter(moment.utc()));
    },
    isAfter ? 1000 : null,
  );

  useEffect(() => {
    if (sendOTPRequestNextTryAt && !isAfter) {
      setIsAfter(moment.utc(sendOTPRequestNextTryAt).isAfter(moment.utc()));
    }
  }, [sendOTPRequestNextTryAt, isAfter, setIsAfter]);

  return (
    <Button
      type="button"
      variant="secondary-dark"
      disabled={sendOTPRequestLoading || seconds > 0}
      onClick={() => sendOTPRequest(email, channel)}
    >
      {seconds > 0 ? t('buttons.resend_in_s', { seconds }) : t('buttons.resend')}
    </Button>
  );
};
export default ResendOTPButton;
