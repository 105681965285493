import cn from 'classnames';
import React from 'react';
import { Text } from '../../../typography/Text';

import './index.scss';

export type LabelProps = {
  required?: boolean;
  label: string;
  children?: never;
};

const Label: FunctionComponent<LabelProps> = ({ className, label, required }) => (
  <Text className={cn('pri-label', { 'pri-label-required': required }, className)} bold>
    {label}
  </Text>
);

export default Label;
