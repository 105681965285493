import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import * as theme from '~/assets/scss/theme.module.scss';
import DeviceIcon from '~/components/icons/deviceIcon';
import LogoFleetIcon from '~/components/icons/logoFleet';
import LogoTypoIcon from '~/components/icons/logoTypo';
import BetaEmployee from '~/components/shared/BetaEmployee';
import CountrySelector from '~/components/shared/forms/CountrySelector';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { EMPLOYEE_SESSION_EQUIPMENT_LINK } from '~/config/routes';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';
import { CompanySelector, Nav, NavItem, SessionSwitcher } from '../_parts';

export const EmployeeNavPanel: FunctionComponent = () => {
  const { company, employee } = useContext(PrimoEmployeeContext);

  const { slug } = useParams();
  const { t } = useTranslation();

  if (!company || !employee) return null;

  return (
    <Bloc
      className="pri-nav-panel notranslate"
      style={{ minWidth: 215, maxWidth: 215, height: '100vh' }}
      backgroundColor="crow"
    >
      <Bloc className="pri-nav-panel-gradient">
        <Flex className="pri-p-5 pri-pt-7" direction="column" fullHeight fullWidth>
          <Link to={EMPLOYEE_SESSION_EQUIPMENT_LINK(slug || '')}>
            <Flex direction="row" gap={1} fullWidth>
              <LogoFleetIcon width={24} color={theme.colorBrand500} />
              <LogoTypoIcon width={70} />
            </Flex>
          </Link>
          <Flex className="pri-menus" direction="column" fullHeight>
            <Nav>
              <Flex className="pri-py-8" direction="column" gap={7}>
                <NavItem
                  icon={<DeviceIcon width={16} />}
                  label={t('menus.employee_session.equipment')}
                  to={EMPLOYEE_SESSION_EQUIPMENT_LINK(slug || '')}
                />
              </Flex>
            </Nav>
          </Flex>

          <Flex direction="column" align="start" fullWidth>
            <CountrySelector dark />
            <CompanySelector isAdmin={false} />
            <BetaEmployee className="pri-w-100">
              <Bloc className="pri-menu-separator">&nbsp;</Bloc>
              <SessionSwitcher company={company} loggedEmployee={employee} />
            </BetaEmployee>
          </Flex>
        </Flex>
      </Bloc>
    </Bloc>
  );
};
