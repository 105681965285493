import cn from 'classnames';
import React from 'react';

import Bloc from '../shaping/Bloc';

import './style.scss';

export type MessageProps = {
  variant?: 'info';
};

const Message: FunctionComponent<MessageProps> = ({ className, children, variant = 'info' }) => (
  <Bloc className={cn(className, 'pri-message', `pri-message-variant-${variant}`)}>{children}</Bloc>
);

export default Message;
