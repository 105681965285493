import cn from 'classnames';
import type { MouseEventHandler } from 'react';
import React from 'react';

import Tile from '~/components/shared/Tile';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';

import { RoundedImage } from '~/components/shared/images';
import './style.scss';

type IntegrationCardProps = {
  children?: never;
  className?: string;
  title: string;
  titleSuffix: React.ReactNode;
  description: string;
  icon: string | React.ReactNode;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const IntegrationCard: FunctionComponent<IntegrationCardProps> = ({
  className,
  title,
  titleSuffix,
  icon,
  description,
  selected,
  onClick,
}) => (
  <Tile selected={selected} className={cn('pri-integration-card', className)} onClick={onClick}>
    <Flex direction="column" gap={3}>
      <Flex justify="between">
        {typeof icon === 'string' ? <RoundedImage src={icon} cover /> : icon}
        {titleSuffix && titleSuffix}
      </Flex>
      <Title size="largeLabel">{title}</Title>
      <Text size="caption" variant="muted-light" className="pri-integration-card-text">
        {description}
      </Text>
    </Flex>
  </Tile>
);

export default IntegrationCard;
