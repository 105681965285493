import React from 'react';

import cn from 'classnames';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

import './style.scss';

import { ChevronRightIcon } from '~/components/icons';
import Tile from '~/components/shared/Tile';
import Filler from '~/components/shared/shaping/Filler';

type CardLinkProps = {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
};

const CardLink: FunctionComponent<CardLinkProps> = ({ className, icon, text, onClick }) => (
  <Tile className={cn('pri-card-link', className)} onClick={onClick}>
    <Flex direction="row" gap={3} align="center" fullWidth>
      {icon}
      <Text bold>{text}</Text>
      <Filler />
      <ChevronRightIcon width={16} />
    </Flex>
  </Tile>
);

export default CardLink;
