import React, { useContext } from 'react';
import Loader from '~/components/shared/Loader';

import Flex from '~/components/shared/shaping/Flex';
import SignupCompanyDetailPart from '~/pages/Signup/part/SignupCompanyDetailPart';
import { SignupContext } from '../context';
import SignupWithPasswordPart from './SignupWithPasswordPart';

const SignUpFlowPart: FunctionComponent = () => {
  const { organization, loading } = useContext(SignupContext);

  if (loading) {
    return (
      <Flex fullHeight fullWidth justify="center" align="center">
        <Loader />
      </Flex>
    );
  }
  if (!organization) {
    return <SignupCompanyDetailPart />;
  }

  return <SignupWithPasswordPart />;
};

export default SignUpFlowPart;
