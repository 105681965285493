import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocalStorage } from 'react-use';
import { LOGIN_CALLBACK_ROUTE, SIGNUP_CALLBACK_ROUTE } from '~/config/routes';

import type { EmployeeSignupInput, GetCompanyLoginQuery, QueryGetCompanyLoginSuccess } from '~/graphql/schema';
import { useGetCompanyLoginLazyQuery } from '~/graphql/schema';

export type Connection = QueryGetCompanyLoginSuccess['data']['connections'][number];

export type PartialEmployeeSignupInput = Omit<EmployeeSignupInput, 'email'> & { email: string | null };

export type SignupContextProps = {
  organization: string | undefined;
  employeeData: PartialEmployeeSignupInput | undefined;
  connections: Connection[];
  loading: boolean;
  redirectToSignup: string;
  redirectToLogin: string;
  setOrganization: (organization: string) => void;
  removeOrganization: () => void;
  removeCompanyId: () => void;
  setEmployeeData: (employeeData: PartialEmployeeSignupInput) => void;
};

export const SignupContext = createContext<SignupContextProps>({
  organization: undefined,
  employeeData: undefined,
  connections: [],
  loading: false,
  redirectToSignup: `${window.location.origin}${SIGNUP_CALLBACK_ROUTE()}`,
  redirectToLogin: `${window.location.origin}${LOGIN_CALLBACK_ROUTE()}`,
  setOrganization: () => undefined,
  removeOrganization: () => undefined,
  removeCompanyId: () => undefined,
  setEmployeeData: () => undefined,
});

const getCompanyLogin = (data: GetCompanyLoginQuery | undefined) => {
  if (data?.getCompanyLogin?.__typename === 'QueryGetCompanyLoginSuccess') {
    return { companyLogin: data.getCompanyLogin, error: null };
  }
  if (data?.getCompanyLogin?.__typename === 'QueryGetCompanyLoginError') {
    return { companyLogin: null, error: data.getCompanyLogin.reasons.join('; ') };
  }
  return { companyLogin: null, error: null };
};

export const SignupProvider: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const [employeeData, setEmployeeData, removeEmployeeData] =
    useLocalStorage<PartialEmployeeSignupInput>('employeeData');
  const [organization, setOrganization, removeOrganization] = useLocalStorage<string>('organization');
  const [companyId, setCompanyId, removeCompanyId] = useLocalStorage<string>('companyId');

  const [getCompanyLoginQuery, { data, loading, error: serverError, called }] = useGetCompanyLoginLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const { companyLogin, error: queryError } = useMemo(() => getCompanyLogin(data), [data]);

  useEffect(() => {
    if ((!companyLogin || companyLogin.data === null || queryError) && called && !loading) {
      removeOrganization();
      removeCompanyId();
      removeEmployeeData();
      toast.error(<>{t('errors.unknown_company')}</>);
    }
    if (serverError) {
      toast.error(<>{t('errors.internal_server_error')}</>);
    }
  }, [
    companyLogin,
    serverError,
    queryError,
    called,
    removeOrganization,
    removeCompanyId,
    removeEmployeeData,
    loading,
    t,
  ]);

  const extendedRemoveOrganization = useCallback(() => {
    removeOrganization();
    removeCompanyId();
    removeEmployeeData();
    setSearchParams(new URLSearchParams(), { replace: true });
    setEmployeeData(undefined);
  }, [removeOrganization, setSearchParams, removeCompanyId, removeEmployeeData, setEmployeeData]);

  const value = useMemo(
    () => ({
      organization: organization && companyLogin?.data?.id,
      companyId,
      employeeData,
      connections: (organization && companyLogin?.data?.connections) || [],
      loading: (!called && !!organization) || loading,
      redirectToSignup: `${window.location.origin}${SIGNUP_CALLBACK_ROUTE()}`,
      redirectToLogin: `${window.location.origin}${LOGIN_CALLBACK_ROUTE()}`,
      setOrganization,
      setCompanyId,
      removeCompanyId,
      setEmployeeData,
      removeOrganization: extendedRemoveOrganization,
    }),
    [
      organization,
      companyId,
      employeeData,
      companyLogin,
      called,
      loading,
      setOrganization,
      extendedRemoveOrganization,
      setCompanyId,
      removeCompanyId,
      setEmployeeData,
    ],
  );

  useEffect(() => {
    if (organization) {
      getCompanyLoginQuery({ variables: { search: organization } });
    }
  }, [organization, getCompanyLoginQuery]);

  return <SignupContext.Provider value={value}>{children}</SignupContext.Provider>;
};
