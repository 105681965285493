import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DevicesIcon, LeftArrowIcon } from '~/components/icons';
import Card from '~/components/shared/Card';
import { StepperContext } from '~/components/shared/Stepper';
import Button from '~/components/shared/buttons/Button';

import CardItem from '~/components/shared/CardItem';
import CardItemProperty from '~/components/shared/CardItem/property';
import { Truthy } from '~/utils/truthy';
import { OffboardingStepperContext } from '../context';

const DevicesRecap: FunctionComponent<{ employeeId?: string | null }> = ({ employeeId }) => {
  const { t } = useTranslation();
  const {
    contextData: { devices },
  } = useContext(OffboardingStepperContext);
  const { currentStep, setCurrentStep } = useContext(StepperContext);

  if (!devices?.length || (employeeId && currentStep <= 1) || (!employeeId && currentStep <= 2)) {
    return null;
  }

  return (
    <Card
      title={t('steppers.offboarding.equipments')}
      icon={<DevicesIcon />}
      action={
        <Button
          variant="secondary-dark"
          onClick={() => {
            setCurrentStep(employeeId ? 1 : 2);
          }}
          icon={<LeftArrowIcon width={16} className="pri-mr-4" />}
        >
          {t('buttons.edit')}
        </Button>
      }
    >
      {devices.map(device => (
        <CardItem key={device.id}>
          <CardItemProperty
            label={device?.name ?? ''}
            value={[
              t(`forms.labels.device_recycle.${device.handleDeviceRecycle.toLowerCase()}`),
              device.agentEnabled && t(`forms.labels.device_data.${device.handleDeviceData.toLowerCase()}`),
            ]
              .filter(Truthy)
              .join(' & ')}
          />
        </CardItem>
      ))}
    </Card>
  );
};

export default DevicesRecap;
