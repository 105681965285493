import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import LeftArrowIcon from '~/components/icons/leftArrow';
import Button from '~/components/shared/buttons/Button';
import Flex from '~/components/shared/shaping/Flex';

import Filler from '../../shaping/Filler';
import { StepperContext } from './StepperContext';

const StepperFooter = () => {
  const {
    setCurrentStep,
    currentStep,
    onClose,
    footerProps: { onNext, nextLabel, onPrev, informationNode, loaderInButton, disabledNextButton },
    loading,
  } = useContext(StepperContext);
  const { t } = useTranslation();
  const displayGoBack = currentStep !== 1;

  return (
    <div className="pri-footer">
      <Flex justify="between" align="center">
        {displayGoBack ? (
          <Button
            variant="secondary-dark"
            onClick={async () => {
              const canGoBack = onPrev ? await onPrev() : true;
              if (canGoBack) setCurrentStep(currentStep - 1);
            }}
            icon={<LeftArrowIcon width={16} className="pri-mr-4" />}
          >
            {t('buttons.back')}
          </Button>
        ) : (
          <Filler />
        )}
        <Flex align="center" gap={7}>
          <Button variant="secondary-dark" onClick={onClose}>
            {t('buttons.cancel')}
          </Button>
          <Button
            disabled={loading || disabledNextButton}
            loading={loaderInButton && loading}
            onClick={async () => {
              const canGoNext = onNext ? await onNext() : true;
              if (canGoNext) return setCurrentStep(currentStep + 1);
              return null;
            }}
          >
            {nextLabel || t('buttons.next')}
          </Button>
          {informationNode}
        </Flex>
      </Flex>
    </div>
  );
};

export default StepperFooter;
