import React from 'react';
import { useTranslation } from 'react-i18next';
import * as theme from '~/assets/scss/theme.module.scss';
import { ChevronRightIcon } from '~/components/icons';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

import { type Company, type Employee } from '~/graphql/schema';
import useSessionSwitcher from '~/hooks/PrimoContexts/useSessionSwitch';

type AdminSessionSwitcherType = {
  company: Pick<Company, 'id' | 'canonicalName'>;
  loggedEmployee: Pick<Employee, 'id' | 'isAdmin' | 'companyId' | 'isOnlyEmployee'>;
};

export const SessionSwitcher: FunctionComponent<AdminSessionSwitcherType> = ({ company, loggedEmployee }) => {
  const { switchSession, currentSession } = useSessionSwitcher(company, loggedEmployee);
  const { t } = useTranslation();

  if (loggedEmployee.isOnlyEmployee || !loggedEmployee.isAdmin) return null;

  return (
    <Bloc className="pri-pt-5 pri-session-switcher cursor-pointer" onClick={switchSession}>
      <Flex justify="between" align="center">
        <Text variant="light" size="caption" bold>
          {t(`buttons.session_switch_to_${currentSession?.toLowerCase()}`)}
        </Text>
        <ChevronRightIcon width={16} color={theme.colorWhite} />
      </Flex>
    </Bloc>
  );
};
