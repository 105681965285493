import React, { useContext, useMemo } from 'react';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { NavPanel } from '~/features/layout/NavPanels/admin';
import InitialSetupStepper from '~/features/steppers/InitialSetupStepper';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

import './index.scss';

const PrimoLayout: FunctionComponent = ({ children }) => {
  const { employee, companyInitialSetup, isLoading } = useContext(PrimoAdminContext);

  const isCompanyFullySetup = useMemo(
    () => !companyInitialSetup || Object.values(companyInitialSetup).every(value => value),
    [companyInitialSetup],
  );

  const isInternalEmployee = useMemo(() => employee?.isInternalEmployee, [employee]);
  const isEulaAccepted = useMemo(() => !!companyInitialSetup?.eulaAccepted, [companyInitialSetup]);

  if (isLoading) return null;

  return isCompanyFullySetup || isInternalEmployee || isEulaAccepted ? (
    <Flex direction="row" justify="start" align="stretch" fullHeight>
      <NavPanel />
      <Bloc backgroundColor="white" className="pri-main-section pri-py-10 pri-px-12 pri-w-100 pri-vh-100" scrollable>
        {children}
      </Bloc>
    </Flex>
  ) : (
    <InitialSetupStepper onClose={() => {}} />
  );
};
export default PrimoLayout;
