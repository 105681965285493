import cn from 'classnames';
import React from 'react';
import { Text } from '../../../typography/Text';

export type ErrorProps = {
  error: string;
  children?: never;
};

const Error: FunctionComponent<ErrorProps> = ({ className, error }) => (
  <Text className={cn('pri-error', 'pri-mt-3', className)} size="caption" variant="danger">
    {error}
  </Text>
);

export default Error;
